import AjaxStore from '../../Core/data/AjaxStore.js';
import PartOfProject from './mixin/PartOfProject.js';
import CalendarModel from '../model/CalendarModel.js';
import { ChronoCalendarManagerStoreMixin } from '../../Engine/quark/store/ChronoCalendarManagerStoreMixin.js';
import CalendarEditorStore from './calendareditor/CalendarEditorStore.js';
import VersionHelper from '../../Core/helper/VersionHelper.js';

/**
 * @module SchedulerPro/data/CalendarManagerStore
 */

/**
 * A class representing the tree of calendars in the SchedulerPro chart. An individual calendar is represented as an instance of the
 * {@link SchedulerPro.model.CalendarModel} class. The store expects the data loaded to be hierarchical. Each parent node should
 * contain its children in a property called 'children'.
 *
 * Please refer to the [calendars guide](#SchedulerPro/guides/basics/calendars.md) for details
 *
 * @mixes SchedulerPro/data/mixin/PartOfProject
 *
 * @extends Core/data/AjaxStore
 */
export default class CalendarManagerStore extends PartOfProject(ChronoCalendarManagerStoreMixin.derive(AjaxStore)) {

    //region Config
    /**
     * @hideconfigs lazyLoad
     */
    /**
     * @hideproperties lazyLoad
     */
    /**
     * @hideevents lazyLoadStarted, lazyLoadEnded
     */

    static get defaultConfig() {
        return {
            tree         : true,
            modelClass   : CalendarModel,
            /**
             * CrudManager must load stores in the correct order. Lowest first.
             * @private
             */
            loadPriority : 100,
            /**
             * CrudManager must sync stores in the correct order. Lowest first.
             * @private
             */
            syncPriority : 100,
            id           : 'calendars', // no-sanity

            // <debug>
            // for vetoing auto-asserting in tests
            validateForCalendarEditorOnLoad : VersionHelper.isTestEnv
            // </debug>
        };
    }

    //endregion

    afterConstruct() {
        super.afterConstruct(...arguments);

        // <debug>
        // Assert if calendars are processable by the calendar editor automatically
        if (this.validateForCalendarEditorOnLoad) {
            this.getProject()?.ion({
                load    : this.validateAllRecordsForCalendarEditor,
                thisObj : this
            });
        }
        // </debug>
    }

    /**
     * Validate all the registered calendars ability to be edited with the calendar editor.
     * If there are any errors they are reported as warnings in the browser console.
     */
    validateAllRecordsForCalendarEditor() {
        for (const calendar of this.allRecords) {
            this.validateRecordForCalendarEditor(calendar);
        }
    }

    /**
     * Validates if the provided calendar data can be loaded by the calendar editor.
     * If there are any errors they are reported as warnings in the browser console.
     * @param {SchedulerPro.model.CalendarModel} calendar Calendar to check.
     */
    validateRecordForCalendarEditor(calendar) {
        const store = this._calendarEditorStore = this._calendarEditorStore || new CalendarEditorStore();

        store.validateRecordsAfterPulling = true;
        store.autoPush                    = false;
        store.autoPull                    = false;
        store.calendar                    = calendar;
        store.pullFromCalendar();
    }
};
