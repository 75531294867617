import Combo from '../../Core/widget/Combo.js';
import DomHelper from '../../Core/helper/DomHelper.js';

/**
 * @module Scheduler/widget/ResourceCombo
 */

/**
 * A Combo subclass which selects resources, optionally displaying the {@link Scheduler.model.ResourceModel#field-eventColor}
 * of each resource in the picker and in the input area.
 *
 * {@inlineexample Scheduler/widget/ResourceCombo.js}
 *
 * @extends Core/widget/Combo
 * @classtype resourcecombo
 * @inputfield
 */
export default class ResourceCombo extends Combo {
    static get $name() {
        return 'ResourceCombo';
    }

    // Factoryable type name
    static get type() {
        return 'resourcecombo';
    }

    static get configurable() {
        return {
            /**
             * Show the {@link Scheduler.model.ResourceModel#field-eventColor event color} for each resource
             * @config {Boolean}
             * @default false
             */
            showEventColor : null,

            displayField : 'name',
            valueField   : 'id',

            picker : {
                cls : 'b-resourcecombo-picker',

                itemIconTpl(record) {
                    if (this.owner.showEventColor) {
                        const
                            { eventColor } = record,
                            isStyleColor   = !DomHelper.isNamedColor(eventColor),
                            style          = eventColor ? (isStyleColor ? ` style="color:${eventColor}"` : '') : ' style="display:none"',
                            colorClass     = !eventColor || isStyleColor ? '' : ` b-sch-foreground-${eventColor}`;

                        return `<div class="b-icon b-icon-square${colorClass}"${style}></div>`;
                    }

                    if (this.multiSelect) {
                        return `<div class="b-icon b-icon-square"></div>`;
                    }

                    return '';
                }
            }
        };
    }

    changeShowEventColor(showEventColor) {
        return Boolean(showEventColor);
    }

    updateShowEventColor(showEventColor) {
        this.element.classList.toggle('b-show-event-color', Boolean(showEventColor));
        this._picker?.element.classList.toggle('b-show-event-color', Boolean(showEventColor));
    }

    changePicker(picker, oldPicker) {
        picker = super.changePicker(picker, oldPicker);
        picker?.element.classList.toggle('b-show-event-color', Boolean(this.showEventColor));
        return picker;
    }

    // Implementation needed at this level because it has two inner elements in its inputWrap
    get innerElements() {
        return [
            {
                class     : 'b-icon b-resource-icon b-icon-square b-hide-display',
                reference : 'resourceIcon'
            },
            this.inputElement
        ];
    }

    syncInputFieldValue() {
        const
            me            = this,
            {
                resourceIcon,
                lastResourceIconCls
            }             = me,
            { classList } = resourceIcon,
            eventColor    = me.selected?.eventColor ?? '';

        super.syncInputFieldValue();

        // Remove last colour whichever way it was done
        resourceIcon.style.color = '';
        lastResourceIconCls && classList.remove(lastResourceIconCls);
        me.lastResourceIconCls = null;

        if (eventColor) {
            if (DomHelper.isNamedColor(eventColor)) {
                me.lastResourceIconCls = `b-sch-foreground-${eventColor}`;
                classList.add(me.lastResourceIconCls);
            }
            else {
                resourceIcon.style.color = eventColor;
            }
            classList.remove('b-hide-display');
        }
        else {
            classList.add('b-hide-display');
        }
    }
}

// Register this widget type with its Factory
ResourceCombo.initClass();
