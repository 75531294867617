import '../../Core/widget/Checkbox.js';
import '../../Core/widget/Combo.js';
import Popup from '../../Core/widget/Popup.js';
import '../../Core/widget/TextField.js';
import CalendarEditorStore from '../data/calendareditor/CalendarEditorStore.js';
import './calendareditor/CalendarEditorDateInfo.js';
import './calendareditor/CalendarEditorLegend.js';
import './calendareditor/CalendarEditorDatePicker.js';
import './calendareditor/CalendarEditorWeekTab.js';
import './calendareditor/CalendarEditorExceptionTab.js';
import MessageDialog from '../../Core/widget/MessageDialog.js';

/**
 * @module SchedulerPro/widget/CalendarEditor
 */

/**
 * Type object containig working days settings.
 *
 * Example: Set Sunday and Monday as non-working days:
 *
 * ```javascript
 * const workingDays = {
 *     0 : false,
 *     1 : false,
 *     2 : true,
 *     3 : true,
 *     4 : true,
 *     5 : true,
 *     6 : true
 * }
 * ```
 *
 * @typedef {Object<'0'|'1'|'2'|'3'|'4'|'5'|'6', Boolean>} WorkingDays
 * @property {Boolean} 0 - Indicates if Sunday is a working day
 * @property {Boolean} 1 - Indicates if Monday is a working day
 * @property {Boolean} 2 - Indicates if Tuesday is a working day
 * @property {Boolean} 3 - Indicates if Wednesday is a working day
 * @property {Boolean} 4 - Indicates if Thursday is a working day
 * @property {Boolean} 5 - Indicates if Friday is a working day
 * @property {Boolean} 6 - Indicates if Saturday is a working day
 */

/**
 * A widget allowing to edit the provided {@link SchedulerPro/model/CalendarModel calendar}.
 *
 * The widget contains a {@link Core/widget/TabPanel tab panel} with three tabs "General", "Exceptions" and "Weeks"
 * (the tab panel itself has `mainTabs` widget `ref`).
 *
 * | Tab ref        | Type                                                                  | Text         | Weight | Description                                                                   |
 * |----------------|-----------------------------------------------------------------------|--------------|--------|-------------------------------------------------------------------------------|
 * | `generalTab`   | {@link Core/widget/Container}                                         | General      | 100    | Calendar name, parent fields and a date picker displaying dates availability. |
 * | `exceptionTab` | {@link SchedulerPro/widget/calendareditor/CalendarEditorExceptionTab} | Exceptions   | 200    | Contains the calendar exception grid and editor.                              |
 * | `weekTab`      | {@link SchedulerPro/widget/calendareditor/CalendarEditorWeekTab}      | Weeks        | 300    | Contains the calendar week grid and editor.                                   |
 *
 * Besides that, there is a bottom toolbar with `Save`, `Delete` and `Cancel` buttons having (`ref` : `saveButton`,
 * `weight` : `200`), (`ref` : `removeButton`, `weight` : `300`) and (`ref` : `cancelButton`, `weight` : `400`) respectively.
 *
 * {@inlineexample SchedulerPro/widget/CalendarEditor.js}
 *
 * ### General tab
 *
 * "General" tab contains widgets for basic configurations and controls to oversee certain dates availability.
 * The tab contains the following widgets:
 *
 * - "Name" (`ref` : `nameField`, `type` : {@link Core/widget/TextField}, `weight` : `10`) A text field for editing
 *   the calendar name.
 * - "Parent" (`ref` : `parentField`, `type` : {@link Core/widget/Combo}, `weight` : `20`) A combobox field for editing
 *   the calendar parent.
 * - "Days are working by default" (`ref` : `unspecifiedTimeIsWorkingField`, `type` : {@link Core/widget/Checkbox},
 *   `weight` : `30`) A checkbox allowing to specify whether the calendar allows to work any time by default.
 * - (`ref` : `datePickerLegend`, `type` : {@link SchedulerPro/widget/calendareditor/CalendarEditorLegend},
 *   `weight` : `50`) A container displaying legend for the date picker (see next `datePicker` widget).
 * - (`ref` : `datePicker`, `type` : {@link SchedulerPro/widget/calendareditor/CalendarEditorDatePicker},
 *   `weight` : `60`) A special date picker displaying the calendar effect on shown dates. The control basically
 *    highlights dates by adding color badges representing the calendar intervals. It does that for the dates
 *    affected by the corresponding intervals.
 * - (`ref` : `dateInfo`, `type` : {@link SchedulerPro/widget/calendareditor/CalendarEditorDateInfo},
 *   `weight` : `70`) A container that shows availability info for the date selected in the date picker.
 *    For the selected date the widget displays both availability and the interval providing it.
 *
 * ### Exceptions tab
 *
 * The "Exceptions" tab allows to specify calendar exceptions - special availability overrides for particular date ranges
 * (like holiday or vacations). The tab contains a grid listing registered exceptions on the left side and a panel
 * allowing to edit the selected exception on the right side.
 *
 * | Widget ref | Type                                                                    | Weight | Description       |
 * |------------|-------------------------------------------------------------------------|--------|-------------------|
 * | `grid`     | {@link Grid/view/Grid}                                                  | 10     | Exception grid.   |
 * | `panel`    | {@link SchedulerPro/widget/calendareditor/CalendarEditorExceptionPanel} | 20     | Exception editor. |
 *
 * ### Weeks tab
 *
 * The "Weeks" tab allows to specify default weekly availability or weekly availability for specific date ranges.
 * The tab contains a grid listing registered week availability intervals on the left side and a panel
 * allowing to edit the selected interval on the right side.
 *
 * | Widget ref | Type                                                               | Weight | Description  |
 * |------------|--------------------------------------------------------------------|--------|--------------|
 * | `grid`     | {@link Grid/view/Grid}                                             | 10     | Week grid.   |
 * | `panel`    | {@link SchedulerPro/widget/calendareditor/CalendarEditorWeekPanel} | 20     | Week editor. |
 *
 * @extends Core/widget/Popup
 * @classtype calendareditor
 * @widget
 */
export default class CalendarEditor extends Popup {

    static $name = 'CalendarEditor';

    static type = 'calendareditor';

    // region Configs

    static configurable = {
        /**
         * A calendar to edit.
         * @config {SchedulerPro.model.CalendarModel}
         */
        calendar : null,

        /**
         * A date to display in the date picker.
         * @config {Date}
         */
        activeDate : null,

        /**
         * Default availability ranges to be assigned to a day (or an exception)
         * once it's made working.
         * By default uses `08:00-12:00, 13:00-17:00` ranges.
         * @config {AvailabilityRangeModelConfig[]}
         */
        defaultWorkingDayAvailability : [
            {
                startDate : '08:00',
                endDate   : '12:00'
            },
            {
                startDate : '13:00',
                endDate   : '17:00'
            }
        ],

        /**
         * Specifies days treated as working by default. The config is used when adding a new week.
         * In this case the widget sets the week working days availability with
         * {@link #config-defaultWorkingDayAvailability} value.
         *
         * The value is an object with day indexes (0 - Sunday, 1 - Monday, 2 - Tuesday,
         * 3 - Wednesday, 4 - Thursday, 5 - Friday and 6 - Saturday) used as keys
         * and boolean values (`true` if the corresponding day is working and `false` otherwise).
         *
         * For example:
         *
         * ```javascript
         * new CalendarEditor({
         *     // Sunday is the only non-working day by default
         *     defaultWorkingDays : {
         *         0 : false,
         *         1 : true,
         *         2 : true,
         *         3 : true,
         *         4 : true,
         *         5 : true,
         *         6 : true
         *     },
         *     ...
         * });
         * ```
         *
         * By default, the config sets 5 working days Monday - Friday with non-working Saturday and Sunday.
         *
         * @config {WorkingDays}
         */
        defaultWorkingDays : {
            0 : false,
            1 : true,
            2 : true,
            3 : true,
            4 : true,
            5 : true,
            6 : false
        },

        confirmCalendarRemoving : true,

        store : {
            autoPush : false,
            autoPull : false
        },

        destroyStore : true,

        clearOnClose     : true,
        autoShow         : false,
        autoUpdateRecord : true,
        timeFormat       : 'LT',
        rootElement      : document.body,
        title            : 'L{workingTimeCalendar}',
        width            : 800,
        maxHeight        : 800,
        height           : '90%',
        closable         : true,
        draggable        : true,
        items            : {
            mainTabs : {
                type     : 'tabpanel',
                flex     : '1 0 100%',
                defaults : {
                    localeClass : this
                },
                items : {
                    generalTab : {
                        title    : 'L{general}',
                        weight   : 100,
                        defaults : {
                            localeClass : this
                        },
                        items : {
                            nameField : {
                                type       : 'text',
                                name       : 'name',
                                label      : 'L{name}',
                                flex       : '1 0 48%',
                                required   : true,
                                weight     : 10,
                                labelWidth : '4em'
                            },
                            parentField : {
                                type         : 'combo',
                                name         : 'parentId',
                                label        : 'L{parent}',
                                flex         : '1 0 48%',
                                displayField : 'name',
                                valueField   : 'id',
                                weight       : 20
                            },
                            unspecifiedTimeIsWorkingField : {
                                type   : 'slidetoggle',
                                name   : 'unspecifiedTimeIsWorking',
                                text   : 'L{daysAreWorkingByDefault}',
                                flex   : '1 0 100%',
                                weight : 30
                            },
                            divider : {
                                tag    : 'hr',
                                weight : 40,
                                flex   : '1 0 100%'
                            },
                            datePickerLegend : {
                                type   : 'calendareditorlegend',
                                flex   : '1 0 25%',
                                weight : 50
                            },
                            datePicker : {
                                type          : 'calendareditordatepicker',
                                // don't want this widget to provide its value to the editor values
                                isolateFields : true,
                                flex          : '1 0 47%',
                                weight        : 60
                            },
                            dateInfo : {
                                type   : 'calendareditordateinfo',
                                flex   : '1 0 25%',
                                weight : 70
                            }
                        }
                    },

                    exceptionTab : {
                        title         : 'L{exceptions}',
                        type          : 'calendareditorexceptiontab',
                        weight        : 200,
                        // don't want this Tab to provide its values to the editor values
                        isolateFields : true
                    },

                    weekTab : {
                        title         : 'L{weeks}',
                        type          : 'calendareditorweektab',
                        weight        : 300,
                        // don't want this Tab to provide its values to the editor values
                        isolateFields : true
                    }
                }
            }
        },
        bbar : {
            defaults : {
                localeClass : this
            },
            items : {
                saveButton : {
                    text    : 'L{save}',
                    onClick : 'up.onSaveClick',
                    color   : 'b-blue',
                    cls     : 'b-raised',
                    weight  : 200
                },
                removeButton : {
                    text    : 'L{delete}',
                    onClick : 'up.onRemoveClick',
                    weight  : 300
                },
                cancelButton : {
                    text    : 'L{Object.Cancel}',
                    onClick : 'up.onCancelClick',
                    weight  : 400
                }
            }
        }
    };

    changeStore(store) {
        if (store && !store.isCalendarEditorStore) {
            store = CalendarEditorStore.new(store);
        }

        return store;
    }

    updateStore(store) {
        const { widgetMap } = this;

        widgetMap.datePickerLegend.store = store;
        widgetMap.datePicker.store       = store;
        widgetMap.dateInfo.store         = store;

        widgetMap.exceptionTab.store = store.chain(record => record.type === 'Exception', undefined, {
            calendar : store.calendar,
            autoPush : false,
            autoPull : false
        });

        widgetMap.weekTab.store = store.chain(record => record.type === 'Week', undefined, {
            calendar : store.calendar,
            autoPush : false,
            autoPull : false
        });
    }

    updateCalendar(calendar) {
        const
            me = this,
            { widgetMap, store } = me,
            { parentField } = widgetMap;

        if (!parentField.store.filters.get('no-loaded-record')) {
            me.buildParentFieldStore(calendar);
        }

        if (calendar) {
            // copy all calendar manager store records to parentField combo store
            parentField.store.data = calendar.firstStore.rootNode.copy(null, true).children;

            me._copiedCalendarStore = calendar.firstStore;
        }

        // get the calendar copy
        const record = calendar ? parentField.store.findRecord('originalInternalId', calendar.internalId, true) : null;

        // load record data into controls
        me.record = record;

        // load record into the store to process intervals
        store.calendar = record;

        // Load & save once to normalize the data
        store.pullFromCalendar(null, true)
            // save normalized data to record (pushToCalendar returns a Promise)
            .then(() => store.pushToCalendar(null, true))
            // then when pushToCalendar has finished
            .then(() => {
                // enable auto push so the calendar copy will be instantly updated
                store.autoPush = true;
                // no sure autoPull is needed here ..will see later
                store.autoPull = true;
            });

        widgetMap.exceptionTab.store.calendar = record;
        widgetMap.weekTab.store.calendar = record;
        widgetMap.dateInfo.calendar = record;

        // re-filter the combo
        parentField.store.filter();

        // hide "Parent" field when there is no records in the combo
        parentField.hidden = !widgetMap.parentField.store.count;

        // hide "Delete" button for the project calendar or null
        widgetMap.removeButton.hidden = !calendar || calendar.project?.calendar === calendar;

        // if no title config value provided
        if (!me.initialConfig.title) {
            // if adding a calendar being created change title to "Add a calendar"
            me.title = calendar?.isCreating ? me.L('L{addCalendar}') : me.L('L{workingTimeCalendar}');
        }
    }

    updateLocalization() {
        super.updateLocalization();

        const me = this;

        // if no title config value provided
        if (!me.initialConfig.title) {
            // if adding a calendar being created change title to "Add a calendar"
            me.title = me.calendar?.isCreating ? me.L('L{addCalendar}') : me.L('L{workingTimeCalendar}');
        }
    }

    updateTimeFormat(value) {
        this.widgetMap.dateInfo.timeFormat = value;
    }

    updateActiveDate(date) {
        const { widgetMap } = this;

        widgetMap.datePicker.activeDate = date;
        widgetMap.dateInfo.date         = date;
    }

    changeDefaultWorkingDayAvailability(value) {
        let result = value;

        if (value) {
            result = value.map(entry => {
                let data = entry;

                if (entry.isModel) {
                    data = { ...entry.data };
                    delete data.id;
                }

                return data;
            });
        }

        return result;
    }

    updateDefaultWorkingDayAvailability(value) {
        const { widgetMap } = this;

        widgetMap.exceptionTab.defaultWorkingDayAvailability = value;
        widgetMap.weekTab.defaultWorkingDayAvailability = value;
    }

    updateConfirmCalendarRemoving(value) {
        this.detachListeners('confirmCalendarRemoving');

        if (value) {
            this.ion({
                name         : 'confirmCalendarRemoving',
                beforeRemove : this.internalOnBeforeRemove,
                thisObj      : this
            });
        }
    }

    // endregion Configs

    // region Construction/destruction

    construct(config) {
        super.construct(...arguments);

        const
            me            = this,
            { widgetMap } = me;

        widgetMap.mainTabs.ion({
            tabChange : me.onMainTabTabChange,
            thisObj   : me
        });

        widgetMap.datePicker.ion({
            selectionChange  : me.onDatePickerSelectionChange,
            addExceptionItem : me.onAddExceptionItem,
            addWeekItem      : me.onAddWeekItem,
            thisObj          : me
        });

        widgetMap.dateInfo.ion({
            intervalClick : me.onIntervalLegendClick,
            thisObj       : me
        });

        widgetMap.datePickerLegend.ion({
            intervalClick : me.onIntervalLegendClick,
            thisObj       : me
        });

        widgetMap.exceptionTab.ion({
            addRecordClick    : me.onAddExceptionClick,
            removeRecordClick : me.onRemoveExceptionClick,
            thisObj           : me
        });

        widgetMap.weekTab.ion({
            addRecordClick    : me.onAddWeekClick,
            removeRecordClick : me.onRemoveWeekClick,
            thisObj           : me
        });
    }

    doDestroy() {
        const
            me = this,
            { widgetMap } = me;

        me._calendarBumpVersionDetacher?.();

        // destroy stores we made
        widgetMap.parentField.store?.destroy?.();
        widgetMap.exceptionTab.store?.destroy?.();
        widgetMap.weekTab.store?.destroy?.();

        if (me.destroyStore && me.store) {
            me.store.destroy();
        }

        super.doDestroy();
    }

    // endregion Construction/destruction

    // region Listeners

    onMainTabTabChange({ activeItem }) {
        // ping invalid states once a tab got switched
        activeItem.eachWidget(w => w.syncInvalid?.(), true);
    }

    onDatePickerSelectionChange({ source }) {
        this.widgetMap.dateInfo.date = source.activeDate;
        this._activeDate = source.activeDate;
    }

    onIntervalLegendClick({ interval }) {
        if (interval) {
            this.editInterval(interval);
        }
    }

    onAddExceptionItem() {
        this.addExceptionInterval();
    }

    onAddExceptionClick({ data }) {
        this.addExceptionInterval(data);
    }

    onRemoveExceptionClick({ record }) {
        this.store.remove(record);
    }

    onAddWeekItem() {
        this.addWeekInterval();
    }

    onAddWeekClick({ data }) {
        this.addWeekInterval(data);
    }

    onRemoveWeekClick({ record }) {
        this.store.remove(record);
    }

    async onSaveClick() {
        // Do not persist if there are invalid values
        if (await this.save()) {
            this.isSaving = true;
            this.close();
            this.isSaving = false;
        }
    }

    async onRemoveClick() {
        this.isRemoving = true;

        if (await this.remove()) {
            this.close();
        }

        this.isRemoving = false;
    }

    onCancelClick() {
        this.close();
    }

    async internalOnBeforeRemove({ calendar }) {
        const
            title    = this.L('L{confirmation}'),
            okButton = this.L('L{Object.Yes}');

        // ask confirmation if: 1) the calendar has got children..
        if (calendar.children?.length) {
            const message = this.L('L{removeCalendarWithChildren}');

            if (await MessageDialog.confirm({ title, okButton, message }) !== MessageDialog.okButton) {
                return false;
            }
        }

        // 2) the calendar (or its children) is used
        if (calendar.project.getCalendarConsumers(calendar).length > 1) {
            const message = this.L('L{removeCalendarAndLinks}');

            if (await MessageDialog.confirm({ title, okButton, message }) !== MessageDialog.okButton) {
                return false;
            }
        }
    }

    // endregion Listeners

    // region Methods

    buildParentFieldStore(calendar) {
        const
            me = this,
            { parentField } = me.widgetMap;

        parentField.store = new calendar.firstStore.constructor();

        parentField.store.addFilter({
            id       : 'no-loaded-record', // no-sanity
            // do not display the edited calendar and its children in the parent combo
            filterBy : record => !me._record || !me._record.contains?.(record, false, true)
        });
    }

    addExceptionInterval(data = {}) {
        const
            me = this,
            record = me.store.addNthNameRecord({
                name     : me.L('New exception'),
                ...me.widgetMap.datePicker.getSelectionTimeSpan(),
                ...data,
                type     : 'Exception',
                calendar : me.record
            });

        return me.editInterval(record);
    }

    addWeekInterval(data = {}) {
        const
            me = this,
            { defaultWorkingDayAvailability, defaultWorkingDays } = me;

        // if no availability specified - build default
        if (!data.availability) {
            const availability = data.availability = [];

            for (let id = 0; id < 7; id++) {
                availability.push({
                    id,
                    availability : defaultWorkingDays[id] ? defaultWorkingDayAvailability : []
                });
            }
        }

        const record = me.store.addNthNameRecord({
            name     : me.L('New week'),
            ...me.widgetMap.datePicker.getSelectionTimeSpan(),
            ...data,
            type     : 'Week',
            calendar : me.record
        });

        return me.editInterval(record);
    }

    editInterval(interval) {
        const
            type = interval.type.toLowerCase(),
            tab  = this.widgetMap[type + 'Tab'];

        tab.record = interval;
        this.widgetMap.mainTabs.activeTab = tab;
    }

    /**
     * Saves changes to the calendar.
     * The changes saving could be prevented by the widget if it finds some of the child controls
     * are in invalid state.
     * In that case the method displays {@link Core/widget/MessageDialog a message} asking user to fix
     * the data before saving.
     * @param {SchedulerPro.model.CalendarModel} [calendar] Target calendar to save changes to.
     * If not provided the changes are save to the {@link #config-calendar loaded calendar}.
     * @returns {SchedulerPro.model.CalendarIntervalModel[]|Boolean} Returns an array of records
     * saved to the calendar or `false` if changes are not saved.
     */
    async save(calendar = this.calendar) {
        const me = this;

        // Do not persist if there are invalid values
        if (me.isValid) {
            const values = me.getValues();

            // if got a parent - replace its id with proper value from the original store
            if (values.parentId) {
                const internalId = me.widgetMap.parentField.store.getById(values.parentId).originalInternalId;

                values.parentId = calendar.firstStore.getByInternalId(internalId)?.id;
            }

            // save field values to the calendar
            calendar.set(values);

            // save intervals to the calendar
            const result = await me.store.pushToCalendar(calendar);

            me.trigger('save', { calendar });

            return result;
        }
        else {
            // wait till user clicks "ok"
            await MessageDialog.alert({
                title   : me.L('L{error}'),
                message : me.L('L{inputErrors}')
            });

            // and switch to the very first invalid tab

            const { mainTabs } = me.widgetMap;

            for (const panel of mainTabs.items) {
                if (!panel.isValid) {
                    mainTabs.activeTab = panel;
                    break;
                }
            }
        }

        return false;
    }

    async remove(calendar = this.calendar) {
        if (!calendar || (await this.trigger('beforeRemove', { calendar }) === false)) {
            return false;
        }

        calendar.project?.unlinkCalendarConsumers(calendar);

        calendar.remove();

        this.trigger('remove', { calendar });

        this.calendar = null;

        return true;
    }

    close() {
        const me = this;

        if (!me.isSaving && !me.isRemoving) {
            me.isCancelling = true;
            me.trigger('cancel');
        }

        // reset controls on close
        if (me.clearOnClose) {
            // reset loaded calendar
            me.calendar = null;
            // set picker date to current date
            me.activeDate = new Date();
            // switch back to the "General" tab
            me.widgetMap.mainTabs.activeTab = 0;
            // clear the date picker selection
            me.widgetMap.datePicker.selection = null;
        }

        const result = super.close(...arguments);

        me.isCancelling = false;

        return result;
    }

    // endregion Methods

}

// Register this widget type with its Factory
CalendarEditor.initClass();
