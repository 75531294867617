import ButtonGroup from '../../../../Core/widget/ButtonGroup.js';

/**
 * A segmented button field allowing to pick month days for the `Monthly` mode in the {@link Scheduler.view.recurrence.RecurrenceEditor recurrence dialog}.
 *
 * @extends Core/widget/ButtonGroup
 */
export default class RecurrenceMonthDaysButtonGroup extends ButtonGroup {

    static $name = 'RecurrenceMonthDaysButtonGroup';

    // Factoryable type name
    static type = 'recurrencemonthdaysbuttongroup';

    static configurable = {
        defaults : {
            toggleable : true
        }
    };

    get minValue() {
        return 1;
    }

    get maxValue() {
        return 31;
    }

    construct(config = {}) {
        config.items = this.buildItems();

        super.construct(config);
    }

    buildItems() {
        const items = [];

        for (let value = this.minValue; value <= this.maxValue; value++) {
            // button config
            items.push({
                text : value + '',
                value
            });
        }

        return items;
    }
};

// Register this widget type with its Factory
RecurrenceMonthDaysButtonGroup.initClass();
