import ColumnStore from '../../Grid/data/ColumnStore.js';
import NumberColumn from '../../Grid/column/NumberColumn.js';
import Duration from '../../Core/data/Duration.js';
import DateHelper from '../../Core/helper/DateHelper.js';
import ObjectHelper from '../../Core/helper/ObjectHelper.js';
import '../../Core/widget/DurationField.js';

/**
 * @module Scheduler/column/DurationColumn
 */

/**
 * A column showing the task {@link Scheduler/model/TimeSpan#field-fullDuration duration}. Please note, this column
 * is preconfigured and expects its field to be of the {@link Core.data.Duration} type.
 *
 * The default editor is a {@link Core.widget.DurationField}. It parses time units, so you can enter "4d"
 * indicating 4 days duration, or "4h" indicating 4 hours, etc. The numeric magnitude can be either an integer or a
 * float value. Both "," and "." are valid decimal separators. For example, you can enter "4.5d" indicating 4.5 days
 * duration, or "4,5h" indicating 4.5 hours.
 *
 * {@inlineexample Scheduler/column/DurationColumn.js}
 *
 * @extends Grid/column/NumberColumn
 * @classtype duration
 * @column
 */
export default class DurationColumn extends NumberColumn {
    compositeField = true;

    //region Config

    static $name = 'DurationColumn';

    static type = 'duration';

    static isGanttColumn = true;

    static fields = [
        /**
         * Precision of displayed duration, defaults to use {@link Scheduler.view.Scheduler#config-durationDisplayPrecision}.
         * Specify an integer value to override that setting, or `false` to use raw value
         * @config {Number|Boolean} decimalPrecision
         */
        { name : 'decimalPrecision' },
        'abbreviatedUnit'
    ];

    static defaults = {
        /**
         * Set to `true` to display duration in short form (5d, 2mo)
         * @config {Boolean}
         */
        abbreviatedUnit : null,

        min           : null,
        max           : null,
        step          : 1,
        largeStep     : 0,
        field         : 'fullDuration',
        text          : 'L{Duration}',
        instantUpdate : true,
        // Undocumented, used by Filter feature to get type of the filter field
        filterType    : 'duration',

        sortable(durationEntity1, durationEntity2) {
            const
                ms1 = durationEntity1.getValue(this.field),
                ms2 = durationEntity2.getValue(this.field);

            return ms1 - ms2;
        }
    };

    construct() {
        super.construct(...arguments);

        const sortFn = this.sortable;

        this.sortable = (...args) => sortFn.call(this, ...args);
    }

    get defaultEditor() {
        const { max, min, step, largeStep } = this;

        // Remove any undefined configs, to allow config system to use default values instead
        return ObjectHelper.cleanupProperties({
            type : 'duration',
            name : this.field,
            max,
            min,
            step,
            largeStep
        });
    }

    //endregion

    //region Internal

    get durationUnitField() {
        return `${this.field}Unit`;
    }

    roundValue(duration) {
        let nbrDecimals = typeof this.decimalPrecision === 'number' ? this.decimalPrecision : this.grid.durationDisplayPrecision;

        if (typeof nbrDecimals !== 'number') {
            nbrDecimals = 1;
        }

        const
            multiplier = Math.pow(10, nbrDecimals),
            rounded    = Math.round(duration * multiplier) / multiplier;

        return rounded;
    }

    formatValue(duration, durationUnit) {
        if (duration instanceof Duration) {
            durationUnit = duration.unit;
            duration = duration.magnitude;
        }

        duration = this.roundValue(duration);

        const unitFn = this.abbreviatedUnit ? 'getShortNameOfUnit' : 'getLocalizedNameOfUnit';

        // super.formatValue(duration) will apply localized decimals et al
        return super.formatValue(duration) + (this.abbreviatedUnit ? '' : ' ') + DateHelper[unitFn](durationUnit, duration !== 1);
    }

    //endregion

    //region Render

    defaultRenderer({ value, record, isExport }) {
        const
            type          = typeof value,
            durationValue = type === 'number' ? value : value?.magnitude,
            durationUnit  = type === 'number' ? record.getValue(this.durationUnitField) : value?.unit;

        // in case of bad input (for instance NaN, undefined or NULL value)
        if (typeof durationValue !== 'number') {
            return isExport ? '' : null;
        }

        return this.formatValue(durationValue, durationUnit);
    }

    //endregion

    // Used with CellCopyPaste as fullDuration doesn't work via record.get
    toClipboardString({ record }) {
        return record.getValue(this.field).toString();
    }

    fromClipboardString({ string, record }) {
        const duration = DateHelper.parseDuration(string, true, this.durationUnit);

        if (duration && 'magnitude' in duration) {
            return duration;
        }

        return record.fullDuration;
    }

    calculateFillValue({ value, record }) {
        return this.fromClipboardString({ string : value, record });
    }
}

ColumnStore.registerColumnType(DurationColumn);
