import ResizeHelper from '../../Core/helper/ResizeHelper.js';
import InstancePlugin from '../../Core/mixin/InstancePlugin.js';
import GridFeatureManager from '../feature/GridFeatureManager.js';
import Rectangle from '../../Core/helper/util/Rectangle.js';
import EventHelper from '../../Core/helper/EventHelper.js';

/**
 * @module Grid/feature/RowResize
 */

/**
 * Enables user to change row height by dragging the bottom row border. After a resize operation, the {@link Grid/data/GridRowModel#field-rowHeight}
 * field of the record is updated (when {@link #config-applyToAllRows} is `false`).
 *
 * Try hovering the bottom row border in the grid below and use drag-drop to change the row height.
 *
 * {@inlineexample Grid/feature/RowResize.js}
 *
 * This feature is **disabled** by default.
 *
 * @extends Core/mixin/InstancePlugin
 * @classtype rowResize
 * @feature
 * @demo Grid/rowheight
 */
export default class RowResize extends InstancePlugin {

    static $name = 'RowResize';

    static configurable = {
        /**
         * Set this to true to modify the global {@link Grid/view/Grid#config-rowHeight} which affects all grid rows.
         * @prp {Boolean}
         * @default false
         */
        applyToAllRows : null,

        /**
         * Set this to a CSS selector to only trigger row resizing in cells for a specific column.
         * @config {String}
         * @default
         */
        cellSelector : '.b-grid-cell',

        /**
         * Minimum height when resizing
         * @prp {Number}
         * @default
         */
        minHeight : 20,

        /**
         * Max height when resizing
         * @prp {Number}
         */
        maxHeight : 0
    };

    //region Init

    construct(grid, config) {
        const me = this;

        super.construct(...arguments);

        me.resizer = new ResizeHelper({
            name                    : 'rowResize',
            targetSelector          : '.b-grid-row:not(.b-group-header)',
            handleContainerSelector : '.b-grid-row',
            outerElement            : grid.element,
            direction               : 'vertical',
            dragThreshold           : 1,
            handleSize              : Math.min(5, grid.rowHeight * 0.1),
            internalListeners       : {
                beforeresizestart : me.onBeforeResizeStart,
                resizestart       : me.onResizeStart,
                resizing          : me.onResizing,
                resize            : me.onResize,
                cancel            : me.onCancel,
                thisObj           : me
            },

            allowResize(target, event) {
                return grid.hoveredCell?.matches(me.cellSelector);
            },

            overTopHandle(event, target) {
                if (this.canResize(target, event) && target.dataset.index !== '0') {
                    const topHandle = Rectangle.from(target);

                    topHandle.height = this.handleSize;

                    return topHandle.height > 0 && Math.abs(topHandle.top - EventHelper.getPagePoint(event).top) < this.handleSize;
                }
                return false;
            },

            internalBeforeStart(context) {
                const { edge, element } = context;

                // Grabbing top handle of any row except first is interpreted as grabbing bottom handle of the previous
                // row
                if (edge === 'top') {
                    return {
                        edge    : 'bottom',
                        element : element.previousElementSibling
                    };
                }
            },

            // Prevent selection as a result of mousedown on virtual handle
            onResizeHandlePointerDown(event) {
                grid.selectionDisabled = true;

                if (grid.features.rowReorder) {
                    // Prevent RowReorder's DragHelper from reacting
                    me._oldReorderDisabled          = grid.features.rowReorder.dragHelper.disabled;
                    grid.features.rowReorder.dragHelper.disabled = true;
                }
                grid.setTimeout(() => {
                    grid.selectionDisabled = false;
                }, 10);
            }
        });
    }

    doDestroy() {
        this.resizer?.destroy();
        super.doDestroy();
    }

    //endregion

    //region Events

    onBeforeResizeStart() {
        return !this.disabled;
    }

    onResizeStart({ source, context }) {
        const
            { client }    = this,
            applyGlobally = this.applyToAllRows || client.fixedRowHeight;

        context.record    = client.store.getById(context.element.dataset.id);
        context.oldHeight = context.record.rowHeight;
        client.store.suspendAutoCommit();

        Object.assign(source, {
            minHeight           : this.minHeight,
            maxHeight           : this.maxHeight,
            // Only update live element if applying to all rows
            skipUpdatingElement : !applyGlobally
        });

        client.element.classList.add('b-row-resizing');
    }

    onResizing({ context }) {
        if (!this.applyToAllRows && !this.client.fixedRowHeight && context.valid) {
            context.record.rowHeight = context.newHeight;
        }
    }

    onResize({ context }) {
        const { client } = this;

        if (this.applyToAllRows || client.fixedRowHeight) {
            client.rowHeight = context.newHeight;
        }
        else if (client.selectionMode.multiSelect && client.selectedRecords.includes(context.record)) {
            client.selectedRecords.forEach(record => record.rowHeight = context.newHeight);
        }
        this.cleanup();
    }

    /**
     * Restore row size on cancel (ESC)
     * @private
     */
    onCancel({ context }) {
        if (!this.applyToAllRows && !this.client.fixedRowHeight) {
            context.record.rowHeight = context.oldHeight;
        }
        this.cleanup();
    }
    //endregion

    cleanup() {
        const { client } = this;

        client.element.classList.remove('b-row-resizing');
        client.store.resumeAutoCommit();

        if (typeof this._oldReorderDisabled === 'boolean') {
            client.features.rowReorder.dragHelper.disabled = this._oldReorderDisabled;
        }
    }

    isEventOverResizeHandle(event) {
        return this.enabled && this.resizer.checkResizeHandles(event);
    }
}

GridFeatureManager.registerFeature(RowResize, false);
