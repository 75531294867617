import StateBase, { throwInvalidMethodCall } from './StateBase.js';
import Transaction, { TRANSACTION_TYPES } from '../Transaction.js';
import {
    STATE_PROP,
    QUEUE_PROP,
    POS_PROP,
    TRANSACTION_PROP,
    AUTO_RECORD_PROP,
    REVISION_NAMES_PROP,
    REVISION_INDEX_PROP,
    REVISION_QUEUE_PROP
} from '../Props.js';
import Registry from './Registry.js';
import { resetQueue } from '../Helpers.js';

/**
 * @module Core/data/stm/state/ReadyState
 */

/**
 * STM ready state class.
 *
 * @internal
 */
export class ReadyStateClass extends StateBase {

    canUndo(stm) {
        //      v
        // |*|*|
        return 0 < stm.position && stm.position <= stm.length;
    }

    canRedo(stm) {
        //  v
        // |*|*|
        return 0 <= stm.position && stm.position < stm.length;
    }

    canCheckoutTo(stm, revision) {
        if (!stm.revisionsEnabled) {
            console.warn('Revisions are disabled');
            return false;
        }

        if (!stm[REVISION_NAMES_PROP].includes(revision)) {
            console.warn('Revision is not found');
            return false;
        }

        return true;
    }

    onUndo(stm, steps) {
        let curPos = stm.position;

        const
            queue  = stm[QUEUE_PROP],
            newPos = Math.max(0, curPos - steps),
            next = () => {
                stm.notifyStoresAboutStateRestoringStart();

                const undoneTransactions = [];
                while (curPos !== newPos) {
                    const transaction = queue[--curPos];
                    transaction.undo();
                    undoneTransactions.push(transaction);
                }

                return [stm.autoRecord ? 'autoreadystate' : 'readystate', () => {
                    stm.notifyStoresAboutStateRestoringStop({ cause : 'undo', transactions : undoneTransactions });
                }];
            };

        return [{
            [STATE_PROP] : 'restoringstate',
            [POS_PROP]   : newPos
        }, next];
    }

    onRedo(stm, steps) {
        let curPos = stm.position;

        const
            queue  = stm[QUEUE_PROP],
            newPos = Math.min(queue.length, curPos + steps);

        const next = () => {
            stm.notifyStoresAboutStateRestoringStart();

            const redoneTransactions = [];
            do {
                const transaction = queue[curPos++];
                transaction.redo();
                redoneTransactions.push(transaction);
            }
            while (curPos !== newPos);

            return [stm.autoRecord ? 'autoreadystate' : 'readystate', () => {
                stm.notifyStoresAboutStateRestoringStop({ cause : 'redo', transactions : redoneTransactions });
            }];
        };

        return [{
            [STATE_PROP] : 'restoringstate',
            [POS_PROP]   : newPos
        }, next];
    }

    onCheckoutTo(stm, targetRevision) {
        const
            revisionIndex = stm[REVISION_NAMES_PROP].indexOf(targetRevision),
            startIndex    = Math.min(revisionIndex, stm[REVISION_INDEX_PROP]),
            endIndex      = Math.max(revisionIndex, stm[REVISION_INDEX_PROP]),
            isBackward    = revisionIndex < stm[REVISION_INDEX_PROP];

        let revisions = stm[REVISION_QUEUE_PROP].slice(startIndex, endIndex);

        if (isBackward) {
            // When moving backward we need to ignore revisions which are conflict resolutions, otherwise we will end
            // up in a conflict state again and will need to resolve it.
            revisions = revisions.filter(rev => !rev.conflictResolutionFor || rev.type === TRANSACTION_TYPES.TEMPORARY).reverse();

            // Remove dummy revisions from the queue
            const dummyRevisions = stm[REVISION_QUEUE_PROP].filter((rev, i) => i >= startIndex && rev.dummy);

            dummyRevisions.forEach(rev => {
                stm[REVISION_QUEUE_PROP].splice(stm[REVISION_QUEUE_PROP].indexOf(rev), 1);
                stm[REVISION_NAMES_PROP].splice(stm[REVISION_NAMES_PROP].indexOf(rev.title), 1);
            });
        }

        return [{
            [STATE_PROP]          : 'checkoutstate',
            [REVISION_INDEX_PROP] : revisionIndex
        }, () => {
            stm.notifyStoresAboutCheckoutStart();

            for (const revision of revisions) {
                revision[isBackward ? 'undo' : 'redo']();
            }
        }];
    }

    onCheckoutToHead(stm) {
        throwInvalidMethodCall();
    }

    onEnable() {
        throwInvalidMethodCall();
    }

    onDisable() {
        return 'disabledstate';
    }

    onAutoRecordOn() {
        return {
            [STATE_PROP]       : 'autoreadystate',
            [AUTO_RECORD_PROP] : true
        };
    }

    onAutoRecordOff() {
        throwInvalidMethodCall();
    }

    onStartTransaction(stm, title) {
        const transaction = new Transaction({ title });

        return [{
            [STATE_PROP]       : 'recordingstate',
            [TRANSACTION_PROP] : transaction
        }, () => {
            stm.notifyStoresAboutStateRecordingStart(transaction);
        }];
    }

    onStopTransaction() {
        throwInvalidMethodCall();
    }

    onStopTransactionDelayed() {
        throwInvalidMethodCall();
    }

    onRejectTransaction() {
        throwInvalidMethodCall();
    }

    onResetQueue(stm, options) {
        return resetQueue(stm, options);
    }

    onModelUpdate()      {}
    onModelInsertChild() {}
    onModelRemoveChild() {}
    onStoreModelAdd()    {}
    onStoreModelInsert() {}
    onStoreModelRemove() {}
    onStoreRemoveAll()   {}
}

/**
 * STM ready state.
 *
 * @internal
 */
const ReadyState = new ReadyStateClass();
export default ReadyState;

Registry.registerStmState('readystate', ReadyState);
