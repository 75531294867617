import { EffectResolutionResult } from '../../../Engine/chrono/SchedulingIssueEffect.js';
import Objects from '../../../Core/helper/util/Objects.js';
import ProjectChangeHandlerMixin from './ProjectChangeHandlerMixin.js';

/**
 * @module SchedulerPro/model/mixin/ProjectRevisionHandlerMixin
 */

/**
 * @typedef RevisionInfo
 * @property {String|Number} revisionId Real ID of the revision.
 * @property {String|Number} localRevisionId Local ID of the revision.
 * @property {String|Number} [conflictResolutionFor] ID of the revision with a conflict which was resolved by this revision.
 * @property {String|Number} clientId Id of the client to identify own changes.
 * @property {Object} changes Changes to apply with the revision.
 */

/**
 * This mixin allows applying revisions to the local project.
 * @mixin
 *
 * @mixes SchedulerPro/model/mixin/ProjectChangeHandlerMixin
 */
export default Target => class ProjectRevisionHandlerMixin extends ProjectChangeHandlerMixin(Target) {
    get shouldIgnoreRemoteChangesInSTM() {
        return super.shouldIgnoreRemoteChangesInSTM || this.stm.revisionsEnabled;
    }

    /**
     * Use this method to organize project changes into transactions. Every queue call will create a sequential
     * promise which cannot be interrupted by other queued functions. You can use async functions and await for
     * any promises (including commitAsync) with one exception - you cannot await other queued calls and any
     * other function/promise which awaits queued function. Otherwise, an unresolvable chain of promises will be
     * created.
     *
     * **NOTE**: Functions which call this method internally are marked with `on-queue` tag.
     *
     * Examples:
     * ```javascript
     * // Invalid queue call which hangs promise chain
     * project.queue(async () => {
     *     const event = project.getEventStore().getById(1);
     *     await project.queue(() => {
     *         event.duration = 2;
     *         return project.commitAsync();
     *     })
     * })
     *
     * // Valid queue call
     * project.queue(() => {
     *     const event = project.getEventStore().getById(1);
     *
     *     // Consequent queue call will be chained after the current function in the next microtask.
     *     project.queue(() => {
     *         event.duration = 2;
     *         return project.commitAsync();
     *     })
     *
     *     // Event duration is not yet changed - this condition is true
     *     if (event.duration !== 2) { }
     * })
     * ```
     *
     * @method queue
     * @param {Function} fn
     * @param {Function} handleReject
     * @on-queue
     */

    /**
     * Initializes revision feature on the project. Calling this API early is required for revisions to work.
     * @param {String} clientId ID of the client. ID should be unique across all clients. Either server should provide
     * it or UUID can be used.
     * @param {String} revisionId ID of the initial revision.
     */
    initRevisions(clientId, revisionId = 'base') {
        const
            me      = this,
            { stm } = me;

        me.detachListeners('projectRevisionListeners');

        if (stm.enabled && stm.revisionsEnabled) {
            me.clientId = clientId;

            stm.initRevision(revisionId);

            // Makes STM to finalize transaction in an async callback
            stm.asyncUndoRedo = true;
            // Allows revisions feature to work with undo/redo after editing task
            stm.mergeAddUpdateActions = true;

            stm.ion({
                name : 'projectRevisionListeners',

                recordingStart : 'revisionMixinHandleRecordingStart',
                recordingStop  : 'revisionMixinHandleRecordingStop',

                revisionRecordingStart : 'revisionMixinHandleRecordingStart',
                revisionRecordingStop  : 'revisionMixinHandleRecordingStop',

                temporaryRevisionRecordingStart : 'revisionMixinHandleRecordingStart',
                temporaryRevisionRecordingStop  : 'revisionMixinHandleRecordingStop',

                beforeRevisionAdd : 'revisionMixinHandleBeforeLocalRevision',
                revisionAdd       : 'revisionMixinHandleLocalRevision',

                increaseRevisionAsync : 'revisionMixinHandleIncreaseRevisionAsync',

                thisObj : me
            });

            // This array will keep list of `changes` objects along with input to help cleanup already handled changes
            // when navigating revisions (mostly when checking up back to head).
            me.pendingLocalRevisions = [];

            if (globalThis.bryntum.DEBUG_REVISION) {
                me.$debugLocalRevisions = [];
            }
        }
    }

    getConflictResolutionsMap(revisions) {
        const map = new Map();

        // When ordering revisions we should put all revisions with `conflictResolutionFor` after the target revision.
        // Those should be identical, however so far we want to keep them all because I am not entirely certain those
        // will actually _be_ identical.
        for (let i = 0; i < revisions.length; i++) {
            const
                revision = revisions[i],
                {
                    changes,
                    conflictResolutionFor
                }        = revision;

            // If this is a conflict resolution revision, we need to put it to a map and remove it from the list
            if (conflictResolutionFor && revisions.some(r => r.revisionId === conflictResolutionFor)) {
                if (!map.has(conflictResolutionFor)) {
                    map.set(conflictResolutionFor, []);
                }

                // Put revision to a map and remove it from the list
                map.get(conflictResolutionFor).push(changes);
            }
        }

        return map;
    }

    /**
     * Applies an array of revisions to a local project
     * @param {RevisionInfo|RevisionInfo[]} revisions
     *
     * @on-queue
     * @returns {Promise}
     */
    async applyRevisions(revisions) {
        const
            me      = this,
            { stm } = me;

        if (!Array.isArray(revisions)) {
            revisions = [revisions];
        }

        me._revisionsQueue = (me._revisionsQueue || []);
        me._revisionsQueue.push(...revisions);

        return me.queue(async() => {
            if (!me._revisionsQueue?.length) {
                return;
            }

            revisions = me._revisionsQueue;
            delete me._revisionsQueue;

            const { lastCommittedRevision } = stm;

            // We rely on the list of the incoming revisions. We trust that revisions are properly sorted and valid.
            // It means we can take our last committed revision number and skip it along with all previous revisions.
            // Because if we have them as committed, it means we already did it and there is no need to switch
            if (lastCommittedRevision) {
                const index = revisions.findLastIndex(rev => rev.revisionId === lastCommittedRevision.title);

                if (index !== -1) {
                    revisions = revisions.slice(index + 1);
                }
            }

            if (stm.isRecording) {
                stm.stopTransaction();
            }

            me.trigger('startApplyingRevision', { revisions });

            if (revisions.length) {
                const conflictResolutionsMap = me.getConflictResolutionsMap(revisions);

                stm.checkoutToLastCommittedRevision();

                await me.commitAsync();

                me.acceptChanges();

                for (const { revisionId, localRevisionId, clientId, changes } of revisions) {
                    if (clientId === me.clientId) {
                        await me.confirmLocalRevisionFromRemote(revisionId, localRevisionId, changes, conflictResolutionsMap.get(revisionId));
                    }
                    else {
                        await me.recordRevisionFromRemoteChanges(revisionId, changes, conflictResolutionsMap.get(revisionId));
                    }
                }

                await me.checkoutToHeadResolvingConflicts();

                me.cleanupLocalChangesAfterCheckoutToHead();

                // Auto recording should normally be on and likely STM is recording. If it does - stop the recording.
                if (stm.isRecording) {
                    me.trigger('postApplyRevision');
                    // It is possible that applying incoming revisions and local revisions on top of it will lead to
                    // changes. Often it is a percentDone field. In case there are changes, we do need to record them
                    // as new revision.
                    stm.stopTransaction();
                }
                // There could be some changes here which might occur when we apply remote/local revisions and the lead
                // to a new project state. For such revision there would be no special inputs, just a bunch of changes.
                // Such revision is basically an extension of our latest revision, but we cannot just update the
                // revision because it was already sent. So we just need to send this changeset as a new revision to
                // the backend.
                else if (me.changes) {
                    stm.createDataCorrectionTransaction();
                }
            }

            me.trigger('stopApplyingRevision', { revisions });
        });
    }

    async confirmLocalRevisionFromRemote(revisionId, localRevisionId, changes, conflictResolutions = []) {
        const
            me      = this,
            { stm } = me;

        stm.checkoutTo(localRevisionId);

        stm.startTemporaryRevision();

        me.handleRevisionSchedulingConflicts(changes, false);

        let conflictOccurred = false;

        const detacher = me.ion({
            conflictAutoResolved() {
                conflictOccurred = true;
            }
        });

        // we still need to apply revision in order to apply ID changes
        await me.applyProjectChanges(Objects.mergeUniformObjects({}, changes, ...conflictResolutions));

        detacher();

        if (conflictOccurred) {
            stm.createConflictResolutionRevision(revisionId, localRevisionId, changes);
        }

        stm.stopRevision();

        stm.commitRevision(localRevisionId, revisionId);

        me.detachListeners('revisionSchedulingConflicts');
    }

    async recordRevisionFromRemoteChanges(revisionId, changes, conflictResolutions = []) {
        const
            me      = this,
            { stm } = me;

        me.handleRevisionSchedulingConflicts(changes, false);

        stm.startRevision(revisionId);

        let conflictOccurred = false;

        const detacher = me.ion({
            conflictAutoResolved() {
                conflictOccurred = true;
            }
        });

        await me.applyProjectChanges(Objects.mergeUniformObjects({}, changes, ...conflictResolutions));

        detacher();

        if (conflictOccurred) {
            stm.createConflictResolutionRevision(revisionId, null, changes);
        }

        stm.stopRevision();

        stm.commitRevision(revisionId);

        me.detachListeners('revisionSchedulingConflicts');
    }

    async checkoutToHeadResolvingConflicts() {
        const
            me      = this,
            { stm } = me;

        while (stm.checkoutToNext() !== false) {
            const { currentRevisionTransaction } = stm;

            stm.startTemporaryRevision('', stm.currentRevisionTransaction);

            const revisionChanges = me.pendingLocalRevisions.find(
                ({ localRevisionId }) => localRevisionId === currentRevisionTransaction.title
            )?.changes;

            me.handleRevisionSchedulingConflicts(revisionChanges);

            let conflictOccurred = false;

            const detacher = me.ion({
                conflictAutoResolved() {
                    conflictOccurred = true;
                }
            });

            // Checking out to head might have applied changes that affect project. We need to calculate project
            // again and make sure no promise is hanging loose.
            // If there is a local revision which is not being applied, this line will start STM auto recording.
            await me.commitAsync();

            detacher();

            if (conflictOccurred) {
                stm.createTemporaryConflictResolutionRevision(currentRevisionTransaction.title, revisionChanges);
            }

            stm.stopRevision();

            me.detachListeners('revisionSchedulingConflicts');
        }

        stm.checkoutToHead();
    }

    handleRevisionSchedulingConflicts(changes) {
        const me = this;

        me.ion({
            name : 'revisionSchedulingConflicts',
            cycle({ schedulingIssue, continueWithResolutionResult }) {
                me.stm.markCurrentTransactionContentUserInput();

                const
                    dependencies             = schedulingIssue.getDependencies(),
                    addedDependencies        = changes[me.dependencyStore.id]?.$input?.added ?? [],
                    dependenciesToDeactivate = dependencies.filter(d => addedDependencies.find(added => {
                        return (added.id === d.id) || (added.$PhantomId === d.id);
                    })),
                    resolution               = schedulingIssue.deactivateDependencyCycleEffectResolutionClass.new();

                if (!dependenciesToDeactivate.length) {
                    const
                        dependencyToDeactivate = dependencies.sort((a, b) => a.id - b.id)[0],
                        errors                 = [
                            `Cannot resolve dependency cycle automatically. Going to deactivate dependency ${dependencyToDeactivate.id}`,
                            `Conflicting dependencies: ${JSON.stringify(dependencies)}`,
                            `Added dependencies: ${JSON.stringify(addedDependencies)}`
                        ];
                    console.warn(errors.join('\n'));

                    // Fallback to deactivating first dependency in the list. Sort by id first to make sure every client
                    // will get the same dependency deactivated.
                    dependenciesToDeactivate.push(dependencyToDeactivate);
                }

                dependenciesToDeactivate.map(dependency => resolution.resolve(dependency));

                continueWithResolutionResult(EffectResolutionResult.Resume);

                me.trigger('conflictAutoResolved');

                // Return false to stop this event - we handled it already
                return false;
            },
            schedulingConflict({ schedulingIssue, continueWithResolutionResult }) {
                me.stm.markCurrentTransactionContentUserInput();

                const
                    resolutions = schedulingIssue.getResolutions(),
                    resolution  = resolutions.find(r => r.isDeactivateDependencyResolution) ||
                        resolutions.find(r => r.isRemoveDateConstraintConflictResolution) ||
                        resolutions[0];

                resolution.resolve();

                continueWithResolutionResult(EffectResolutionResult.Resume);

                me.trigger('conflictAutoResolved');

                // Return false to stop this event - we handled it already
                return false;
            },
            thisObj : me,
            prio    : 1000
        });
    }

    // When navigating local revisions we only apply user input, so when we checkout back to head project will clean
    // inputs itself, but project changes as a result of calculation won't be automatically cleaned. Therefore, we need
    // to do it manually. We take all `changes` objects from all uncommitted local revisions and commit those changes
    // alone.
    cleanupLocalChangesAfterCheckoutToHead() {
        const
            me                = this,
            { stm }           = me,
            lastLocalRevision = stm.localRevisions[0]?.title;

        // need to test that added/removed records are not reported as changes twice
        if (lastLocalRevision) {
            // Remove unused local revision changes
            me.pendingLocalRevisions = me.pendingLocalRevisions.slice(
                me.pendingLocalRevisions.findIndex(({ localRevisionId }) => localRevisionId === lastLocalRevision)
            );

            const changelog = Objects.mergeUniformObjects({}, ...me.pendingLocalRevisions.map(({ changes }) => changes));

            me.afterApplyProjectChangesCleanup(changelog);
        }
    }

    inflateChangesWithUserInput(changes, userInput) {
        if (!userInput) {
            return;
        }

        // Here we need to iterate all record updates in changes object and put a meta-object with user input.
        for (const storeId in changes) {
            if (storeId === 'project' && 'updated' in userInput) {
                changes.project.$input = userInput.updated.get(this) ?? {};
                delete changes.project.id;
                continue;
            }

            const storeChanges = changes[storeId];

            storeChanges.$input = {};

            if (storeId in userInput) {
                // If 'added' key is in the user input, it will certainly be in changes too. Same for 'removed'.
                // So we can assume added/removed in `userInput[storeId]` will always be a subset of `changes[storeId]`.
                // User input contains records, so to avoid reading persistable data once again we can just copy object
                // from the array.
                ['added', 'removed'].forEach(key => {
                    if (key in userInput[storeId]) {
                        storeChanges.$input[key] = storeChanges[key]
                            .filter(a => userInput[storeId][key].find(b => (a.id ?? a.$PhantomId) === (b.id ?? b.$PhantomId)));
                    }
                });
            }

            if ('updated' in storeChanges) {
                for (const recordChanges of storeChanges.updated) {
                    const record = this.getCrudStore(storeId).getById(recordChanges.id);

                    // Just in case skip records which were removed from the store.
                    // Having a record we can check user input map for changes of this record.
                    // If record is in the map, it means record had user input which we need to put
                    // to the changes object
                    if (record && userInput.updated?.has(record)) {
                        const input = storeChanges.$input;

                        input.updated = input.updated || [];

                        // Input contains raw values. We need to fill input object with properly serialized values.
                        // We take raw object and for every key in it, we take processed value from the updated array.
                        // Also, user input contains field names and recordChanges contains data sources.
                        const changedFields = Object.fromEntries(Object.keys(userInput.updated.get(record)).map(key => {
                            const { dataSource } = record.getFieldDefinition(key);
                            return [dataSource, recordChanges[dataSource]];
                        }));

                        input.updated.push({ [record.constructor.idField] : record.id, ...changedFields });
                    }
                }
            }

            // Record can be reported as added and modified in the user input, because record still has Phantom ID. In
            // this case we need to add missing key to the input
            if (storeChanges.added && userInput.updated && !userInput[storeId]?.added) {
                const
                    storeDescriptor = this.getStoreDescriptor(storeId),
                    phantomIdField  = storeDescriptor.phantomIdField || this.phantomIdField,
                    records         = Array.from(userInput.updated.keys());

                storeChanges.$input.added = storeChanges.added.filter(
                    // Even though updated records contain records from all CRUD stores, we are looking for phantom IDs
                    // which are unique.
                    added => records.find(updated => updated.id === added[phantomIdField])
                );
            }
        }
    }

    inflateRevisionWithConflictResolutionDataChanges(changes, revisionChanges = {}) {
        // If this object is provided, it means there was a conflict which was resolved automatically. If there was a
        // conflict, it means some of the changes in this revision are invalid. So we need to go over revision changes,
        // get current values of corresponding records.
        for (const storeId in revisionChanges) {
            const storeChanges = revisionChanges[storeId];

            for (const recordChanges of (storeChanges.updated || [])) {
                const record = this.getCrudStore(storeId).getById(recordChanges.id);

                if (record) {
                    const { persistableData } = record;

                    for (const key in recordChanges) {
                        if (key !== 'id' && recordChanges[key] !== persistableData[key]) {
                            if (!Objects.hasPath(changes, `${storeId}.updated`)) {
                                Objects.setPath(changes, `${storeId}.updated`, []);
                            }

                            const
                                { updated }   = changes[storeId],
                                updatedRecord = updated.find(r => r.id === recordChanges.id);

                            if (updatedRecord) {
                                updatedRecord[key] = persistableData[key];
                            }
                            else {
                                updated.push({ id : recordChanges.id, [key] : persistableData[key] });
                            }
                        }
                    }
                }
                else {
                    // This message can be logged when revision changes contained updated record, but this record is
                    // currently missing from the store. It may mean that record was removed from the store as part of
                    // conflict resolution.
                    console.warn(`Trying to update record with id ${recordChanges.id} which is not in the store ${storeId}`);
                }
            }
        }
    }

    revisionMixinHandleBeforeLocalRevision() {
        this._currentRevisionChanges = this.changes;
        return this._currentRevisionChanges != null;
    }

    revisionMixinHandleLocalRevision({ localRevisionId, conflictResolutionFor, revisionChanges, userInput }) {
        const me = this;

        let { _currentRevisionChanges : changes } = me;

        // changes can be null, so default value does not work
        changes = changes ?? {};

        delete me._currentRevisionChanges;

        // Changes object is not guaranteed to be exactly aligned with the latest revision change. However, with normal
        // STM usage and lifecycle they are equal. Since we need to only pass changes which constitute revision, we can
        // always read changes, notify listeners about them and then commit them, assuming changes are handled by the
        // transport layer which is not the responsibility of the project.
        me.acceptChanges();

        me.inflateRevisionWithConflictResolutionDataChanges(changes, revisionChanges);

        me.inflateChangesWithUserInput(changes, userInput);

        /**
         * This event triggers when a new revision is added to the project. It is used to notify the backend about the
         * new revision.
         * @event revisionNotification
         * @param {String} localRevisionId ID of the local revision. Backend should send it in the broadcast channel
         * @param {String} [conflictResolutionFor] ID of the revision with a conflict which was resolved by this revision
         * @param {String} clientId ID of the client instance. Used to distinguish own revisions from the broadcast
         * channel
         * @param {Object} changes Object with changes constituting revision
         */
        me.trigger('revisionNotification', {
            localRevisionId,
            conflictResolutionFor,
            changes,
            clientId : me.clientId
        });

        me.pendingLocalRevisions.push({ localRevisionId, changes, conflictResolutionFor });

        if (globalThis.bryntum.DEBUG_REVISION) {
            me.$debugLocalRevisions.push({ localRevisionId, changes, conflictResolutionFor });
        }

    }

    //#region Figuring user input using STM and Engine
    // For the reasoning see section `### Figuring project changeset input` in the `revisions.md` guide.
    // To tell user input apart from calculated changes we use a trick: when transaction starts recording we put a
    // special listener to an event which marks end of project calculation but which is triggered early before changes
    // are written back to records. This allows us to look into current transactions and set special flag on transaction
    // actions. Those actions will be used by revisions feature when checking out revisions.
    revisionMixinHandleRecordingStart() {
        this.ion({
            name           : 'userInputInvestigatorListener',
            finalizeCommit : 'revisionMixinHandleFinalizeCommitAsync',
            dataReady      : 'revisionMixinHandleDataReady',
            thisObj        : this
        });
    }

    revisionMixinHandleRecordingStop() {
        // This is a cleanup stage. We don't want mark anything here, because unmarked actions pose no problem.
        this.detachListeners('userInputInvestigatorListener');
    }

    revisionMixinHandleFinalizeCommitAsync(...args) {
        this.stm.markCurrentTransactionContentUserInput();
    }

    revisionMixinHandleDataReady() {
        this.stm.markCurrentTransactionContentCalculated();
    }

    async revisionMixinHandleIncreaseRevisionAsync({ callback }) {
        await this.commitAsync();

        callback();
    }

    //#endregion
};
