import Validatable from '../../../../Core/widget/mixin/Validatable.js';
import DateHelper from '../../../../Core/helper/DateHelper.js';
import ButtonGroup from '../../../../Core/widget/ButtonGroup.js';

/**
 * A segmented button field allowing to pick months for the `Yearly` mode in the
 * {@link Scheduler.view.recurrence.RecurrenceEditor recurrence dialog}.
 *
 * @extends Core/widget/ButtonGroup
 * @classtype recurrencemonthsbuttongroup
 */
export default class RecurrenceMonthsButtonGroup extends ButtonGroup.mixin(Validatable) {

    static $name = 'RecurrenceMonthsButtonGroup';

    static type = 'recurrencemonthsbuttongroup';

    static configurable = {
        defaults : {
            toggleable : true
        }
    };

    construct(config = {}) {
        config.items = this.buildItems();

        super.construct(config);
    }

    buildItems() {
        return DateHelper.getMonthNames().map((item, index) => ({
            text  : item.substring(0, 3),
            value : index + 1 // 1-based
        }));
    }

    updateItemText(item) {
        item.text = DateHelper.getMonthName(item.value - 1).substring(0, 3);
    }

    onLocaleChange() {
        // update button texts on locale switch
        this.items.forEach(this.updateItemText, this);
    }

    updateLocalization() {
        this.onLocaleChange();
        super.updateLocalization();
    }
};

// Register this widget type with its Factory
RecurrenceMonthsButtonGroup.initClass();
