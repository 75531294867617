import Column from './Column.js';
import ColumnStore from '../data/ColumnStore.js';
import DomHelper from '../../Core/helper/DomHelper.js';
import StringHelper from '../../Core/helper/StringHelper.js';
import Tooltip from '../../Core/widget/Tooltip.js';
import VersionHelper from '../../Core/helper/VersionHelper.js';

/**
 * @module Grid/column/ActionColumn
 */

/**
 * Config object for an action in an ActionColumn.
 * @typedef {Object} ActionConfig
 * @property {String} [cls] CSS Class for action icon
 * @property {String} [ariaLabel] A text to use for the action element´s `aria-label` value. If not provided, the
 *   action´s `tooltip` will be used as the `aria-label` (if present).
 * @property {Function|String|TooltipConfig} [tooltip] Tooltip text, or a config object which can reconfigure the shared
 *   tooltip by setting boolean, numeric and string config values, or a function to return the tooltip text, passed the
 *   row's `record`
 * @property {Core.data.Model} [tooltip.record] The record
 * @property {Function|Boolean} [visible] Boolean to define the action icon visibility or a callback function, passed the
 *   row's `record`, to change it dynamically
 * @property {Function} [onClick] Callback to handle click action item event
 * @property {Core.data.Model} [onClick.record] The row record
 * @property {HTMLElement} [onClick.target] The clicked action element
 * @property {ActionConfig} [onClick.action] The clicked action config
 * @property {Grid.view.GridBase} [onClick.grid] The Grid instance
 * @property {Grid.column.ActionColumn} [onClick.column] The ActionColumn instance
 * @property {Boolean} [showForGroup] Set to `true` to have action icon visible in group headers only when using the
 *  `group` feature
 * @property {Function|String} [renderer] A render function, or the name of a function in the Grid's ownership tree used
 *   to define the action element. Passed the row's `record`, expected to return an HTML string or a DOM config object.
 *
 * **Note**: when specified, the `cls` action config is ignored. Make sure you add an action icon manually, for example:
 *
 * ```javascript
 * {
 *      type    : 'action',
 *      text    : 'Increase amount',
 *      actions : [{
 *          cls      : 'b-fa b-fa-plus', // this line will be ignored
 *          renderer : ({ record }) => '<i class="b-action-item b-fa b-fa-plus"></i> ' + record.name,
 *          onClick  : ({ record }) => {}
 *      }]
 * }
 * ```
 *
 * or
 *
 * ```javascript
 * {
 *      type    : 'action',
 *      text    : 'Increase amount',
 *      actions : [{
 *          cls      : 'b-fa b-fa-plus', // this line will be ignored
 *          renderer : 'up.renderAction' // Defined on the Grid
 *          onClick  : ({ record }) => {}
 *      }]
 * }
 * ```
 *
 * @property {Core.data.Model} [renderer.record] The record
 */

/**
 * A column that displays actions as clickable icons in the cell.
 *
 * {@inlineexample Grid/column/ActionColumn.js}
 *
 * ```javascript
 * new TreeGrid({
 *     appendTo : document.body,
 *     columns  : [{
 *         type    : 'action',
 *         text    : 'Increase amount',
 *         actions : [{
 *             cls      : 'b-fa b-fa-plus',
 *             renderer : ({ action, record }) => `<i class="b-action-item ${action.cls} b-${record.enabled ? "green" : "red"}-class"></i>`,
 *             visible  : ({ record }) => record.canAdd,
 *             tooltip  : ({ record }) => `<p class="b-nicer-than-default">Add to ${record.name}</p>`,
 *             onClick  : ({ record }) => console.log(`Adding ${record.name}`)
 *         }, {
 *             cls     : 'b-fa b-fa-pencil',
 *             tooltip : 'Edit note',
 *             onClick : ({ record }) => console.log(`Editing ${record.name}`)
 *         }]
 *     }]
 * });
 * ```
 *
 * Actions may be placed in {@link Grid/feature/Group} headers, by setting `action.showForGroup` to `true`. Those
 * actions will not be shown on normal rows.
 *
 * @extends Grid/column/Column
 * @classtype action
 * @column
 */
export default class ActionColumn extends Column {

    static type  = 'action';
    static $name = 'ActionColumn';

    static fields = [
        /**
         * An array of action config objects, see {@link #typedef-ActionConfig} for details.
         *
         * ```javascript
         * new Grid({
         *     columns  : [{
         *         type    : 'action',
         *         text    : 'Actions',
         *         actions : [{
         *             cls      : 'b-fa b-fa-plus',
         *             visible  : ({ record }) => record.canAdd,
         *             onClick  : ({ record }) => console.log(`Adding ${record.name}`)
         *         }, {
         *             cls     : 'b-fa b-fa-pencil',
         *             tooltip : 'Edit note',
         *             onClick : ({ record }) => console.log(`Editing ${record.name}`)
         *         }]
         *     }]
         * });
         * ```
         *
         * @config {ActionConfig[]} actions List of action configs
         * @category Common
         */
        { name : 'actions', type : 'array' },

        /**
         * Set true to hide disable actions in this column if the grid is {@link Core.widget.Widget#config-readOnly}
         * @config {Boolean} disableIfGridReadOnly
         * @default
         * @category Common
         */
        { name : 'disableIfGridReadOnly', defaultValue : false }
    ];

    static defaults = {
        /**
         * @hideconfigs filterable, groupable, sortable, editor, searchable, htmlEncode, resizable
         */

        /**
         * Column minimal width. If value is Number then minimal width is in pixels.
         * @config {Number|String} minWidth
         * @default 30
         * @category Layout
         */
        minWidth : 30,

        filterable : false,
        groupable  : false,
        sortable   : false,
        editor     : false,
        searchable : false,
        htmlEncode : false,
        resizable  : false,
        readOnly   : true
    };

    get groupHeaderReserved() {
        return true;
    }

    construct(config, store) {
        const me = this;

        if (config.renderer) {
            VersionHelper.deprecate('grid', '7.0.0', 'Using deprecated `renderer` with ActionColumn, use `afterRenderCell` callback instead.');
            config.afterRenderCell = config.renderer;
            delete config.renderer;
        }

        super.construct(config, store);

        // use auto-size only as default behaviour
        if (!config.width && !config.flex) {
            me.grid.ion({ paint : 'updateAutoWidth', thisObj : me });
        }

        if (me.disableIfGridReadOnly) {
            me.grid.element.classList.add('b-actioncolumn-readonly');
        }
    }

    /**
     * Renderer that displays action icon(s) in the cell.
     * @private
     */
    defaultRenderer({ grid, column, record, isExport, callExternalRenderer = true }) {
        const
            inGroupTitle = record && ('groupRowFor' in record.meta),
            { subGrid }  = column,
            renderConfig = {
                className : { 'b-action-ct' : 1 },
                children  : column.actions?.map((actionConfig, index) => {
                    if ('visible' in actionConfig) {
                        if (((typeof actionConfig.visible === 'function') && actionConfig.visible({ record }) === false) || actionConfig.visible === false) {
                            return '';
                        }
                    }

                    // check if an action allowed to be shown in case of using grouping
                    if ((inGroupTitle && !actionConfig.showForGroup) || (!inGroupTitle && actionConfig.showForGroup)) {
                        return '';
                    }

                    const
                        {
                            tooltip,
                            renderer
                        }    = actionConfig,
                        btip = (typeof tooltip === 'function' || tooltip?.startsWith?.('up.')) ? subGrid.callback(tooltip, subGrid, [{ record }]) : tooltip || '';

                    // handle custom renderer if it is specified
                    let config;
                    if (renderer) {
                        const customRendererData = subGrid.callback(renderer, subGrid, [{
                            index,
                            record,
                            column,
                            isExport,
                            tooltip : btip,
                            action  : actionConfig
                        }]) ?? '';

                        // Set data-index to make onClick handler stable
                        if (typeof customRendererData === 'string') {
                            config = {
                                tag     : 'span',
                                class   : 'b-action-item b-tool',
                                dataset : {
                                    ...Tooltip.encodeConfig(btip),
                                    index
                                },
                                html : customRendererData
                            };
                        }
                        else {
                            customRendererData.dataset       = customRendererData.dataset || {};
                            customRendererData.dataset.index = index;
                            config = customRendererData;
                        }
                    }
                    else {
                        config = {
                            tag     : 'button',
                            dataset : {
                                ...Tooltip.encodeConfig(btip),
                                index
                            },
                            className : {
                                'b-tool'           : 1,
                                'b-action-item'    : 1,
                                [actionConfig.cls] : actionConfig.cls
                            }
                        };
                    }

                    const ariaLabel = actionConfig.ariaLabel || (typeof actionConfig.tooltip === 'string' ? actionConfig.tooltip : undefined);

                    if (ariaLabel) {
                        config['aria-label'] = StringHelper.encodeHtml(ariaLabel);
                    }
                    return config;
                })
            };

        if (isExport) {
            return renderConfig.children.flatMap(configItem => configItem.html || []).join(',');
        }

        return renderConfig;
    }

    /**
     * Handle icon click and call action handler.
     * @private
     */
    onCellClick({ grid, column, record, target }) {
        let actionEl;
        if (column !== this || !(actionEl = target.closest('.b-action-item[data-index]'))) {
            return;
        }

        const
            actionIndex   = actionEl.dataset.index,
            action        = column.actions?.[actionIndex],
            actionHandler = action?.onClick;

        if (actionHandler) {
            this.callback(actionHandler, column, [{ record, action, target, grid, column }]);
        }
    }

    /**
     * Update width for actions column to fit content.
     * @private
     */
    updateAutoWidth() {
        const
            me           = this,
            groupActions = [],
            {
                actions : oldActions
            }            = me;

        // header may be disabled, in that case we won't be able to calculate the width properly
        if (!me.element) {
            return;
        }

        const actions = me.actions = [];

        // collect group and non group actions to check length later
        oldActions?.forEach(actionOriginal => {
            const action = { ...actionOriginal };

            // remove possible visibility condition to make sure an action will exist in test HTML
            delete action.visible;
            // group actions shows in different row and never together with non group
            if (action.showForGroup) {
                delete action.showForGroup;
                groupActions.push(action);
            }
            else {
                actions.push(action);
            }
        });

        // use longest actions length to calculate column width
        if (groupActions.length > actions.length) {
            me._actions = groupActions;
        }

        const actionsHtml = DomHelper.createElement(me.defaultRenderer({
            column               : me,
            record               : new me.grid.store.modelClass(),
            callExternalRenderer : false
        })).outerHTML;

        me.width   = DomHelper.measureText(actionsHtml, me.element, true, me.element.parentElement);
        me.actions = oldActions;
    }
}

ColumnStore.registerColumnType(ActionColumn);
ActionColumn.exposeProperties();
