import Helpers from './Helpers.js';
import DomHelper from './DomHelper.js';

/**
 * @module Core/helper/CSSHelper
 */

/**
 * Provides methods to add and manipulate CSS style rules.
 *
 * Note that this class is incompatible with [CSP](https://developer.mozilla.org/en-US/docs/Web/HTTP/CSP)
 *
 * ```javascript
 * this.criticalRule = CSSHelper.insertRule(`#${this.id} .b-sch-event.critical {background-color:${this.criticalColor}}`);
 * ```
 */
export default class CSSHelper {
    static $name = 'CSSHelper';

    /**
     * Inserts a CSS style rule based upon the passed text
     * @param {String|String[]} cssText The text of the rule including selector and rule body just as it would
     * be specified in a CSS file.
     * @returns {CSSRule|CSSRule[]} The resulting `CSSRule` object(s) if the add was successful.
     */
    static insertRule(cssText, parentElement = document.head) {
        const
            styleSheet   = this.getStyleSheet(parentElement),
            { cssRules } = styleSheet,
            oldCount     = cssRules.length,
            one          = typeof cssText === 'string';

        (one ? [cssText] : cssText).forEach((rule, index) => styleSheet.insertRule(rule, index));

        // Only return element zero if the add was successful.
        if (cssRules.length > oldCount) {
            return one ? cssRules[0] : Array.from(cssRules).slice(0, cssText.length);
        }
    }

    /**
     * Looks up the first rule which matched the passed selector.
     * @param {String|Function} selector Either the selector string to exactly match or a function which
     * when passed a required selector, returns `true`.
     * @returns {CSSRule} The first matching CSS Rule object if any found.
     */
    static findRule(selector) {
        let result;
        const isFn = typeof selector === 'function';

        // Array#find will stop when the function returns true, stop when the inner
        // find call yields a value from the search string.
        // Array#find better: to http://www.andygup.net/fastest-way-to-find-an-item-in-a-javascript-array/
        Array.prototype.find.call(document.head.querySelectorAll('link[rel=stylesheet],style[type*=css]'), element => {
            result = Array.prototype.find.call(element.sheet.rules || element.sheet.cssRules, r => {
                return isFn ? selector(r) : r.selectorText === selector;
            });
            if (result) {
                return true;
            }
        });

        return result;
    }

    static getStyleSheet(parentElement = document.head) {
        if (!parentElement.$bryntumStylesheet) {
            parentElement.$bryntumStylesheet = DomHelper.createElement({
                tag    : 'style',
                id     : 'bryntum-private-styles', // no-sanity
                type   : 'text/css',
                parent : parentElement
            }).sheet;
        }
        return parentElement.$bryntumStylesheet;
    }

    /**
     * Returns current CSS version
     * @returns {String}
     * @internal
     */
    static getCSSVersion() {
        return getComputedStyle(document.documentElement).getPropertyValue('--bryntum-version').replace(/[" ]/gm, '');
    }
}

Helpers.register(CSSHelper);
