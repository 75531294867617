import GridFeatureManager from '../../Grid/feature/GridFeatureManager.js';
import SchedulerScrollButtons from '../../Scheduler/feature/ScrollButtons.js';

/**
 * @module Gantt/feature/ScrollButtons
 */

/**
 *
 * This feature injects buttons in each row that scrolls the task bar into view. It can optionally show a label along with the
 * button, using the {@link #config-labelRenderer}.
 *
 * ```javascript
 * new Gantt({
 *     appendTo          : 'container',
 *     features : {
 *         scrollButtons : {
 *             labelRenderer({ taskRecord }) {
 *                 return `${taskRecord.wbsCode} ${StringHelper.encodeHtml(taskRecord.name)}`;
 *             }
 *         }
 *     }
 * ```
 *
 * This feature is **disabled** by default.
 *
 * {@inlineexample Gantt/feature/ScrollButtons.js}
 *
 * @demo Gantt/scroll-buttons
 *
 * @extends Scheduler/feature/ScrollButtons
 * @feature
 * @classtype scrollButtons
 *
 * @typings Scheduler.feature.ScrollButtons -> Scheduler.feature.SchedulerScrollButtons
 */
export default class ScrollButtons extends SchedulerScrollButtons {

    static $name = 'ScrollButtons';

    renderer({ record, grid }) {
        const
            taskBefore      = record.endDate <= grid.visibleDateRange.startDate,
            taskAfter       = record.startDate >= grid.visibleDateRange.endDate;

        return {
            class    : 'b-scroll-buttons-container',
            children : [{
                className : {
                    'b-scroll-buttons-content' : true,
                    'b-scroll-backward'        : taskBefore,
                    'b-scroll-forward'         : taskAfter
                },
                children : [
                    this.labelRenderer && (taskBefore || taskAfter) ? {
                        tag  : 'span',
                        text : this.labelRenderer?.({ taskRecord : record })
                    } : null,
                    record.isScheduled ? {
                        tag       : 'i',
                        className : {
                            'b-icon'          : 1,
                            'b-scroll-button' : 1,
                            'b-icon-before'   : taskBefore,
                            'b-icon-after'    : taskAfter,
                            'b-task-visible'  : !taskBefore && !taskAfter
                        }
                    } : null
                ]
            }]
        };
    }

    onElementClick({ target }) {
        if (target.matches('.b-scroll-button')) {
            const
                { client } = this,
                record     = client.getRecordFromElement(target);

            client.scrollTaskIntoView(record, Object.assign(this.scrollOptions, { block : target.classList.contains(this.forwardIconCls) ? 'end' : 'start' }), true);
        }
    }
}

GridFeatureManager.registerFeature(ScrollButtons, false, 'Gantt');
