import DateHelper from '../../Core/helper/DateHelper.js';
import ButtonGroup from '../../Core/widget/ButtonGroup.js';
import RecurrenceDayRuleEncoder from '../data/util/recurrence/RecurrenceDayRuleEncoder.js';

/**
 * @module Scheduler/widget/DayButtons
 */

/**
 * A segmented button field allowing you to pick one or more days of the week.
 *
 * {@inlineexample Scheduler/view/DayButtons.js}
 *
 * @extends Core/widget/ButtonGroup
 * @classtype daybuttons
 */
export default class DayButtons extends ButtonGroup {

    static $name = 'DayButtons';

    // Factoryable type name
    static type = 'daybuttons';

    static defaultConfig = {
        /**
         * The value for this button group can be set as either an array of strings (e.g., [‘SU’, ‘WE’, ‘TH’]), or
         * an array of day numbers (where 0 represents Sunday).
         *
         * ```javascript
         * new DayButtons({
         *    appendTo : document.body,
         *    value : [0,1]
         * })
         * ```
         * @accepts {Number[]|String[]}
         * @prp {String[]}
         */
        value : null,

        items : true
    };

    static configurable = {
        /**
         * @hideconfigs items, add, remove, insert, remove, removeAll
         */

        /**
         * A CSS class to add to the pressed state of the buttons.
         * @config {String}
         * @default
         */
        pressedCls : 'b-raised b-blue',

        /**
         * Number of chars to display for each day
         * @config {Number}
         * @default
         */
        dayNameLength : 1,

        separator : ',',

        defaults : {
            toggleable : true
        }

    };

    updateItemText(item) {
        const
            day = RecurrenceDayRuleEncoder.decodeDay(item.value)[0],
            dayName = DateHelper.getDayName(day);

        item.text = dayName.substring(0, this.dayNameLength);
        item.tooltip = dayName;
    }

    changeItems(items) {
        if (items === true) {
            const
                weekStartDay = DateHelper.weekStartDay,
                dayNames = DateHelper.getDayNames().map((text, index) => ({
                    text       : text.substring(0, this.dayNameLength),
                    tooltip    : text,
                    value      : RecurrenceDayRuleEncoder.encodeDay(index),
                    pressedCls : this.pressedCls,
                    dataset    : {
                        dayIndex : index
                    }
                }));

            // we should start week w/ weekStartDay
            items = dayNames.slice(weekStartDay).concat(dayNames.slice(0, weekStartDay));

            items = super.changeItems(items);
        }

        return items;
    }

    set value(value) {
        if (value?.length > 0 && typeof value[0] === 'number') {
            value = value.map(val => RecurrenceDayRuleEncoder.encodeDay(val));
        }
        if (value && Array.isArray(value)) {
            value = value.join(this.separator);
        }

        super.value = value;
    }

    get value() {
        let value = super.value;

        if (value && Array.isArray(value)) {
            value = value.join(this.separator);
        }

        return value;
    }

    /**
     * An array of the selected days, represented as JS day numbers (0 - Sunday, 1 - Monday etc.).
     * @property {Number[]}
     * @readonly
     */
    get valueAsDayNumbers() {
        return this.value ? this.value.split(this.separator).map(value => RecurrenceDayRuleEncoder.decodeDay(value)[0]) : [];
    }

    onLocaleChange() {
        // update button texts on locale switch
        this.items.forEach(this.updateItemText, this);
    }

    updateLocalization() {
        this.onLocaleChange();
        super.updateLocalization();
    }
};

// Register this widget type with its Factory
DayButtons.initClass();
