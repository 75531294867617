/**
 * @module Core/data/stm/state/TemporaryRevisionRecordingState
 */
import { throwInvalidMethodCall } from './StateBase.js';
import Registry from './Registry.js';
import {
    STATE_PROP,
    TRANSACTION_PROP
} from '../Props.js';
import { RecordingStateClass } from './RecordingState.js';

/**
 * STM revision recording state class.
 *
 * @internal
 */
export class TemporaryRevisionRecordingStateClass extends RecordingStateClass {
    canUndo(stm) {
        return false;
    }

    canRedo(stm) {
        return false;
    }

    canCheckoutTo() {
        return false;
    }

    onUndo(stm) {
        throwInvalidMethodCall();
    }

    onRedo(stm) {
        throwInvalidMethodCall();
    }

    onCheckoutToHead(stm) {
        throwInvalidMethodCall();
    }

    onStopRevision(stm, title) {
        const transaction = stm[TRANSACTION_PROP];

        return [{
            [STATE_PROP]       : 'checkoutstate',
            [TRANSACTION_PROP] : null
        }, () => {
            stm.notifyStoresAboutTempRevRecordingStop(transaction);
        }];
    }

    onStartTransaction(stm) {
        throwInvalidMethodCall();
    }

    onStopTransaction(stm) {
        throwInvalidMethodCall();
    }

    onStopTransactionDelayed(stm) {
        throwInvalidMethodCall();
    }

    onRejectTransaction(stm) {
        throwInvalidMethodCall();
    }

    onEnable(stm) {
        throwInvalidMethodCall();
    }

    onDisable(stm) {
        throwInvalidMethodCall();
    }

    onAutoRecordOn(stm) {
        throwInvalidMethodCall();
    }

    onAutoRecordOff(stm) {
        throwInvalidMethodCall();
    }

    onResetQueue(stm) {
        throwInvalidMethodCall();
    }
}

/**
 * STM temporary revision recording state.
 *
 * @internal
 */
const TemporaryRevisionRecordingState = new TemporaryRevisionRecordingStateClass();
export default TemporaryRevisionRecordingState;

Registry.registerStmState('temporaryrevisionrecordingstate', TemporaryRevisionRecordingState);
