import StringHelper from '../../../Core/helper/StringHelper.js';
import GridRowModel from '../../../Grid/data/GridRowModel.js';

/**
 * @module SchedulerPro/model/calendareditor/CalendarEditorIntervalBaseModel
 */

/**
 * Base model for the calendar editor store.
 *
 * @extends Grid/data/GridRowModel
 * @internal
 */
export default class CalendarEditorIntervalBaseModel extends GridRowModel {

    static $name = 'CalendarEditorIntervalBaseModel';

    static fields = [
        /**
         * The interval type. It's either a __week__ (specified default daily availability)
         * or an __exception__ (an availability override for a specific period of time, for example
         * a holiday or a vacation).
         * @field {'Exception'|'Week'} type
         */
        'type',
        /**
         * The interval name.
         * @field {String} name
         */
        'name',
        /**
         * The calendar this interval belongs to.
         * @field {SchedulerPro.model.CalendarModel} calendar
         */
        'calendar',
        /**
         * The interval start date (if the intervals overrides a specific date range).
         * @field {Date} startDate
         */
        { name : 'startDate', type : 'date' },
        /**
         * The interval end date (if the intervals overrides a specific date range).
         * @field {Date} endDate
         */
        { name : 'endDate', type : 'date' },
        'color',
        'compositeCode'
    ];

    construct(config) {
        const {
            intervals,
            isTypeDefined
        } = config;

        this._isTypeDefined = isTypeDefined;
        this.intervals = intervals instanceof Set ? intervals : new Set(intervals);

        delete config.isTypeDefined;
        delete config.intervals;

        if (!config.compositeCode) {
            config.compositeCode = StringHelper.generateUUID();
        }

        super.construct(...arguments);
    }

    get isTypeDefined() {
        return this._isTypeDefined;
    }

    get isOverride() {
        return this.startDate || this.endDate;
    }

    toJSON() {
        const result = super.toJSON();

        if (result.calendar) {
            result.calendar = result.calendar.id;
        }

        return result;
    }
}
