var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Mixin } from '../../../../ChronoGraph/class/BetterMixin.js';
import { calculate } from '../../../../ChronoGraph/replica/Entity.js';
import { isAtomicValue } from '../../../../ChronoGraph/util/Helpers.js';
import { DependencyType, Direction, TimeUnit } from '../../../scheduling/Types.js';
import { ConstrainedScheduleMixin } from "./ConstrainedScheduleMixin.js";
//---------------------------------------------------------------------------------------------------------------------
export class ConstrainedByDependenciesEventScheduleMixin extends Mixin([ConstrainedScheduleMixin], (base) => {
    const superProto = base.prototype;
    class ConstrainedByDependenciesEventScheduleMixin extends base {
        /**
         * The method defines whether the provided dependency (called pushing dependency)
         * should constrain the successor/predecessor (in forward/backward direction) or not.
         * If the method returns `true` the dependency constrains the event being pushed and does not do that when `false` returned.
         * By default the method returns `true` if the dependency is [[SchedulerProDependencyMixin.active|active]]
         * and if this event is [[inactive|active]] (or both the pushing event and the event being pushed are [[inactive]]).
         *
         * This is used when calculating [[startDateConstraintIntervals]].
         * @param dependency Dependency to consider.
         * @returns `true` if the dependency should push, `false` if not.
         */
        *shouldPushingDepAffectScheduling(dependency) {
            if (this.direction === Direction.Forward
                && !(yield* this.event.shouldPredecessorAffectScheduling(dependency))
                ||
                    this.direction === Direction.Backward
                        && !(yield* this.event.shouldSuccessorAffectScheduling(dependency))) {
                // early exit if the hooks on event said the pushing dependency should be ignored
                return false;
            }
            const pushingEvent = yield this.pickPushingEventIdentifier(dependency);
            // ignore missing from events and inactive dependencies
            return pushingEvent && !isAtomicValue(pushingEvent) && (yield dependency.$.active)
                // ignore inactive predecessor (unless we both are inactive)
                && (!(yield pushingEvent.$.inactive) || (yield this.event.$.inactive));
        }
        pickPushingDepsIdentifier(event) {
            if (this.direction === Direction.Forward) {
                return event.$.incomingDeps;
            }
            else if (this.direction === Direction.Backward) {
                return event.$.outgoingDeps;
            }
            else {
                throw new Error("Should not be here");
            }
        }
        pickPushingEventIdentifier(dependency) {
            if (this.direction === Direction.Forward) {
                return dependency.$.fromEvent;
            }
            else if (this.direction === Direction.Backward) {
                return dependency.$.toEvent;
            }
            else {
                throw new Error("Should not be here");
            }
        }
        *pickPushingStartDateIdentifier(pushingEvent) {
            return this.pick(pushingEvent).$.startDate;
        }
        *pickPushingEndDateIdentifier(pushingEvent) {
            return this.pick(pushingEvent).$.endDate;
        }
        // need to have at least one "entity" decorator in the class, otherwise the entity infrastructure won't be setup
        *calculateStartDateConstraintIntervals() {
            const intervals = yield* superProto.calculateStartDateConstraintIntervals.call(this);
            const project = this.getProject();
            const dependencyConstraintIntervalClass = project.dependencyConstraintIntervalClass;
            const pushingDeps = yield this.pickPushingDepsIdentifier(this.event);
            for (const pushingDep of pushingDeps) {
                // ignore missing from events and inactive predecessors/dependencies
                if (!(yield* this.shouldPushingDepAffectScheduling(pushingDep)))
                    continue;
                const pushingEvent = yield this.pickPushingEventIdentifier(pushingDep);
                // treat the event as manually scheduled if the field is true or the event is started
                // and we should schedule started events as manually scheduled
                const manuallyScheduled = yield* pushingEvent.isManuallyScheduled();
                const pushingEventDirection = (yield this.event.$.effectiveDirection).direction;
                let pushingDate;
                switch (yield pushingDep.$.type) {
                    case DependencyType.StartToEnd:
                        if (this.direction === Direction.Backward) {
                            pushingDate = manuallyScheduled && pushingEventDirection === this.direction
                                ? yield pushingEvent.$.endDate
                                : yield (yield* this.pickPushingEndDateIdentifier(pushingEvent));
                        }
                        break;
                    case DependencyType.EndToStart:
                        if (this.direction === Direction.Forward) {
                            pushingDate = manuallyScheduled && pushingEventDirection === this.direction
                                ? yield pushingEvent.$.endDate
                                : yield (yield* this.pickPushingEndDateIdentifier(pushingEvent));
                        }
                        break;
                    case DependencyType.StartToStart:
                        pushingDate = manuallyScheduled && pushingEventDirection === this.direction
                            ? yield pushingEvent.$.startDate
                            : yield (yield* this.pickPushingStartDateIdentifier(pushingEvent));
                        break;
                }
                if (pushingDate) {
                    const lag = yield pushingDep.$.lag;
                    const lagUnit = yield pushingDep.$.lagUnit;
                    const calendar = yield pushingDep.$.calendar;
                    // this "subscribes" on the calendar's `version` field (which is incremented
                    // every time when the intervals of the calendar changes)
                    yield calendar.$.version;
                    const interval = this.direction === Direction.Forward
                        ?
                            dependencyConstraintIntervalClass.new({
                                owner: pushingDep,
                                startDate: lag !== 0
                                    ?
                                        calendar.calculateEndDate(pushingDate, yield* project.$convertDuration(lag, lagUnit, TimeUnit.Millisecond))
                                    :
                                        pushingDate,
                                endDate: null
                            })
                        :
                            dependencyConstraintIntervalClass.new({
                                owner: pushingDep,
                                startDate: null,
                                endDate: lag !== 0
                                    ?
                                        // Skip non-working time forward to constrain the event as late as possible
                                        // (could affect if the event and successor use different calendars)
                                        calendar.skipNonWorkingTime(calendar.calculateStartDate(pushingDate, yield* project.$convertDuration(lag, lagUnit, TimeUnit.Millisecond)))
                                    :
                                        pushingDate
                            });
                    intervals.unshift(interval);
                }
            }
            return intervals;
        }
        *calculateEndDateConstraintIntervals() {
            const intervals = yield* superProto.calculateEndDateConstraintIntervals.call(this);
            const project = this.getProject();
            const dependencyConstraintIntervalClass = project.dependencyConstraintIntervalClass;
            const pushingDeps = yield this.pickPushingDepsIdentifier(this.event);
            for (const pushingDep of pushingDeps) {
                // ignore missing from events and inactive dependencies
                if (!(yield* this.shouldPushingDepAffectScheduling(pushingDep)))
                    continue;
                const pushingEvent = yield this.pickPushingEventIdentifier(pushingDep);
                const manuallyScheduled = yield* pushingEvent.isManuallyScheduled();
                const pushingEventDirection = (yield this.event.$.effectiveDirection).direction;
                let pushingDate;
                switch (yield pushingDep.$.type) {
                    case DependencyType.EndToEnd:
                        pushingDate = manuallyScheduled && pushingEventDirection === this.direction
                            ? yield pushingEvent.$.endDate
                            : yield (yield* this.pickPushingEndDateIdentifier(pushingEvent));
                        break;
                    case DependencyType.StartToEnd:
                        if (this.direction === Direction.Forward) {
                            pushingDate = manuallyScheduled && pushingEventDirection === this.direction
                                ? yield pushingEvent.$.startDate
                                : yield (yield* this.pickPushingStartDateIdentifier(pushingEvent));
                        }
                        break;
                    case DependencyType.EndToStart:
                        if (this.direction === Direction.Backward) {
                            pushingDate = manuallyScheduled && pushingEventDirection === this.direction
                                ? yield pushingEvent.$.startDate
                                : yield (yield* this.pickPushingStartDateIdentifier(pushingEvent));
                        }
                        break;
                }
                if (pushingDate) {
                    const lag = yield pushingDep.$.lag;
                    const lagUnit = yield pushingDep.$.lagUnit;
                    const calendar = yield pushingDep.$.calendar;
                    // this "subscribes" on the calendar's `version` field (which is incremented
                    // every time when the intervals of the calendar changes)
                    yield calendar.$.version;
                    const interval = this.direction === Direction.Forward
                        ?
                            dependencyConstraintIntervalClass.new({
                                owner: pushingDep,
                                startDate: lag !== 0
                                    ?
                                        calendar.calculateEndDate(pushingDate, yield* project.$convertDuration(lag, lagUnit, TimeUnit.Millisecond))
                                    :
                                        pushingDate,
                                endDate: null
                            })
                        :
                            dependencyConstraintIntervalClass.new({
                                owner: pushingDep,
                                startDate: null,
                                endDate: lag !== 0
                                    ?
                                        // Skip non-working time forward to constrain the event as late as possible
                                        // (could affect if the event and successor use different calendars)
                                        calendar.skipNonWorkingTime(calendar.calculateStartDate(pushingDate, yield* project.$convertDuration(lag, lagUnit, TimeUnit.Millisecond)))
                                    :
                                        pushingDate
                            });
                    intervals.unshift(interval);
                }
            }
            return intervals;
        }
    }
    __decorate([
        calculate('startDateConstraintIntervals')
    ], ConstrainedByDependenciesEventScheduleMixin.prototype, "calculateStartDateConstraintIntervals", null);
    return ConstrainedByDependenciesEventScheduleMixin;
}) {
}
