import Store from '../../../Core/data/Store.js';
import DateHelper from '../../../Core/helper/DateHelper.js';
import AvailabilityRangeModel from '../../model/calendareditor/AvailabilityRangeModel.js';

/**
 * @module SchedulerPro/data/calendareditor/AvailabilityRangeStore
 */

/**
 * A store of availability ranges. The class is used by
 * {@link SchedulerPro/model/calendareditor/CalendarEditorExceptionModel} class
 * which is a part of the {@link SchedulerPro/widget/CalendarEditor calendar editor} data level.
 *
 * @extends Core/data/Store
 */
export default class AvailabilityRangeStore extends Store {

    static $name = 'AvailabilityRangeStore';

    static configurable = {
        modelClass : AvailabilityRangeModel,

        verifyNoGeneratedIds : false
    };

    invertRanges(availability = this.getRange(), modelClass = this.modelClass) {
        return this.constructor.invertRanges(availability, modelClass);
    }

    static invertRanges(availability, modelClass = AvailabilityRangeModel) {
        if (!availability?.length) {
            return availability;
        }

        availability.sort((a, b) => a.startDate - b.startDate);

        const
            dayStart = DateHelper.clearTime(availability[0].startDate),
            dayEnd   = DateHelper.add(dayStart, 1, 'day'),
            ranges   = [];

        let currentRange = [];

        // start new range from day start time
        currentRange = modelClass.new({ startDate : dayStart });
        ranges.push(currentRange);

        for (const { startDate, endDate } of availability) {
            // close current range at "from" time
            currentRange.endDate = startDate;
            // start new range from "to" time
            currentRange = modelClass.new({ startDate : endDate });
            ranges.push(currentRange);
        }

        currentRange.endDate = dayEnd;

        if (ranges[0].startDate - ranges[0].endDate === 0) {
            ranges.shift();
        }

        if (ranges[ranges.length - 1].startDate - ranges[ranges.length - 1].endDate === 0) {
            ranges.pop();
        }

        // <remove-on-release>
        // TODO check this
        // </remove-on-release>
        if (ranges[0] && ranges[0].startDate - ranges[ranges.length - 1].endDate === 0) {
            ranges[ranges.length - 1].endDate = ranges[0].endDate;
            ranges.shift();
        }

        return ranges;
    }

    get isValid() {
        return !this.getErrors(1);
    }

    getErrors(limit = 0) {
        let errors = new Set();

        for (const record of this.allRecords) {
            const recordErrors = record.getErrors();

            if (recordErrors) {
                errors = new Set([...errors, ...recordErrors]);
            }

            if (limit && errors.size > limit) break;
        }

        return errors.size ? Array.from(errors) : null;
    }

    validateOverlap({ startDate, endDate, record }) {
        return !this.allRecords.some(r => r !== record && r.startDate && r.endDate &&
            DateHelper.intersectSpans(startDate, endDate, r.startDate, r.endDate));
    }

}
