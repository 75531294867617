/**
 * @module Core/data/stm/action/RemoveChildAction
 */
import { STORES_PROP } from '../Props.js';
import ActionBase, { ACTION_TYPES } from './ActionBase.js';

const
    PARENT_MODEL_PROP = Symbol('PARENT_MODEL_PROP'),
    CHILD_MODELS_PROP = Symbol('CHILD_MODELS_PROP'),
    CONTEXT_PROP      = Symbol('CONTEXT_PROP');

/**
 * Action to record store remove child operation.
 * @extends Core/data/stm/action/ActionBase
 */
export default class RemoveChildAction extends ActionBase {
    static $name = 'RemoveChildAction';

    static get defaultConfig() {
        return {
            /**
             * Reference to a parent model a child model has been removed to.
             *
             * @prp {Core.data.Model}
             * @readonly
             * @default
             */
            parentModel : undefined,

            /**
             * Children models removed.
             *
             * @prp {Core.data.Model[]}
             * @readonly
             * @default
             */
            childModels : undefined,

            /**
             * Map having children models as keys and values containing previous parent
             * index at the parent.
             *
             * @prp {Object}
             * @readonly
             * @default
             */
            context : undefined,

            stores : undefined
        };
    }

    get type() {
        return ACTION_TYPES.REMOVE_CHILD;
    }

    //<debug>
    afterConfig() {
        super.afterConfig();

        console.assert(
            this.parentModel.isModel &&
            Array.isArray(this.childModels) &&
            this.childModels.every(m => m.isModel) &&
            this.context instanceof Map &&
            Array.isArray(this.stores),
            "Can't create action, bad configuration!"
        );
    }
    //</debug>

    get parentModel() {
        return this[PARENT_MODEL_PROP];
    }

    set parentModel(model) {
        //<debug>
        console.assert(
            !this[PARENT_MODEL_PROP] && model,
            "Can't set parent model, model is required and can be set only once!"
        );
        //</debug>

        this[PARENT_MODEL_PROP] = model;
    }

    get childModels() {
        return this[CHILD_MODELS_PROP];
    }

    set childModels(models) {
        //<debug>
        console.assert(
            !this[CHILD_MODELS_PROP] &&
            Array.isArray(models) &&
            models.every(m => m.isModel),
            "Can't set child models, models are required, it should be array of Model class and can be set only once!"
        );
        //</debug>

        this[CHILD_MODELS_PROP] = models.slice(0);
    }

    get context() {
        return this[CONTEXT_PROP];
    }

    set context(ctx) {
        //<debug>
        console.assert(
            !this[CONTEXT_PROP] &&
            ctx instanceof Map &&
            Array.from(ctx.entries()).every(([k, v]) => {
                return k.isModel && typeof v === 'object' && typeof v.parentIndex === 'number' && typeof v.orderedParentIndex === 'number';
            }),
            "Can't set context, the value is required it should be Map keyed by inserted models with `index` values, and it can be set only once!"
        );
        //</debug>

        this[CONTEXT_PROP] = ctx;
    }

    get stores() {
        return this[STORES_PROP];
    }

    set stores(stores) {
        //<debug>
        console.assert(Array.isArray(stores) && stores.every(s => s.isStore), 'Stores should be an array of store instances');
        //</debug>

        this[STORES_PROP] = stores;
    }

    undo() {
        const { parentModel, context, childModels } = this;

        // Let's sort models by parent index such that models with lesser index
        // were inserted back first, thus making valid parent index of models following.

        childModels.sort((lhs, rhs) => {
            const
                lhsIndex = context.get(lhs).parentIndex,
                rhsIndex = context.get(rhs).parentIndex;

            return lhsIndex - rhsIndex;
        });

        // Now let's re-insert records back to where they were
        childModels.forEach(m => {
            const ctx = context.get(m);

            parentModel.insertChild(m, ctx.parentIndex, undefined, { orderedParentIndex : ctx.orderedParentIndex });
        });
    }

    redo() {
        this.parentModel.removeChild(this.childModels);
    }
}
