import CalendarEditorBaseTab from './CalendarEditorBaseTab.js';
import './CalendarEditorWeekGrid.js';
import './CalendarEditorWeekPanel.js';

/**
 * @module SchedulerPro/widget/calendareditor/CalendarEditorWeekTab
 */

/**
 * The {@link SchedulerPro/widget/CalendarEditor calendar editor} "Weeks" tab.
 * The tab contains a {@link SchedulerPro/widget/calendareditor/CalendarEditorWeekGrid grid}
 * and a {@link SchedulerPro/widget/calendareditor/CalendarEditorWeekPanel panel} partnered
 * to display and edit week intervals respectively.
 *
 * @extends SchedulerPro/widget/calendareditor/CalendarEditorBaseTab
 * @classtype calendareditorweektab
 * @internal
 * @widget
 */
export default class CalendarEditorWeekTab extends CalendarEditorBaseTab {

    static $name = 'CalendarEditorWeekTab';

    static type = 'calendareditorweektab';

    static configurable = {
        defaultWorkingDayAvailability : null,
        items                         : {
            grid : {
                type   : 'calendareditorweekgrid',
                weight : 100,
                bbar   : {
                    items : {
                        addButton : {
                            tooltip : 'L{CalendarEditorWeekTab.addWeek}'
                        },
                        removeButton : {
                            tooltip : 'L{CalendarEditorWeekTab.removeWeek}'
                        }
                    }
                }
            },
            panel : {
                type              : 'calendareditorweekpanel',
                isolateFields     : true,
                weight            : 200,
                internalListeners : {
                    addAddDefaultWeekClick : 'up.onAddAddDefaultWeekClick'
                }
            }
        }
    };

    updateDefaultWorkingDayAvailability(value) {
        this.widgetMap.panel.defaultWorkingDayAvailability = value;
    }

    //region Event listeners

    internalOnAddRecordClick() {
        const data = {};

        // If got no default week with availability provided
        if (!this.widgetMap.grid.store.find(record => record.isOverride && record.hasAvailability())) {
            data.startDate = null;
            data.endDate = null;
        }

        this.trigger('addRecordClick', data);
    }

    onAddAddDefaultWeekClick() {
        this.trigger('addRecordClick', { data : { startDate : null, endDate : null } });
    }

    onGridSelect({ source }) {
        super.onGridSelect(...arguments);
    }

    onGridRenderRows({ source }) {
        super.onGridRenderRows(...arguments);

        this.toggleRemoveButton();
    }

    //endregion Event listeners

    toggleRemoveButton() {
        super.toggleRemoveButton(this.widgetMap.grid.store.count <= 1);
    }
}

CalendarEditorWeekTab.initClass();
