import NumberFormat from './util/NumberFormat.js';
import LocaleManager from '../localization/LocaleManager.js';

/**
 * A static class offering number of formatting helper methods.
 * @static
 * @internal
 */
export default class NumberHelper {

    static get $name() {
        return 'NumberHelper';
    }

    static getLocalizedFormatter(formatConfig) {
        return typeof formatConfig === 'string'
            // number format provided as string - augment with the locale
            ? NumberFormat.get({ locale : LocaleManager.localeCode, template : formatConfig })
            : formatConfig?.locale != null
                // number format provided as config object with locale already specified - do nothing
                ? NumberFormat.get(formatConfig)
                // number format not provided, or provided as config object with no locale specified - augment with the locale
                : NumberFormat.get(Object.assign({}, formatConfig, { locale : LocaleManager.localeCode }));
    }
}
