import Column from '../../Grid/column/Column.js';
import ColumnStore from '../../Grid/data/ColumnStore.js';
import AttachToProjectMixin from '../../Scheduler/data/mixin/AttachToProjectMixin.js';
import CalendarField from '../widget/CalendarField.js';

/**
 * @module SchedulerPro/column/ResourceCalendarColumn
 */

/**
 * A column that displays (and allows user to update) the current {@link SchedulerPro.model.CalendarModel calendar} of
 * the resource.
 *
 * Default editor is a {@link SchedulerPro.widget.CalendarField}.
 *
 * {@inlineexample SchedulerPro/column/ResourceCalendarColumn.js}
 *
 * @mixes Scheduler/data/mixin/AttachToProjectMixin
 * @extends Grid/column/Column
 * @classtype resourceCalendar
 * @column
 */
export default class ResourceCalendarColumn extends Column.mixin(AttachToProjectMixin) {

    //region Config

    static $name = 'ResourceCalendarColumn';

    static type = 'resourceCalendar';

    static get defaults() {
        return {
            field  : 'calendar',
            text   : 'L{calendar}',
            width  : 250,
            editor : {
                type         : CalendarField.type,
                clearable    : true,
                allowInvalid : false
            }
        };
    }

    //endregion

    //region Init

    construct() {
        super.construct(...arguments);

        this.grid.ion({
            beforeCellEditStart : 'onBeforeCellEditStart',
            thisObj             : this
        });
    }

    attachToProject(project) {
        if (project) {
            // Store default calendar to filter out this value
            this.defaultCalendar = project.defaultCalendar;

            this.editor.store = project.calendarManagerStore;
        }
    }

    attachToResourceStore(resourceStore) {
        super.attachToResourceStore(resourceStore);

        if (resourceStore) {
            resourceStore.ion({
                name    : 'resourceStore',
                update  : 'onResourceUpdate',
                thisObj : this
            });
        }
    }

    //endregion

    //region Events

    // Event rendering does not update cells when engine updates a resource, instead we do a minimal update here
    onResourceUpdate({ record, changes }) {
        const change = changes[this.field];

        if (change) {
            // Ignore "normalization" of id -> instance, won't affect our appearance
            if (typeof change.oldValue === 'string' && change.value?.id === change.oldValue) {
                return;
            }
            this.refreshCell(record);
        }
    }

    onBeforeCellEditStart({ grid, editorContext }) {
        const { editor } = editorContext;

        if (editorContext.column === this && editor.calendarEditor) {
            editor.calendarConsumerRecord    = editorContext.record;
            // show scheduler visible date start in the calendar editor date picker
            editor.calendarEditor.activeDate = grid.visibleDateRange.startDate;
            // let field know that we are cell editing
            editor.cellEditing               = true;
        }
    }

    //endregion

    //region Render

    defaultRenderer({ value }) {
        if (value?.id) {
            const record = this.editor.store.getById(value.id);
            return record?.getValue(this.editor.displayField) || '';
        }
        else {
            return '';
        }
    }

    //endregion

}

ColumnStore.registerColumnType(ResourceCalendarColumn);
