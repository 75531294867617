import Container from './Container.js';
import Rotatable from './mixin/Rotatable.js';

import './Button.js';
import Validatable from './mixin/Validatable.js';

/**
 * @module Core/widget/ButtonGroup
 */

const
    toggleOrderFn = ({ $toggleOrder : a }, { $toggleOrder : b }) => (b || 0) - (a || 0),
    buttonListeners = {
        click        : 'resetValueCache',
        beforeToggle : 'onItemBeforeToggle',
        toggle       : 'onItemToggle'
    };

/**
 * A specialized container that holds buttons, displaying them in a horizontal group with borders adjusted to make them
 * stick together.
 *
 * Trying to add other widgets than buttons will throw an exception.
 *
 * ```javascript
 * new ButtonGroup({
 *     items : [
 *         { icon : 'b-fa b-fa-kiwi-bird' },
 *         { icon : 'b-fa b-fa-kiwi-otter' },
 *         { icon : 'b-fa b-fa-kiwi-rabbit' },
 *         ...
 *     ]
 * });
 * ```
 *
 * {@inlineexample Core/widget/ButtonGroup.js}
 *
 * @extends Core/widget/Container
 * @classtype buttonGroup
 * @widget
 */
export default class ButtonGroup extends Container.mixin(Rotatable, Validatable) {
    static $name = 'ButtonGroup';
    static type = 'buttongroup';

    /**
     * Fires when a button in the group is clicked
     * @event click
     * @param {Core.widget.Button} source Clicked button
     * @param {Event} event DOM event
     */

    /**
     * Fires when the default action is performed on a button in the group (the button is clicked)
     * @event action
     * @param {Core.widget.Button} source Clicked button
     * @param {Event} event DOM event
     */

    /**
     * Fires when a button in the group is toggled (the {@link Core.widget.Button#property-pressed} state is changed).
     * If you need to process the pressed button only, consider using {@link #event-click} event or {@link #event-action} event.
     * @event toggle
     * @param {Core.widget.Button} source Toggled button
     * @param {Boolean} pressed New pressed state
     * @param {Event} event DOM event
     */

    static configurable = {
        defaultType : 'button',

        /**
         * Custom CSS class to add to element. When using raised buttons (cls 'b-raised' on the buttons), the group
         * will look nicer if you also set that cls on the group.
         *
         * ```
         * new ButtonGroup({
         *   cls : 'b-raised,
         *   items : [
         *       { icon : 'b-fa b-fa-unicorn', cls : 'b-raised' },
         *       ...
         *   ]
         * });
         * ```
         *
         * @config {String}
         * @category CSS
         */
        cls : null,

        /**
         * An array of Buttons or typed Button config objects.
         * @config {ButtonConfig[]|Core.widget.Button[]}
         */
        items : null,

        /**
         * Default color to apply to all contained buttons, see {@link Core.widget.Button#config-color Button#color}.
         * Individual buttons can override the default.
         * @config {String}
         */
        color : null,

        /**
         * If you use gaps between your buttons, set this to `true` (controls whether to use border-radius for the button
         * elements)
         * @config {Boolean}
         * @default
         */
        useGap : false,

        /**
         * Set to `true` to turn the ButtonGroup into a toggle group, assigning a generated value to each contained
         * buttons {@link Core.widget.Button#config-toggleGroup toggleGroup config}. Individual buttons can
         * override the default.
         * @config {Boolean}
         */
        toggleGroup : null,

        valueSeparator : ',',

        columns : null,

        hideWhenEmpty : true,

        defaultBindProperty : 'value',

        errorTooltipCachePath : 'buttonGroupErrorTooltip',

        errorTooltipConfig : {
            forSelector : '.b-buttongroup.b-invalid'
        },

        overflowable : true,

        /**
         * A two-element array containing the minimum and maximum number of toggleable buttons. If a single number is
         * specified, it is converted to an array using that given value as the maximum and 1 is used as the minimum.
         * If `true` is specified, it is equivalent to `[0, Infinity]`, allowing any buttons to be toggled independently
         * or all buttons to be untoggled.
         *
         * A value of `null` (the default) is similar to `toggleable : 1` unless {@link #config-toggleGroup} is set,
         * or individual buttons are configured with their own {@link Core.widget.Button#config-toggleable} or
         * {@link Core.widget.Button#config-toggleGroup} values. If this config is used, `toggleable` and `toggleGroup`
         * should not be assigned to individual buttons.
         *
         * @prp {Number[]}
         * @accepts {Boolean|Number}
         */
        toggleable : null
    };

    afterConstruct() {
        super.afterConstruct();

        this.ion({
            change : 'internalOnChange'
        });
    }

    // ButtonGroup has no wrapper like field does
    // so return element instead since error tooltip uses field.inputWrap
    get inputWrap() {
        return this.element;
    }

    get isValid() {
        let result = super.isValid;

        const
            me = this,
            { toggleable } = me;

        me.clearError('L{Field.fieldRequired}', true);

        if (result && !me.disabled) {
            if ((me.required && !me.value) || (toggleable && me.pressed.length < toggleable[0])) {
                result = false;
                me.setError('L{Field.fieldRequired}', true);
            }
        }

        if (me.checkValidity && me.callback(me.checkValidity, me, [me]) === false) {
            result = false;
        }

        if (result && me.errors && Object.keys(me.errors).length) {
            result = false;
        }

        return result;
    }

    get pressed() {
        return this.items.filter(b => b.pressed).sort(toggleOrderFn);
    }

    internalOnChange() {
        this.syncInvalid();
    }

    onChildAdd(item) {
        super.onChildAdd(item);

        item.ion({
            thisObj : this,
            ...buttonListeners,

            // This needs to run before the 'click' event is relayed by this button group, in such listener
            // the `value` must already be updated
            prio : 10000
        });
    }

    onChildRemove(item) {
        item.un({
            thisObj : this,
            ...buttonListeners
        });

        super.onChildRemove(item);
    }

    onItemBeforeToggle({ pressed }) {
        const { toggleable } = this;

        return pressed || !toggleable || (this.pressed.length > toggleable[0]);
    }

    onItemToggle(event) {
        const
            me = this,
            toggleCount = (me.toggleCount || 0) + 1,
            { toggleable } = me;

        me.resetValueCache();

        if (!me.isSettingValue && (!me.toggleGroup || event.pressed)) {
            if (toggleable) {
                event.source.$toggleOrder = toggleCount;
                me.toggleCount = toggleCount;

                me.isSettingValue = true;
                me.pressed.slice(toggleable[1]).forEach(b => b.pressed = false);
                me.isSettingValue = false;
            }

            me.triggerFieldChange({ value : me.value, userAction : true, event });
        }
    }

    resetValueCache() {
        // reset cached value to revalidate next time it's requested
        this._value = null;
    }

    createWidget(widget) {
        const
            me   = this,
            type = me.constructor.resolveType(widget.type || 'button');

        if (type.isButton) {
            if (me.color && !widget.color) {
                widget.color = me.color;
            }

            if (!widget.toggleGroup && me.toggleGroup) {
                widget.toggleGroup = me.toggleGroup;
            }

            if (me.toggleable) {
                widget.toggleable = true;
            }
        }

        if (me.columns) {
            widget.width = `${100 / me.columns}%`;
        }

        widget = super.createWidget(widget);

        me.relayEvents(widget, ['click', 'action', 'toggle']);

        return widget;
    }

    changeToggleable(toggleable) {
        if (toggleable === true) {
            toggleable = [0, Infinity];
        }
        else if (typeof toggleable === 'number') {
            toggleable = [1, toggleable];
        }

        return toggleable || null;
    }

    updateToggleable(toggleable) {
        if (toggleable && !this.isConstructing) {
            this.pressed.slice(toggleable[1]).forEach(b => b.pressed = false);
        }
    }

    changeToggleGroup(toggleGroup, was) {
        return toggleGroup === true ? was || ButtonGroup.generateId('toggleGroup') : toggleGroup;
    }

    updateRotate(rotate) {
        this.eachWidget(btn => {
            if (btn.rotate !== false) {
                btn.rotate = rotate;
            }
        });
    }

    get valueAsArray() {
        const values = [];

        // collect pressed item values
        this.items.forEach(w => {
            if (w.pressed && w.value !== undefined) {
                values.push(w.value);
            }
        });

        return values;
    }

    get value() {
        const me = this;

        // if we don't have cached value
        // let's calculate it based on item values
        if (!me._value) {
            // build a string
            me._value = me.valueAsArray.join(me.valueSeparator);
        }

        return me._value;
    }

    set value(value) {
        const
            me       = this,
            oldValue = me.value;

        if (value == null) {
            value = [];
        }
        else if (typeof value == 'string') {
            value = value.split(me.valueSeparator);
        }
        else if (!Array.isArray(value)) {
            value = [value];
        }

        me._value = value.join(me.valueSeparator);

        me.isSettingValue = true;

        // Reflect value on items
        me.items.forEach(w => {
            if (w.value !== undefined) {
                w.pressed = value.includes(w.value);
            }
        });

        me.isSettingValue = false;

        if (!me.isConfiguring && oldValue !== me.value) {
            me.triggerFieldChange({ value : me.value, userAction : Boolean(me._isUserAction) });
        }
    }

    get validity() {
        return {};
    }

    onDisabled(disabled) {
        this.syncInvalid();

        // translate state to child buttons
        this.items.forEach(button => button.disabled = disabled || (!button.ignoreParentReadOnly && this.readOnly));
    }

    updateReadOnly(readOnly) {
        super.updateReadOnly(readOnly);

        this.updateDisabled(this.disabled);
    }

    addToOverflowMenu(menu, overrides, onCreateTwin) {
        const { toggleable, toggleGroup } = this;

        if (toggleGroup || (toggleable?.[0] === 1 && toggleable[1] === 1)) {
            overrides = {
                ...overrides,
                radio : true
            };
        }

        this.items.forEach(it => it.addToOverflowMenu(menu, overrides, onCreateTwin));
    }

    get widgetClassList() {
        const classList = super.widgetClassList;
        // if the buttons should be shown in rows
        this.columns && classList.push('b-columned');
        return classList;
    }

    updateUseGap(useGap) {
        if (!useGap) {
            this.element.classList.add('b-no-gap');
        }
    }
}

// Register this widget type with its Factory
ButtonGroup.initClass();
