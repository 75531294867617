import Grid from '../../../Grid/view/Grid.js';
import Validatable from '../../../Core/widget/mixin/Validatable.js';

/**
 * @module SchedulerPro/widget/calendareditor/CalendarEditorWeekGrid
 */

/**
 * Class implementing the grid from the {@link SchedulerPro/widget/CalendarEditor calendar editor} "Weeks" tab.
 * @extends Grid/view/Grid
 * @mixes Core/widget/mixin/Validatable
 * @classtype calendareditorweekgrid
 * @internal
 * @widget
 */
export default class CalendarEditorWeekGrid extends Grid.mixin(Validatable) {

    static $name = 'CalendarEditorWeekGrid';

    static type = 'calendareditorweekgrid';

    // <debug>
    // region Localization test
    static localization = [
        'L{errorNoDefaultWeek}',
        'L{errorMultipleDefaultWeeks}',
        'L{errorStartAfterEnd}',
        'L{errorNoWeekAvailability}',
        'L{errorInvalidWeekAvailability}'
    ];
    // endregion
    // </debug>

    //region Config

    static configurable = {
        errorTooltipCachePath : 'validatableGridErrorTooltip',

        errorTooltipConfig : {
            forSelector : '.b-calendareditorweekgrid.b-invalid'
        }
    };

    updateStore(store, oldStore) {
        super.updateStore(store, oldStore);

        const me = this;

        // trigger validation
        me.syncInvalid();

        me.storeChangeListenerDetacher?.();

        if (store) {
            me.storeChangeListenerDetacher = store.ion({
                change  : me.internalOnStoreChange,
                thisObj : me
            });
        }
    }

    //endregion Config

    // Grid has no inputWrap expected by Validatable
    // so return element instead since error tooltip uses field.inputWrap
    get inputWrap() {
        return this.element;
    }

    get validity() {
        return {};
    }

    get isValid() {
        const { store } = this;

        let errors;

        if (store) {
            errors = store.getErrors();

            // First reset all errors possibly returned by the store
            for (const error of Object.values(store.weekModelClass.errors)) {
                this.clearError(error, true);
            }

            for (const error of Object.values(store.constructor.errors)) {
                this.clearError(error, true);
            }

            if (errors) {
                for (const error of errors) {
                    this.setError(error, true);
                }
            }
        }

        return !errors;
    }

    internalOnStoreChange() {
        // show/hide error tooltip
        this.syncInvalid();
    }
}

// Register this widget type with its Factory
CalendarEditorWeekGrid.initClass();
