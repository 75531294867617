/**
 * @module Core/helper/Helpers
 */

// Not every module can import *Helper modules (because the Helpers ultimately import them). So, instead, the Helpers
// register themselves in this namespace (object) that all modules can import since this module imports nothing.

/**
 * @typedef HelpersType
 * @property {Core.helper.ArrayHelper} Array
 * @property {Core.helper.BrowserHelper} Browser
 * @property {Core.helper.CSSHelper} Css
 * @property {Core.helper.DateHelper} Date
 * @property {Core.helper.DomHelper} Dom
 * @property {Core.helper.EventHelper} Event
 * @property {Core.helper.FunctionHelper} Function
 * @property {Core.helper.ObjectHelper} Object
 * @property {Core.helper.StringHelper} String
 * @property {Core.helper.TimeZoneHelper} TimeZone
 * @property {Core.helper.VersionHelper} Version
 * @internal
 */

const
    registry = Object.create(null),
    helpers  = [
        // One of these things is not like the others...
        'Array', 'Browser', ['Css', 'CSSHelper'], 'Date', 'Dom', 'Event', 'Function', 'Object', 'String', 'TimeZone',
        'Version'
    ].map(el => Array.isArray(el) ? el : [el, `${el}Helper`]),
    mapify = entries => Object.assign(Object.create(null), Object.fromEntries(entries)),
    byName = mapify(helpers),
    byType = mapify(helpers.map(([k, v]) => [v, k])),
    denied = reason => (obj, name) => {
        throw new Error(`Cannot ${reason} "Helpers.${name}": object is readonly`);
    },
    /**
     * Collection of registered Helper singletons.
     * @type {HelpersType}
     * @internal
     */
    Helpers = new Proxy(registry, {
        defineProperty : denied('define property'),
        deleteProperty : denied('delete'),
        set            : denied('assign'),

        get(obj, name) {
            const ret = registry[name];

            if (!ret) {
                throw new Error(`Property not defined "Helpers.${name}": ${name in byName
                    ? `import ${byName[name]} before using it`
                    : `invalid helper name`}`);
            }

            return ret;
        }

        // we don't need any other getter traps because we populate "registry" (the proxy target) below.
    });

Object.defineProperty(registry, 'register', {
    // not enumerable, configurable, writable
    value(helper) {
        const
            name = helper.$name,
            key = byType[name];

        // <remove-on-release>
        if (!key) {
            throw new Error(`Invalid Helper registration "${name}"`);
        }

        if (registry[key]) {
            throw new Error(`Duplicate Helper registration for "${name}"`);
        }
        // </remove-on-release>

        registry[key] = helper;
    }
});

// Populating the proxy target object makes Object.keys(), for...in, etc work by default
helpers.forEach(ent => registry[ent[0]] = null);

export default Helpers;
