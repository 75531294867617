import Widget from '../../../Core/widget/Widget.js';
import EventHelper from '../../../Core/helper/EventHelper.js';

/**
 * @module SchedulerPro/widget/calendareditor/CalendarEditorLegend
 */

/**
 * A widget displaying legend on {@link SchedulerPro/widget/CalendarEditor the calendar editor}
 * "General" tab.
 * @extends Core/widget/Widget
 * @classtype calendareditorlegend
 * @internal
 * @widget
 */
export default class CalendarEditorLegend extends Widget {

    static $name = 'CalendarEditorLegend';

    static type = 'calendareditorlegend';

    // region Configs

    static configurable = {
        // this widget does not provide a value
        defaultBindProperty : null,
        /**
         * A store to collect calendar data from.
         * @config {SchedulerPro.data.calendareditor.CalendarEditorStore}
         */
        store               : null
    };

    updateStore(store) {
        this.detachListeners('store');

        store.ion({
            name    : 'store',
            change  : 'onCalendarStoreChange',
            thisObj : this
        });
    }

    // endregion Configs

    // region Rendering

    compose() {
        const { store } = this;

        if (!store) {
            return {};
        }

        return {
            children : [{
                tag      : 'ul',
                children : [
                    {
                        tag   : 'li',
                        class : 'b-working-day',
                        text  : this.L('L{workingDay}')
                    },
                    {
                        tag   : 'li',
                        class : 'b-nonworking-day',
                        text  : this.L('L{nonWorkingDay}')
                    },
                    ...store.getRange().map(interval => ({
                        tag   : 'li',
                        text  : interval.name || 'Default',
                        class : {
                            ...(interval.cls ? { [interval.cls] : 1 } : []),
                            'b-interval-legend' : 1,
                            'b-icon'            : 1
                        },
                        style : {
                            '--interval-badge-color' : interval.color
                        },
                        dataset : {
                            interval : interval.id
                        }
                    }))
                ]
            }]
        };
    }

    // endregion Rendering

    // region Listeners

    onInternalPaint({ firstPaint }) {
        if (firstPaint) {
            EventHelper.on({
                element  : this.rootElement,
                click    : 'internalOnIntervalClick',
                delegate : '.b-interval-legend',
                thisObj  : this
            });
        }
    }

    internalOnIntervalClick(event) {
        // get clicked record
        const interval = this.store.getById(event.target.dataset.interval);

        if (interval) {
            this.trigger('intervalClick', { ...event, interval });
        }
    }

    onCalendarStoreChange() {
        this.recompose();
    }

    onLocalized() {
        this.recompose();
    }

    // endregion Listeners
}

// Register this widget type with its Factory
CalendarEditorLegend.initClass();
