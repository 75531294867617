/**
 * @module SchedulerPro/widget/calendareditor/CalendarEditorAvailabilityRangeContainer
 */

import DH from '../../../Core/helper/DateHelper.js';
import Container from '../../../Core/widget/Container.js';

/**
 * Container displaying fields for editing availability ranges.
 * The widget is used by {@link SchedulerPro/widget/CalendarEditor the calendar editor}.
 *
 * @extends Core/widget/Container
 * @classtype calendareditoravailabilityrangecontainer
 * @internal
 * @widget
 */
export default class CalendarEditorAvailabilityRangeContainer extends Container {

    static $name = 'CalendarEditorAvailabilityRangeContainer';

    static type = 'calendareditoravailabilityrangecontainer';

    // <debug>
    // region Localization test
    static localization = [
        'L{AvailabilityRangeError.errorOverlap}',
        'L{AvailabilityRangeError.errorMissingDate}',
        'L{AvailabilityRangeError.errorStartAfterEnd}'
    ];
    // endregion
    // </debug>

    // region Configs

    static configurable = {
        /**
         * Store providing ranges to edit.
         * @config {SchedulerPro.data.calendareditor.AvailabilityRangeStore}
         */
        store : null,

        defaultAvailability : [
            {
                startDate : '08:00',
                endDate   : '12:00'
            },
            {
                startDate : '13:00',
                endDate   : '17:00'
            }
        ],

        defaultBindProperty : 'store'
    };

    updateStore(store) {
        this.detachListeners('store');

        store?.ion({
            name    : 'store',
            change  : this.onAvailabilityUpdate,
            thisObj : this
        });

        this.renderAvailabilityFields(store);
    }

    // endregion Configs

    onAvailabilityUpdate() {
        this.renderAvailabilityFields();
    }

    renderAvailabilityFields(availability = this.store) {
        const
            me           = this,
            toRemove     = [],
            foundRecords = new Set();

        // Check existing range controls w/ availability records
        me.eachWidget(widget => {
            // if its record is no longer in the availability store need to remove the container
            if (!availability?.getById(widget.record.id)) {
                // Ensure focus doesn't exit the Popup on remove of a focused item
                if (widget.containsFocus) {
                    widget.revertFocus();
                }
                toRemove.push(widget);
            }
            // remember the record id we met
            else {
                foundRecords.add(widget.record);
            }
        }, false);

        if (availability) {
            // Make containers for new ranges
            for (const range of availability) {
                // this range has no controls yet
                if (!foundRecords.has(range)) {
                    me.addTimeRangeFields(range);
                }
            }
        }

        // remove outdated containers
        toRemove.forEach(w => {
            me.remove(w); w.destroy();
        });

        me.trigger('renderAvailabilityFields');

        // trigger container validation to highlight its invalid controls
        me.isValid;
    }

    addTimeRangeFields(record) {
        this.add({
            type                : 'container',
            cls                 : 'b-availability-range',
            record,
            autoUpdateRecord    : true,
            autoUpdateFields    : true,
            strictRecordMapping : true,
            // don't add these field values to the panel.values
            isolateFields       : true,
            items               : {
                startDateField : {
                    type          : 'timeField',
                    name          : 'startDate',
                    flex          : 'none',
                    autoExpand    : true,
                    triggers      : null,
                    required      : true,
                    checkValidity : 'up.availabilityStartDateIsValid'
                },
                separator    : '-',
                endDateField : {
                    type              : 'timeField',
                    name              : 'endDate',
                    flex              : 'none',
                    autoExpand        : true,
                    triggers          : null,
                    required          : true,
                    checkValidity     : 'up.availabilityEndDateIsValid',
                    internalListeners : {
                        transformTimeValue : 'up.onEndDateValueTransform'
                    }
                },
                removeRangeButton : {
                    type : 'button',
                    icon : 'b-icon b-fa-xmark',
                    cls  : {
                        'b-transparent' : 1
                    },
                    record,
                    onClick : 'up.onRemoveRangeClick',
                    tooltip : `L{CalendarEditorAvailabilityRangeContainer.removeRange}`
                },
                addRangeButton : {
                    type : 'button',
                    icon : 'b-icon b-fa-add',
                    cls  : {
                        'b-transparent' : 1
                    },
                    record,
                    onClick : 'up.onAddRangeClick',
                    tooltip : `L{CalendarEditorAvailabilityRangeContainer.addRange}`
                }
            }
        });
    }

    checkAvailabilityValuesValidityForField(field, values = {}) {
        const
            { record } = field.up('container'),
            errors = record.getErrors({
                [field.name] : field.value,
                ...values
            });

        // First reset all errors possibly returned by the record
        for (const error of Object.values(record.constructor.errors)) {
            field.clearError(`L{AvailabilityRangeError.${error}}`, true);
        }

        if (errors) {
            for (const error of errors) {
                field.setError(`L{AvailabilityRangeError.${error}}`, true);
            }
        }
    }

    availabilityStartDateIsValid(field) {
        this.checkAvailabilityValuesValidityForField(field);
    }

    availabilityEndDateIsValid(field) {
        // add 1 day for midnight to pass startDate < endDate checks
        const endDate = field.value && DH.isMidnight(field.value)
            ? DH.getStartOfNextDay(field.value, true) : field.value;

        this.checkAvailabilityValuesValidityForField(field, { endDate });
    }

    onEndDateValueTransform(eventData) {
        const { value } = eventData;

        if (value) {
            let val = DH.getTime(value);

            // adjust value +24 hours if midnight ..set date to default (Jan 1st) otherwise
            if (DH.isMidnight(val)) {
                val = DH.getStartOfNextDay(val);
            }

            eventData.value = val;
        }
    }

    /**
     * Adds a new time range to the {@link #config-store}.
     */
    addRanges(ranges) {
        const { store } = this;

        // if got interval(s) add another 1-hour one ..starting in an hour
        if (store.last?.endDate) {
            const
                startDate = DH.add(store.last?.endDate, 1, 'h', true),
                endDate   = DH.add(startDate, 1, 'h', true);

            store.add({ startDate, endDate });
        }
        else {
            store.add(ranges || this.defaultAvailability || {});
        }
    }

    onAddRangeClick() {
        this.addRanges();
    }

    onRemoveRangeClick({ source }) {
        if (this.store.count > 1) {
            this.store.remove(source.record);
        }
        // Instead of removing the last record left in the store
        // let's reset its field values to 00:00-00:00 and start cell editing.
        // Our UI renders buttons for adding ranges on rows only
        // so we cannot allow removing all of them.
        else {
            source.record.set({
                startDate : '00:00',
                endDate   : '00:00'
            });
        }
    }
}

// Register this widget type with its Factory
CalendarEditorAvailabilityRangeContainer.initClass();
