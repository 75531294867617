import Store from '../../../Core/data/Store.js';
import ColorField from '../../../Core/widget/ColorField.js';
import Container from '../../../Core/widget/Container.js';
import DateField from '../../../Core/widget/DateField.js';
import RadioGroup from '../../../Core/widget/RadioGroup.js';
import RecurrenceEditorPanel from '../../../Scheduler/view/recurrence/RecurrenceEditorPanel.js';
import CalendarEditorStore from '../../data/calendareditor/CalendarEditorStore.js';
import CalendarEditorAvailabilityRangeContainer from './CalendarEditorAvailabilityRangeContainer.js';

/**
 * @module SchedulerPro/widget/calendareditor/CalendarEditorExceptionPanel
 */

/**
 * A panel implementing calendar exception interval editor.
 * The panel is used by the {@link SchedulerPro/widget/CalendarEditor calendar editor} "Exceptions" tab.
 *
 * @extends Core/widget/Container
 * @classtype calendareditorexceptionpanel
 * @internal
 * @widget
 */
export default class CalendarEditorExceptionPanel extends Container {
    static $name = 'CalendarEditorExceptionPanel';
    static type = 'calendareditorexceptionpanel';

    // <debug>
    // region Localization test
    static localization = [
        'L{errorMissingDate}',
        'L{errorStartAfterEnd}',
        'L{errorStartAndEndRepeatNumberMismatch}'
    ];
    // endregion
    // </debug>

    // region Configs

    static configurable = {
        defaultWorkingDayAvailability : [
            {
                startDate : '08:00',
                endDate   : '16:00'
            }
        ],
        autoUpdateRecord    : true,
        strictRecordMapping : true,
        items               : {
            exceptionForm : {
                type     : 'container',
                cls      : 'b-calendareditor-form',
                defaults : {
                    localeClass : this
                },
                items : {
                    nameField : {
                        type     : 'textfield',
                        name     : 'name',
                        label    : 'L{name}',
                        required : true
                    },
                    colorField : {
                        type           : ColorField.type,
                        name           : 'color',
                        addNoColorItem : false,
                        colors         : CalendarEditorStore.intervalColors
                    },
                    exceptionStartDateField : {
                        type          : DateField.type,
                        name          : 'startDate',
                        label         : 'L{from}',
                        checkValidity : 'up.dateFieldIsValid'
                    },
                    exceptionEndDateField : {
                        type          : DateField.type,
                        name          : 'endDate',
                        label         : 'L{to}',
                        checkValidity : 'up.dateFieldIsValid'
                    },
                    isWorkingField : {
                        type     : RadioGroup.type,
                        name     : 'isWorking',
                        label    : 'L{is}',
                        defaults : {
                            localeClass : this
                        },
                        items : [
                            {
                                text         : 'L{nonWorking}',
                                name         : 'isWorking',
                                checkedValue : false
                            },
                            {
                                text         : 'L{working}',
                                name         : 'isWorking',
                                checkedValue : true
                            }
                        ],
                        internalListeners : {
                            change : 'up.onIsWorkingFieldChange'
                        }
                    },
                    availabilitySplitter : {
                        html                  : '',
                        localizableProperties : ['dataset.text'],
                        dataset               : {
                            text : 'L{hours}'
                        },
                        cls    : 'b-divider b-span-row',
                        hidden : true
                    },
                    availabilityContainer : {
                        type     : CalendarEditorAvailabilityRangeContainer.type,
                        name     : 'availability',
                        cls      : 'b-span-row',
                        required : true
                    },
                    startRepeatToggle : {
                        type              : 'slidetoggle',
                        cls               : 'b-slidetoggle-splitter b-span-row',
                        label             : 'L{repeat}',
                        internalListeners : {
                            change : 'up.onStartRepeatToggleChange'
                        }
                    },
                    startRepeatPanel : {
                        type                : RecurrenceEditorPanel.type,
                        cls                 : 'b-span-row',
                        name                : 'startRecurrence',
                        defaultBindProperty : 'record',
                        isolateFields       : true,
                        autoUpdateRecord    : true,
                        items               : {
                            monthDaysButtonField : {
                                checkValidity     : 'up.startMonthDaysButtonFieldIsValid',
                                internalListeners : {
                                    change : 'up.onStartMonthDaysButtonFieldChange'
                                }
                            },
                            monthsButtonField : {
                                checkValidity     : 'up.startMonthsButtonFieldIsValid',
                                internalListeners : {
                                    change : 'up.onStartMonthsButtonFieldChange'
                                }
                            },
                            intervalField       : false,
                            stopRecurrenceField : false,
                            frequencyField      : {
                                // Daily iterator has only one parameter "interval"
                                // but LaterJS cannot handle it properly so get rid of DAILY iterator completely
                                buildItems() {
                                    return this.constructor.prototype.buildItems().filter(r => r.value !== 'DAILY');
                                },
                                internalListeners : {
                                    change : 'up.onStartFrequencyChange'
                                }
                            },
                            positionsCombo : {
                                // LaterJS cannot handle properly "last ..." rule
                                // so we leave here only 1st/2nd/3rd/4th/5th entries
                                buildItems() {
                                    return Store.new({
                                        fields : ['value', 'text', 'cls'],
                                        data   : this.buildDayNumbers()
                                    });
                                }
                            },
                            daysCombo : {
                                // LaterJS cannot handle properly "Nth weekend/weekday/day" rules
                                // so we leave here only days
                                buildItems() {
                                    return this.weekDays;
                                }
                            }
                        }
                    },
                    endRepeatToggle : {
                        type              : 'slidetoggle',
                        cls               : 'b-slidetoggle-splitter b-span-row',
                        label             : 'L{repeatEnd}',
                        internalListeners : {
                            change : 'up.onEndRepeatToggleChange'
                        }
                    },
                    endRepeatPanel : {
                        type                : RecurrenceEditorPanel.type,
                        cls                 : 'b-span-row',
                        name                : 'endRecurrence',
                        defaultBindProperty : 'record',
                        isolateFields       : true,
                        autoUpdateRecord    : true,
                        items               : {
                            monthDaysButtonField : {
                                checkValidity     : 'up.endMonthDaysButtonFieldIsValid',
                                internalListeners : {
                                    change : 'up.onEndMonthDaysButtonFieldChange'
                                }
                            },
                            monthsButtonField : {
                                checkValidity     : 'up.endMonthsButtonFieldIsValid',
                                internalListeners : {
                                    change : 'up.onEndMonthsButtonFieldChange'
                                }
                            },
                            // Frequency is supposed to match start one
                            frequencyField : {
                                disabled : true
                            },
                            intervalField       : false,
                            // Stop conditions should match the start ones
                            stopRecurrenceField : false,
                            positionsCombo      : {
                                // LaterJS cannot handle properly "last ..." rule
                                // so we leave here only 1st/2nd/3rd/4th/5th entries
                                buildItems() {
                                    return Store.new({
                                        fields : ['value', 'text', 'cls'],
                                        data   : this.buildDayNumbers()
                                    });
                                }
                            },
                            daysCombo : {
                                // LaterJS cannot handle properly "Nth weekend/weekday/day" rules
                                // so we leave here only days
                                buildItems() {
                                    return this.weekDays;
                                }
                            }
                        }
                    }
                }
            },
            noRecordAddButton : {
                type              : 'button',
                icon              : 'b-icon-add',
                text              : 'L{CalendarEditorExceptionTab.addException}',
                hidden            : true,
                internalListeners : {
                    click : 'up.onAddExceptionClick'
                }
            }
        }
    };

    updateRecord(record) {
        const { widgetMap } = this;

        // reset las availability data
        widgetMap.availabilityContainer._lastValue = null;

        // start/end recurrence toggles are not real fields of the record so treat them separately
        widgetMap.startRepeatToggle.value = Boolean(record?.startRecurrence);
        widgetMap.endRepeatToggle.value   = Boolean(record?.startRecurrence && record?.endRecurrence);

        super.updateRecord(record);

        // force UI to show/hide respective controls
        this.onIsWorkingFieldChange({ value : widgetMap.isWorkingField.value });
        this.onStartRepeatToggleChange({ value : widgetMap.startRepeatToggle.value });
        this.onEndRepeatToggleChange({ value : widgetMap.endRepeatToggle.value });

        widgetMap.exceptionForm.hidden     = !record;
        widgetMap.noRecordAddButton.hidden = Boolean(record);
    }

    updateDefaultWorkingDayAvailability(value) {
        this.widgetMap.availabilityContainer.defaultAvailability = value;
    }

    // endregion Configs

    // region Validation

    validateButtonGroupWidget(widget, pairWidget) {
        if (this.record) {
            const { errorStartAndEndRepeatNumberMismatch } = this.record.constructor.errors;

            widget.clearError(`L{CalendarEditorExceptionPanel.${errorStartAndEndRepeatNumberMismatch}}`, true);

            if (pairWidget && widget.valueAsArray.length !== pairWidget.valueAsArray.length) {
                widget.setError(`L{CalendarEditorExceptionPanel.${errorStartAndEndRepeatNumberMismatch}}`, true);
                return false;
            }
        }

        return true;
    }

    startMonthDaysButtonFieldIsValid(widget) {
        const { widgetMap } = this;

        return this.validateButtonGroupWidget(widget, widgetMap.endRepeatToggle.checked &&
            widgetMap.endRepeatPanel.widgetMap.monthDaysButtonField);
    }

    endMonthDaysButtonFieldIsValid(widget) {
        return this.validateButtonGroupWidget(widget, this.widgetMap.startRepeatPanel.widgetMap.monthDaysButtonField);
    }

    startMonthsButtonFieldIsValid(widget) {
        const { widgetMap } = this;

        return this.validateButtonGroupWidget(widget, widgetMap.endRepeatToggle.checked &&
            widgetMap.endRepeatPanel.widgetMap.monthButtonField);
    }

    endMonthsButtonFieldIsValid(widget) {
        return this.validateButtonGroupWidget(widget, this.widgetMap.startRepeatPanel.widgetMap.monthButtonField);
    }

    dateFieldIsValid(field) {
        const { record } = this;

        if (record) {
            const { errors } = record.constructor;

            Object.values(errors).forEach(error => field.clearError(error, true));

            const
                recordErrors = record.getErrors({ [field.name] : field.value }),
                // find an error relevant to dates
                error = recordErrors?.filter(e => e === errors.errorMissingDate || e === errors.errorStartAfterEnd)[0];

            if (error) {
                field.setError(error, true);
                return false;
            }
        }
        else {
            field.clearError(null, true);
        }

        return true;
    }

    // endregion Validation

    processValuesBeforeSet(values) {
        if (values) {
            const { startRecurrence, endRecurrence } = values;

            // <remove-on-release>
            // TODO check if this is needed?
            // </remove-on-release>
            if (!startRecurrence?.isRecurrenceModel) {
                values.startRecurrence = null;
            }

            if (!endRecurrence?.isRecurrenceModel) {
                values.endRecurrence = null;
            }
        }
    }

    setValues(values, ...args) {
        this.processValuesBeforeSet(values);
        super.setValues(values, ...args);
    }

    set values(values) {
        this.processValuesBeforeSet(values);
        super.values = values;
    }

    get values() {
        return super.values;
    }

    //region Listeners

    onStartMonthDaysButtonFieldChange({ userAction }) {
        // if not propagating field change already
        if (!this._propagatingFieldChange) {
            this._propagatingFieldChange = true;
            // propagate the change to endRepeatPanel we are paired with
            this.widgetMap.endRepeatPanel.widgetMap.monthDaysButtonField.triggerFieldChange({ userAction });
            this._propagatingFieldChange = false;
        }
    }

    onEndMonthDaysButtonFieldChange({ userAction }) {
        // if not propagating field change already
        if (!this._propagatingFieldChange) {
            this._propagatingFieldChange = true;
            // propagate the change to startRepeatPanel we are paired with
            this.widgetMap.startRepeatPanel.widgetMap.monthDaysButtonField.triggerFieldChange({ userAction });
            this._propagatingFieldChange = false;
        }
    }

    onStartMonthsButtonFieldChange({ userAction }) {
        // if not propagating field change already
        if (!this._propagatingFieldChange) {
            this._propagatingFieldChange = true;
            // propagate the change to endRepeatPanel we are paired with
            this.widgetMap.endRepeatPanel.widgetMap.monthsButtonField.triggerFieldChange({ userAction });
            this._propagatingFieldChange = false;
        }
    }

    onEndMonthsButtonFieldChange({ userAction }) {
        // if not propagating field change already
        if (!this._propagatingFieldChange) {
            this._propagatingFieldChange = true;
            // propagate the change to startRepeatPanel we are paired with
            this.widgetMap.startRepeatPanel.widgetMap.monthsButtonField.triggerFieldChange({ userAction });
            this._propagatingFieldChange = false;
        }
    }

    onAddExceptionClick() {
        this.owner.trigger('addRecordClick');
    }

    onStartFrequencyChange({ value }) {
        const
            { widgetMap }       = this,
            { endRepeatToggle } = widgetMap,
            endFrequencyField   = widgetMap.endRepeatPanel?.widgetMap.frequencyField,
            isWeekly            = value === 'WEEKLY';

        if (endFrequencyField) {
            endFrequencyField.value = value;
        }

        // No end repeat if weekly
        if (endRepeatToggle) {
            if (isWeekly) {
                endRepeatToggle.value = false;
                this.onEndRepeatToggleChange({ value : false, userAction : true });

            }
            endRepeatToggle.disabled = isWeekly;
        }
    }

    onIsWorkingFieldChange({ value, userAction }) {
        const { availabilityContainer, availabilitySplitter } = this.widgetMap;

        availabilitySplitter.hidden = availabilityContainer.hidden = !value;

        // Remove/add availability records if that's caused by user click
        if (userAction) {
            // if we made the interval working - add prev used availability (or default one) to the grid
            if (!this.isSettingValues && !availabilityContainer.store.count) {
                availabilityContainer.addRanges(availabilityContainer._lastValue);
            }

            if (value) {
                availabilityContainer.firstItem.focus();
            }
            // remove availability if interval is non-working
            else {
                const availability = availabilityContainer.store.allRecords
                    .filter(record => record.isValid)
                    .map(record => record.toJSON());

                availabilityContainer._lastValue = availability.length && availability;

                availabilityContainer.store.removeAll();
            }
        }
    }

    onStartRepeatToggleChange({ userAction, value }) {
        const { startRepeatPanel, endRepeatPanel, endRepeatToggle } = this.widgetMap;

        if (userAction) {
            const
                { record }      = this,
                startRecurrence = value ? record.startRecurrence || record.buildRecurrenceModel({
                    frequency : 'MONTHLY',
                    date      : record.startDate
                }) : null;

            startRepeatPanel.record   = startRecurrence;
            // announce startRepeatPanel.record changed its value
            startRepeatPanel.triggerFieldChange({ value : startRecurrence, userAction });

            // If user toggles start pattern off, also toggle end pattern
            if (!value && endRepeatToggle.value) {
                endRepeatToggle.value = false;
                // announce startRepeatPanel.record changed its value
                endRepeatPanel.triggerFieldChange({ value : null, userAction });
            }
            this.isValid; // Trigger validation check
        }

        startRepeatPanel.disabled = startRepeatPanel.hidden = endRepeatToggle.disabled = !value;
    }

    onEndRepeatToggleChange({ userAction, value }) {
        const { endRepeatPanel, startRepeatPanel } = this.widgetMap;

        if (userAction) {
            const
                { record }    = this,
                startRecurrence = startRepeatPanel.record,
                endRecurrence = value ? record.endRecurrence || record.buildRecurrenceModel({
                    frequency : startRecurrence.frequency,
                    date      : record.endDate
                }) : null;

            endRepeatPanel.record = endRecurrence;
            endRepeatPanel.triggerFieldChange({ value : endRecurrence, userAction });
        }

        endRepeatPanel.disabled  = endRepeatPanel.hidden = !value;
    }

    //endregion Listeners
}

CalendarEditorExceptionPanel.initClass();
