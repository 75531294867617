import Base from '../../../Core/Base.js';
import ObjectHelper from '../../../Core/helper/ObjectHelper.js';

/**
 * @module SchedulerPro/model/mixin/ProjectChangeHandlerMixin
 */

// Check if assigning a raw value will make the field change
const willChange = (fieldName, rawData, record) => {
    const
        // fieldName is taken from `record.modifications` which uses field names for all keys except id. So for
        // idField we need to specifically fall back to `id`
        field          = record.getFieldDefinition(fieldName === record.constructor.idField ? 'id' : fieldName),
        { dataSource } = field,
        newValue       = record.constructor.processField(fieldName, rawData[dataSource], record);

    return dataSource in rawData ? !field.isEqual(newValue, record.getValue(fieldName)) : true;
};

/**
 * This mixin allows syncing a changes object between projects. See {@link #function-applyProjectChanges} for usage
 * @mixin
 */
export default Target => class ProjectChangeHandlerMixin extends (Target || Base) {
    startConfigure(config) {
        // process the project first which ingests any configured data sources,
        this.getConfig('project');

        super.startConfigure(config);
    }

    beforeApplyProjectChanges() {
        const { stm } = this;

        let shouldResume  = false,
            transactionId = null;

        this.suspendChangeTracking();

        if (stm.enabled && !stm.isNavigatingRevisions) {
            shouldResume = true;

            if (stm.isRecording) {
                transactionId = stm.stash();
            }

            if (this.shouldIgnoreRemoteChangesInSTM) {
                stm.disable();
            }
            else {
                stm.startTransaction();
            }
        }

        return { shouldResume, transactionId };
    }

    /**
     * Allows to apply changes from one project to another. For method to produce correct results, projects should be
     * isomorphic - they should use same models and store configuration, also data in source and target projects
     * should be identical before changes to the source project are made and applied to the target project.
     * This method is meant to apply changes in real time - as source project is changed, changes should be applied to
     * the target project before it is changed.
     * When changes are applied all changes are committed and project is recalculated, which means target project
     * won't have any local changes after.
     *
     * Usage:
     * ```javascript
     * // Collect changes from first project
     * const { changes } = projectA;
     *
     * // Apply changes to second project
     * await projectB.applyProjectChanges(changes);
     * ```
     *
     * <div class="note">
     * This method will apply changes from the incoming object and accept all current project changes. Before
     * applying changes make sure you've processed current project changes in order not to lose them.
     * </div>
     *
     * <div class="note">
     * When {@link Scheduler/crud/AbstractCrudManagerMixin#config-autoSync} is enabled, and client wants to
     * apply changes without triggering a sync request, this method should be used instead of
     * {@link Scheduler/crud/AbstractCrudManagerMixin#function-applyChangeset}. It helps maintain current
     * behavior while providing flexibility for scenarios where syncing is not required.
     * </div>
     *
     * @param {Object} changes Project {@link Scheduler/crud/AbstractCrudManagerMixin#property-changes} object
     * @returns {Promise}
     */
    async applyProjectChanges(changes) {
        const
            me = this,
            {
                shouldResume,
                transactionId
            }  = me.beforeApplyProjectChanges();

        me.trigger('startApplyChangeset');

        // Raise a flag to let store know not to stash stm changes
        me.applyingChangeset = true;

        if (changes.project) {
            me.applyProjectResponse(changes.project);
        }

        // Apply changes from other project
        // Has to clone to be able to catch the change and clean it up after commit
        me.applyChangeset(ObjectHelper.clone(changes));

        await me.commitAsync();

        // This will clean up changes in the project model if they match incoming values
        me.commitRespondedChanges();

        me.afterApplyProjectChangesCleanup(changes);

        me.afterApplyProjectChanges(shouldResume, transactionId);

        me.applyingChangeset = false;

        // Trigger commit async in case non-project related field (e.g. name) was changed to update possibly
        // opened task editor
        await me.commitAsync();

        me.trigger('endApplyChangeset');
    }

    afterApplyProjectChangesCleanup(changes) {
        // The call to applyChangeset() clears changes (it might, but not always), but commitAsync() leads to new
        // changes. If those changes match what we requested, we flag them as not modified
        for (const storeId in changes) {
            const storeDescriptor = this.getStoreDescriptor(storeId);

            // if that a Store section
            if (storeDescriptor) {
                const
                    // Due to this issue better to use lookup on the project instance rather than in global registry
                    // https://github.com/bryntum/support/issues/5238
                    { store }    = storeDescriptor,
                    storeChanges = changes[storeId],
                    changedRows  = [...storeChanges.updated ?? [], ...storeChanges.added ?? []];

                // Store might be destroyed, asyncness...
                if (store) {
                    // Iterate updated and added rows
                    for (const data of changedRows) {
                        const record = store.getById(data[store.modelClass.idField]);

                        // Record might not have been added e.g. if change was conflicting and got resolved by rejecting
                        if (record) {
                            // Compare each change on the matching record with the raw data value, unflag change if they match
                            for (const fieldName in record.modifications) {
                                if (!willChange(fieldName, data, record)) {
                                    delete record.meta.modified[fieldName];
                                }
                            }
                        }
                        else {
                            const
                                phantomIdField = this.phantomIdField ?? storeDescriptor.phantomIdField ?? '$PhantomId',
                                record = store.getById(data[phantomIdField]);

                            if (record) {
                                store.added.remove(record);
                            }
                        }
                    }

                    for (const data of storeChanges.removed ?? []) {
                        store.removed.remove(data.id);
                    }
                }
            }
        }
    }

    afterApplyProjectChanges(shouldResume, transactionId) {
        if (shouldResume) {
            const { stm } = this;

            if (this.shouldIgnoreRemoteChangesInSTM) {
                stm.enable();
            }
            else {
                stm.stopTransaction();
            }

            stm.applyStash(transactionId);
        }

        this.resumeChangeTracking();
    }
};
