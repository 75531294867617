import Widget from '../../Core/widget/Widget.js';
import './CalendarEditor.js';
import ModelCombo from './ModelCombo.js';

/**
 * @module SchedulerPro/widget/CalendarField
 */

/**
 * A combo used to select the calendar for an event. This field can be seen in the {@link SchedulerPro.widget.taskeditor.AdvancedTab}
 *
 * {@inlineexample SchedulerPro/widget/CalendarField.js}
 *
 * @extends SchedulerPro/widget/ModelCombo
 * @classtype calendarfield
 * @inputfield
 */
export default class CalendarField extends ModelCombo {

    //region Config

    static $name = 'CalendarField';

    // Factoryable type name
    static type = 'calendarfield';

    static get configurable() {
        return {
            valueField   : 'id',
            displayField : 'name',
            editable     : false,

            clearable : true,

            /**
             * The consumer of the edited calendar.
             * Can be a resource, an event or a project model,
             * The value is required for {@link #config-calendarEditor "Edit calendar" trigger} functionality.
             *
             * @config {SchedulerPro.model.EventModel|SchedulerPro.model.ResourceModel|SchedulerPro.model.ProjectModel}
             */
            calendarConsumerRecord : null,

            /**
             * Configuration of the calendar editor triggered by clicking "Edit calendar" trigger.
             * Provide `false` to this config to skip both the editor and the trigger constructing.
             * @config {CalendarEditorConfig|false}
             */
            calendarEditor : {
                type          : 'calendareditor',
                autoClose     : true,
                autoShow      : false,
                isolateFields : true,
                centered      : true,
                modal         : true
            },

            /**
             * The store containing the calendars
             * @config {SchedulerPro.data.CalendarManagerStore}
             */
            store : null,

            listItemTpl : calendar => {
                return calendar.name || this.L('L{Default calendar}');
            },

            displayValueRenderer : (calendar, field) => {
                calendar = calendar || field.store?.project?.effectiveCalendar;

                return calendar?.name || this.L('L{Default calendar}');
            }
        };
    }

    changeCalendarEditor(value) {
        const me = this;

        me.detachListeners('calendarEditor');

        const result = value ? Widget.create(value) : value;

        if (result) {
            result.owner = me;
            result.ion({
                name    : 'calendarEditor',
                save    : me.onCalendarEditorSave,
                cancel  : me.onCalendarEditorCancel,
                remove  : me.onCalendarEditorRemove,
                thisObj : me
            });
        }

        return result;
    }

    updateProject() {
        this.syncCalendarEditorTrigger();
    }

    updateCalendarConsumerRecord(record) {
        const me = this;

        me.getConfig('project');

        if (record) {
            if (!me.project && record.project) {
                me.project = record.project;
            }

            // value can be read directly from the provided record
            if (!me.value && record.calendar) {
                me.value = record.calendar;
            }
        }

        me.syncCalendarEditorTrigger();
    }

    updateCalendarEditor(editor) {
        this.getConfig('triggers');

        this.triggers = {
            calendarEditor : editor && {
                // should go last
                weight  : -100,
                cls     : 'b-icon-calendar',
                handler : 'onCalendarEditorClick',
                tooltip : 'L{CalendarField.editCalendar}',
                key     : 'shift++'
            } || null
        };

        this.syncCalendarEditorTrigger();
    }

    //endregion Config

    //region Construction/destruction

    doDestroy() {
        this.calendarEditor?.destroy?.();
        super.doDestroy(...arguments);
    }

    //endregion Construction/destruction

    //region Listeners

    onCalendarEditorSave({ calendar }) {
        calendar.isCreating = false;
    }

    onCalendarEditorCancel({ source }) {
        const { calendar } = source;

        // if clicking cancel after adding a calendar - remove it
        if (calendar?.isCreating) {
            this.value = null;
            this.store.remove(calendar);
        }
    }

    onCalendarEditorRemove() {
        this.value = null;
    }

    syncCalendarEditorTrigger() {
        const
            me = this,
            calendarEditorTrigger = me.triggers.calendarEditor;

        if (calendarEditorTrigger) {
            const visible = me.calendarEditor && me.project && me.calendarConsumerRecord;

            if (visible) {
                calendarEditorTrigger.cls = me.value ? 'b-icon-calendar' : 'b-icon-calendar-plus';
                calendarEditorTrigger.hidden = false;
            }
            else {
                calendarEditorTrigger.hidden = true;
            }
        }
    }

    onCalendarEditorClick() {
        const me = this;

        if (!me.readOnly && !me.disabled) {
            let calendar = me.value;

            const
                { calendarEditor, project, calendarConsumerRecord } = me,
                // beforeEditCalendar event data
                eventData = { calendarEditor, calendar : calendar || {} };

            if (calendarConsumerRecord.isEventModel || calendarConsumerRecord.isProjectModel) {
                calendarEditor.activeDate = calendarConsumerRecord.startDate;
            }

            if (!calendar) {
                eventData.calendar.name = calendarConsumerRecord.name + ' - calendar';
            }

            me.trigger('beforeEditCalendar', eventData);

            calendar = eventData.calendar;

            // no calendar selected - make a copy of the project calendar
            if (!calendar?.isCalendarModel && project) {

                const projectCalendar = project.effectiveCalendar;

                if (!calendar.name) {
                    calendar.name = projectCalendar.name ? projectCalendar.name + ' - copy' : 'New calendar';
                }

                calendar = projectCalendar.copy(calendar);
                calendar.isCreating = true;

                me.store.add(calendar);
                me.value = calendar;
            }

            if (calendar?.isCalendarModel) {
                calendarEditor.calendar = calendar;
                calendarEditor.show();
            }
        }
    }

    //endregion Listeners

    //region Internal

    get value() {
        return super.value;
    }

    set value(value) {
        if (value?.isDefault?.()) {
            value = null;
        }

        // force calendarConsumerRecord configs processing so they could provide project value
        this.getConfig('calendarConsumerRecord');

        super.value = value;

        this.syncCalendarEditorTrigger();
    }

    compose() {
        // Force evaluation of calendarEditor so triggers include the calendarEditor trigger
        this.getConfig('calendarEditor');

        return super.compose();
    }

    // Have to override FIeld#syncEmpty to not take into account isEmptyInput
    // since input element in this case always has some value (a fallback to the project calendar name)
    syncEmpty() {
        const
            { isEmpty, element } = this,
            clear                = this.triggers?.clear;

        if (element) {
            if (clear) {
                clear[isEmpty ? 'hide' : 'show']();
            }
            element.classList.toggle('b-empty', isEmpty);
        }
    }

    //endregion

}

// Register this widget type with its Factory
CalendarField.initClass();
