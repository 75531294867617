/**
 * @module Core/data/stm/state/RevisionRecordingState
 */
import Transaction from '../Transaction.js';
import { throwInvalidMethodCall } from './StateBase.js';
import Registry from './Registry.js';
import {
    REVISION_INDEX_PROP,
    REVISION_QUEUE_PROP,
    REVISION_NAMES_PROP,
    STATE_PROP,
    TRANSACTION_PROP
} from '../Props.js';
import { RecordingStateClass } from './RecordingState.js';

/**
 * STM revision recording state class.
 *
 * @internal
 */
export class RevisionRecordingStateClass extends RecordingStateClass {
    canUndo(stm) {
        return false;
    }

    canRedo(stm) {
        return false;
    }

    canCheckoutTo() {
        return false;
    }

    onUndo(stm) {
        throwInvalidMethodCall();
    }

    onRedo(stm) {
        throwInvalidMethodCall();
    }

    onCheckoutToHead(stm) {
        throwInvalidMethodCall();
    }

    onStopRevision(stm, title) {
        const
            transaction   = stm[TRANSACTION_PROP],
            revisionQueue = stm[REVISION_QUEUE_PROP],
            revisions     = stm[REVISION_NAMES_PROP],
            position      = stm[REVISION_INDEX_PROP] + 1;

        // While recording a revision we might have had a conflict which marked some
        // actions as input and some as resolutions. To properly record remote revision we
        // need to only take original input.
        const revision = Transaction.createTransactionWithOriginalInput(transaction);

        revisions.splice(position, 0, revision.title);
        revisionQueue.splice(position - 1, 0, revision);

        stm.commitRevision(revision.title);

        return [{
            [STATE_PROP]          : 'checkoutstate',
            [REVISION_INDEX_PROP] : position,
            [TRANSACTION_PROP]    : null
        }, () => {
            stm.notifyStoresAboutRevRecordingStop(revision);
        }];
    }

    onStartTransaction(stm) {
        throwInvalidMethodCall();
    }

    onStopTransaction(stm) {
        throwInvalidMethodCall();
    }

    onStopTransactionDelayed(stm) {
        throwInvalidMethodCall();
    }

    onRejectTransaction(stm) {
        throwInvalidMethodCall();
    }

    onEnable(stm) {
        throwInvalidMethodCall();
    }

    onDisable(stm) {
        throwInvalidMethodCall();
    }

    onAutoRecordOn(stm) {
        throwInvalidMethodCall();
    }

    onAutoRecordOff(stm) {
        throwInvalidMethodCall();
    }

    onResetQueue(stm) {
        throwInvalidMethodCall();
    }
}

/**
 * STM revision recording state.
 *
 * @internal
 */
const RevisionRecordingState = new RevisionRecordingStateClass();
export default RevisionRecordingState;

Registry.registerStmState('revisionrecordingstate', RevisionRecordingState);
