/**
 * @module Core/data/stm/Props.js
 * @internal
 */

export const STATE_PROP             = Symbol('STATE_PROP');
export const STORES_PROP            = Symbol('STORES_PROP');
export const QUEUE_PROP             = Symbol('QUEUE_PROP');
export const POS_PROP               = Symbol('POS_PROP');
export const ACTION_QUEUE_PROP      = Symbol('ACTION_QUEUE_PROP');
export const REVISION_NAMES_PROP    = Symbol('REVISION_NAMES_PROP');
export const REVISION_QUEUE_PROP    = Symbol('REVISION_QUEUE_PROP');
export const REVISION_INDEX_PROP    = Symbol('REVISION_INDEX_PROP');
export const CURRENT_REV_ID_PROP    = Symbol('CURRENT_REV_ID_PROP');
export const TRANSACTION_PROP       = Symbol('TRANSACTION_PROP');
export const TRANSACTION_TIMER_PROP = Symbol('TRANSACTION_TIMER_PROP');
export const AUTO_RECORD_PROP       = Symbol('AUTO_RECORD_PROP');
export const IS_APPLYING_STASH      = Symbol('IS_APPLYING_STASH');
export const PROPS                  = Object.freeze([
    STATE_PROP,
    STORES_PROP,
    QUEUE_PROP,
    POS_PROP,
    ACTION_QUEUE_PROP,
    REVISION_NAMES_PROP,
    REVISION_QUEUE_PROP,
    REVISION_INDEX_PROP,
    CURRENT_REV_ID_PROP,
    TRANSACTION_PROP,
    TRANSACTION_TIMER_PROP,
    AUTO_RECORD_PROP,
    IS_APPLYING_STASH
]);
