import Panel from '../../../Core/widget/Panel.js';
import '../../../Core/widget/Widget.js';
import '../../../Core/widget/Button.js';
import '../../../Core/widget/Checkbox.js';
import '../../../Core/widget/SlideToggle.js';
import '../../../Core/widget/DateField.js';
import '../../../Core/widget/NumberField.js';
import './field/RecurrenceFrequencyCombo.js';
import './field/RecurrenceDaysCombo.js';
import './field/RecurrenceDaysButtonGroup.js';
import './field/RecurrenceMonthDaysButtonGroup.js';
import './field/RecurrenceMonthsButtonGroup.js';
import './field/RecurrenceStopConditionCombo.js';
import './field/RecurrencePositionsCombo.js';
import BrowserHelper from '../../../Core/helper/BrowserHelper.js';
import DateHelper from '../../../Core/helper/DateHelper.js';
import ArrayHelper from '../../../Core/helper/ArrayHelper.js';

/**
 * @module Scheduler/view/recurrence/RecurrenceEditorPanel
 */

/**
 * Panel containing fields used to edit a {@link Scheduler.model.RecurrenceModel recurrence model}. Used by
 * {@link Scheduler/view/recurrence/RecurrenceEditor}, and by the recurrence tab in Scheduler Pro's event editor.
 *
 * Not intended to be used separately.
 *
 * @extends Core/widget/Panel
 * @classtype recurrenceeditorpanel
 * @private
 */
export default class RecurrenceEditorPanel extends Panel {

    static $name = 'RecurrenceEditorPanel';

    static type = 'recurrenceeditorpanel';

    //#region Configs

    static configurable = {
        focusable           : false,
        cls                 : 'b-recurrenceeditor',
        record              : false,
        addNone             : false,
        strictRecordMapping : true,
        items               : {
            frequencyField : {
                type     : 'recurrencefrequencycombo',
                name     : 'frequency',
                label    : 'L{RecurrenceEditor.Frequency}',
                weight   : 10,
                onChange : 'up.onFrequencyFieldChange',
                addNone  : 'up.addNone'
            },
            intervalField : {
                type     : 'numberfield',
                weight   : 15,
                name     : 'interval',
                label    : 'L{RecurrenceEditor.Every}',
                min      : 1,
                required : true
            },
            daysLabel : {
                type         : 'label',
                text         : 'L{RecurrenceEditor.Days}',
                weight       : 18,
                forFrequency : 'WEEKLY',
                cls          : 'b-button-group-label'
            },
            daysButtonField : {
                type         : 'daybuttons',
                cls          : 'b-recurrencedaysbuttongroup',
                weight       : 20,
                name         : 'days',
                forFrequency : 'WEEKLY',
                useGap       : true,
                required     : true
            },
            emptyMonthsHiddenField : {
                type              : 'field',
                inputType         : 'hidden',
                name              : 'monthDays',
                forFrequency      : 'MONTHLY',
                internalListeners : {
                    beforeUpdateDisabled : 'up.onFieldBeforeUpdateDisabled',
                    // Enforce empty value for this field.
                    // It's used specially to reset monthDays when toggling states
                    change() {
                        this._value = null;
                    }
                }
            },
            // the radio button enabling "monthDaysButtonField" in MONTHLY mode
            monthDaysRadioField : {
                type                   : 'checkbox',
                weight                 : 30,
                toggleGroup            : 'radio',
                toggleGroupRootElement : '.b-recurrenceeditorpanel',
                forFrequency           : 'MONTHLY',
                label                  : 'L{RecurrenceEditor.Each}',
                checked                : true,
                onChange               : 'up.onMonthDaysRadioFieldChange',
                cls                    : 'b-label-with-checkbox'
            },
            monthDaysButtonField : {
                type              : 'recurrencemonthdaysbuttongroup',
                weight            : 40,
                name              : 'monthDays',
                forFrequency      : 'MONTHLY',
                cls               : 'b-space-above',
                required          : true,
                internalListeners : {
                    beforeUpdateDisabled : 'up.onFieldBeforeUpdateDisabled'
                }
            },
            // the radio button enabling monthDays number field in YEARLY modes
            monthDaysNumberRadioField : {
                type                   : 'checkbox',
                weight                 : 53,
                cls                    : 'b-label-with-checkbox',
                toggleGroup            : 'radio',
                toggleGroupRootElement : '.b-recurrenceeditorpanel',
                forFrequency           : 'YEARLY',
                label                  : 'L{RecurrenceEditor.on}',
                style                  : 'display:inline-flex;',
                onChange               : 'up.onMonthDaysNumberRadioFieldChange'
            },
            monthDaysNumberField : {
                type              : 'numberfield',
                weight            : 56,
                flex              : 1,
                name              : 'monthDays',
                forFrequency      : 'YEARLY',
                min               : 1,
                max               : 31,
                required          : true,
                internalListeners : {
                    beforeUpdateDisabled : 'up.onFieldBeforeUpdateDisabled'
                }
            },
            monthsLabel : {
                type         : 'label',
                text         : 'L{RecurrenceEditor.Months}',
                weight       : 57,
                forFrequency : 'YEARLY',
                cls          : 'b-button-group-label'
            },
            monthsButtonField : {
                type         : 'recurrencemonthsbuttongroup',
                weight       : 58,
                cls          : 'b-space-above b-space-below',
                name         : 'months',
                forFrequency : 'YEARLY',
                onChange     : 'up.onMonthsButtonFieldChange',
                required     : true
            },
            positionAndDayRadioField : {
                type                   : 'checkbox',
                weight                 : 60,
                cls                    : 'b-label-with-checkbox b-space-below',
                toggleGroup            : 'radio',
                toggleGroupRootElement : '.b-recurrenceeditorpanel',
                forFrequency           : 'MONTHLY|YEARLY',
                label                  : 'L{RecurrenceEditor.On the}',
                onChange               : 'up.onPositionAndDayRadioFieldChange'
            },
            positionsCombo : {
                type              : 'recurrencepositionscombo',
                weight            : 80,
                name              : 'positions',
                forFrequency      : 'MONTHLY|YEARLY',
                cls               : 'b-space-below b-no-span',
                internalListeners : {
                    beforeUpdateDisabled : 'up.onFieldBeforeUpdateDisabled'
                }
            },
            daysCombo : {
                type              : 'recurrencedayscombo',
                weight            : 90,
                name              : 'days',
                forFrequency      : 'MONTHLY|YEARLY',
                cls               : 'b-space-below b-no-span',
                internalListeners : {
                    beforeUpdateDisabled : 'up.onFieldBeforeUpdateDisabled'
                }
            },
            stopRecurrenceField : {
                type     : 'recurrencestopconditioncombo',
                weight   : 100,
                label    : 'L{RecurrenceEditor.End repeat}',
                onChange : 'up.onStopRecurrenceFieldChange',
                cls      : 'b-no-inner-span'
            },
            countField : {
                type              : 'numberfield',
                weight            : 110,
                name              : 'count',
                min               : 2,
                required          : true,
                disabled          : true,
                label             : ' ',
                cls               : 'b-no-span',
                internalListeners : {
                    beforeUpdateDisabled : 'up.onFieldBeforeUpdateDisabled'
                }
            },
            endDateField : {
                type              : 'datefield',
                weight            : 120,
                name              : 'endDate',
                hidden            : true,
                disabled          : true,
                label             : ' ',
                required          : true,
                cls               : 'b-no-span',
                internalListeners : {
                    beforeUpdateDisabled : 'up.onFieldBeforeUpdateDisabled'
                }
            }
        }
    };

    changeItems(items, oldItems) {
        const
            result = super.changeItems(items, oldItems),
            {
                positionAndDayRadioField,
                monthDaysNumberRadioField,
                monthDaysNumberField
            } = this.widgetMap;

        if (monthDaysNumberRadioField) {
            positionAndDayRadioField.label = this.L('L{RecurrenceEditor.the}');
        }
        else {
            positionAndDayRadioField.label = this.L('L{RecurrenceEditor.On the}');
            monthDaysNumberField && result.remove(monthDaysNumberField);
        }

        return result;
    }

    setupWidgetConfig(widgetConfig) {
        // All our inputs must be mutated using triggers and touch gestures on mobile
        if (BrowserHelper.isMobile && !('editable' in widgetConfig)) {
            widgetConfig.editable = false;
        }

        return super.setupWidgetConfig(...arguments);
    }

    updateRecord(record) {
        super.updateRecord(record);

        const
            me = this,
            {
                frequencyField,
                daysButtonField,
                monthDaysButtonField,
                monthDaysNumberField,
                monthsButtonField,
                monthDaysRadioField,
                monthDaysNumberRadioField,
                positionAndDayRadioField,
                stopRecurrenceField
            }  = me.widgetMap;

        if (record) {
            const timeSpanValues = record.getDateValues();

            // some field default values are calculated based on timeSpan.startDate
            if (timeSpanValues) {
                // if no "days" value provided
                if (!record.days?.length) {
                    daysButtonField.value = timeSpanValues.days;
                }

                // if no "monthDays" value provided
                if (!record.monthDays?.length) {
                    if (monthDaysButtonField) {
                        monthDaysButtonField.value = timeSpanValues.monthDays;
                    }

                    if (monthDaysNumberField) {
                        monthDaysNumberField.value = timeSpanValues.monthDays[0];
                    }
                }

                // if no "months" value provided
                if (!record.months?.length && monthsButtonField) {
                    monthsButtonField.value = timeSpanValues.months[0];
                }
            }

            // if the record has both "days" & "positions" fields set check "On the" checkbox
            if (daysButtonField.value && record.positions) {
                positionAndDayRadioField.check();
                // <remove-on-release>
                // TODO: if toggleGroup members are not painted automatic unchecking doesn't work
                // </remove-on-release>
                if (!me.isPainted) {
                    monthDaysRadioField.uncheck();
                }
            }
            else if (monthDaysButtonField?.value) {
                if (monthDaysNumberRadioField && !monthDaysNumberRadioField.hidden) {
                    monthDaysNumberRadioField.check();
                }
                else {
                    monthDaysRadioField.check();
                }

                // <remove-on-release>
                // TODO: if toggleGroup members are not painted automatic unchecking doesn't work
                // </remove-on-release>
                if (!me.isPainted || !me.element.isConnected) {
                    positionAndDayRadioField.uncheck();
                }
            }

            if (stopRecurrenceField) {
                stopRecurrenceField.recurrence = record;
            }
        }
        else {
            frequencyField.value = 'NONE';
        }
    }

    //#endregion Configs

    // Disabled field does not contribute to values, clear manually
    get valuesCleanState() {
        return {
            frequency : null,
            interval  : null,
            days      : null,
            months    : null,
            monthDays : null,
            positions : null,
            endDate   : null,
            count     : null
        };
    }

    processValues(result) {
        if ('monthDays' in result) {
            if (typeof result.monthDays === 'string') {
                result.monthDays = result.monthDays.split(',');
            }
            else {
                result.monthDays = ArrayHelper.asArray(result.monthDays);
            }
        }
    }

    get values() {
        // Disabled field does not contribute to values, clear manually
        const result = { ...this.valuesCleanState, ...super.values };

        this.processValues(result);

        return result;
    }

    set values(values) {
        super.values = values;
    }

    getValues() {
        // Disabled field does not contribute to values, clear manually
        const result = { ...this.valuesCleanState, ...super.getValues(...arguments) };

        this.processValues(result);

        return result;
    }

    /**
     * Updates the provided recurrence model with the contained form data.
     * If recurrence model is not provided updates the last loaded recurrence model.
     * @internal
     */
    syncEventRecord(recurrence) {
        recurrence = recurrence || this.record;

        // bail out if got no record
        if (recurrence) {
            // get values relevant to the RecurrenceModel (from enabled fields only)
            const values = this.getValues((w) => w.name in recurrence && !w.disabled);

            recurrence.set(values);
        }
    }

    toggleStopFields(userAction) {
        const
            me                           = this,
            stopValue                    = me.widgetMap.stopRecurrenceField?.value,
            { countField, endDateField } = me.widgetMap,
            countIsUsed                  = stopValue === 'count',
            dateIsUsed                   = !countIsUsed && stopValue === 'date';

        countField._userAction = endDateField._userAction = userAction;

        countField.disabled = countField.hidden = !countIsUsed;
        endDateField.disabled = endDateField.hidden = !dateIsUsed;

        countField._userAction = endDateField._userAction = false;
    }

    isWidgetAvailableForFrequency(widget, frequency = this.widgetMap.frequencyField.value) {
        return !widget.forFrequency || widget.forFrequency.includes(frequency);
    }

    //#region Events

    onFieldChange({ source, userAction }) {
        const { record } = this;

        if (userAction && this.autoUpdateRecord && record) {
            record.isSanitizingSuspended = true;
        }

        super.onFieldChange(...arguments);

        if (userAction && this.autoUpdateRecord && record) {
            record.isSanitizingSuspended = false;

            // Delay sanitize call to make sure all synchronous changes got into the record
            this.setTimeout({
                fn                : () => record.sanitize(),
                delay             : 50,
                cancelOutstanding : true
            });
        }
    }

    onFieldBeforeUpdateDisabled({ source, disabled }) {
        if (!disabled && source._userAction) {
            source.triggerFieldChange({ userAction : true });
        }
    }

    onMonthDaysRadioFieldChange({ source, checked, userAction, propagatingUserActionFrom }) {
        const { monthDaysButtonField, emptyMonthsHiddenField } = this.widgetMap;

        if (monthDaysButtonField) {
            monthDaysButtonField._userAction = userAction;
            monthDaysButtonField.disabled = !checked || !this.isWidgetAvailableForFrequency(monthDaysButtonField);
            monthDaysButtonField._userAction = false;
        }

        if (emptyMonthsHiddenField) {
            emptyMonthsHiddenField._userAction = userAction || propagatingUserActionFrom;
            emptyMonthsHiddenField.disabled = checked || !this.isWidgetAvailableForFrequency(emptyMonthsHiddenField);
            emptyMonthsHiddenField._userAction = false;
        }
    }

    onMonthsButtonFieldChange({ value }) {
        const
            monthNames               = DateHelper.getMonthNames(),
            { monthDaysNumberField } = this.widgetMap;

        if (value && monthDaysNumberField) {
            monthDaysNumberField.hint = value.split(',').map(month => monthNames[month - 1]).join(', ');
        }
    }

    onMonthDaysNumberRadioFieldChange({ checked, userAction }) {
        const { monthDaysNumberField } = this.widgetMap;

        monthDaysNumberField._userAction = userAction;
        monthDaysNumberField.disabled = !checked || !this.isWidgetAvailableForFrequency(monthDaysNumberField);
        monthDaysNumberField._userAction = false;
    }

    onPositionAndDayRadioFieldChange({ source, checked, userAction }) {
        const { daysCombo, positionsCombo } = this.widgetMap;

        // toggle day & positions combos
        daysCombo._userAction = positionsCombo._userAction = userAction;
        daysCombo.disabled = positionsCombo.disabled = !checked || !this.isWidgetAvailableForFrequency(daysCombo);
        daysCombo._userAction = positionsCombo._userAction = false;
    }

    onStopRecurrenceFieldChange({ userAction }) {
        this.toggleStopFields(userAction);
    }

    onFrequencyFieldChange({ value, oldValue, valid, userAction }) {
        const
            me    = this,
            items = me.queryAll(w => 'forFrequency' in w),
            {
                intervalField,
                stopRecurrenceField
            }      = me.widgetMap,
            isNone = value === 'NONE';

        if (valid && value) {
            for (const item of items) {
                item._userAction = userAction;
                item.disabled = item.hidden = !me.isWidgetAvailableForFrequency(item, value);
                item._userAction = false;
            }

            // Special handling of NONE
            if (stopRecurrenceField) {
                stopRecurrenceField.hidden = isNone;
            }

            if (intervalField) {
                intervalField.hidden = isNone;

                if (!isNone) {
                    intervalField.hint = me.L(`L{RecurrenceEditor.${value}intervalUnit}`);
                }

                // When a non-recurring record is loaded, intervalField is set to empty. We want it to default to 1 here
                // to not look weird (defaults to 1 on the data layer)
                if (oldValue === 'NONE' && intervalField.value == null) {
                    intervalField.value = 1;
                }
            }

            me.toggleFieldsState();
        }
    }

    //#endregion Events

    toggleFieldsState(userAction) {
        const
            me                      = this,
            { widgetMap }           = me,
            { monthDaysRadioField } = widgetMap;

        if (monthDaysRadioField) {
            me.onMonthDaysRadioFieldChange({ checked : monthDaysRadioField.checked, userAction });
        }
        me.onPositionAndDayRadioFieldChange({ checked : widgetMap.positionAndDayRadioField.checked, userAction });
        me.onStopRecurrenceFieldChange({ userAction });
    }

    updateLocalization() {
        // do extra labels translation (not auto-translated yet)
        const { countField, intervalField, frequencyField } = this.widgetMap;

        countField.hint = this.L('L{RecurrenceEditor.time(s)}');

        if (frequencyField.value && frequencyField.value !== 'NONE') {
            intervalField.hint = this.L(`L{RecurrenceEditor.${frequencyField.value}intervalUnit}`);
        }

        super.updateLocalization();
    }

}

// Register this widget type with its Factory
RecurrenceEditorPanel.initClass();
