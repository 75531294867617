import InstancePlugin from '../../Core/mixin/InstancePlugin.js';
import GridFeatureManager from '../../Grid/feature/GridFeatureManager.js';
import Tooltip from '../../Core/widget/Tooltip.js';

/**
 * @module SchedulerPro/feature/EventBuffer
 */

/**
 * Feature that allows showing additional time before & after an event, to visualize things like travel time - or the time you
 * need to prepare a room for a meeting + clean it up after.
 *
 * The feature relies on two model fields: {@link SchedulerPro.model.EventModel#field-preamble} and
 * {@link SchedulerPro.model.EventModel#field-postamble} which are used to calculate overall start and end dates used to
 * position the event. Buffer time overlaps the same way events overlap (as you can see in the inline demo below). It
 * should also be noted that buffer time is ignored for milestones.
 *
 * {@inlineexample SchedulerPro/feature/EventBuffer.js}
 *
 * This feature is **disabled** by default
 *
 * @extends Core/mixin/InstancePlugin
 * @classtype eventBuffer
 * @feature
 * @demo SchedulerPro/travel-time
 */
export default class EventBuffer extends InstancePlugin {
    static $name = 'EventBuffer';

    static configurable = {
        /**
         * Show buffer duration labels
         * @config {Boolean}
         * @default
         */
        showDuration : true,

        /**
         * A function which lets you customize the visual appearance of the preamble and postamble elements.
         *
         * @config {Function}
         * @param {Object} data
         * @param {SchedulerPro.model.EventModel} data.eventRecord The event record
         * @param {Object} data.preambleConfig A config object for the preamble
         * @param {String} data.preambleConfig.icon A CSS class for the icon to show (e.g. car / bicycle)
         * @param {Object|String} data.preambleConfig.cls A CSS object for the preamble element
         * @param {String} data.preambleConfig.text The text to display
         * @param {Object} data.postambleConfig A config object for the postamble
         * @param {String} data.postambleConfig.icon A CSS class for the icon to show (e.g. car / bicycle)
         * @param {Object|String} data.postambleConfig.cls A CSS object for the postamble element
         * @param {String} data.postambleConfig.text The text to display
             * @returns {void}
             */
        renderer : null,

        /**
             * A function which receives data about the buffer time and returns a html string to show in a tooltip when
             * hovering a buffer time element
             *
             * @config {Function}
             * @param {Object} data Data
             * @param {Core.data.Duration} data.duration Buffer time duration
             * @param {Boolean} data.before `true` if this is a buffer time before the event start, `false` if after
             * @param {SchedulerPro.model.EventModel} data.eventRecord The event record
             * @returns {String} String representing the HTML markup
             */
        tooltipTemplate : {
            value   : null,
            $config : 'nullify'
        }
    };

    static get pluginConfig() {
        return {
            chain : ['onEventDataGenerated']
        };
    }

    //region Chained methods

    updateTooltipTemplate(tooltipTemplate) {
        const me = this;

        if (tooltipTemplate) {
            me.tooltip = Tooltip.new({
                forElement  : me.client.timeAxisSubGridElement,
                forSelector : '.b-sch-event-buffer-before,.b-sch-event-buffer-after',
                align       : {
                    align  : 'b-t',
                    offset : [0, 10]
                },
                getHtml({ activeTarget }) {
                    const
                        eventRecord = me.client.resolveEventRecord(activeTarget),
                        before      = activeTarget.matches('.b-sch-event-buffer-before'),
                        duration    = before ? eventRecord.preamble : eventRecord.postamble;

                    return me.tooltipTemplate({ eventRecord, duration, before });
                }
            });
        }
        else {
            me.tooltip?.destroy();
        }
    }

    onEventDataGenerated({ useEventBuffer, bufferBeforeWidth, bufferAfterWidth, eventRecord, wrapperChildren }) {

        if (this.enabled && useEventBuffer) {
            const
                { isHorizontal }        = this.client,
                { showDuration }        = this,
                sizeProp                = isHorizontal ? 'width' : 'height',
                preambleConfig          = {},
                postambleConfig         = {};

            let { preamble, postamble } = eventRecord;

            this.renderer?.({ eventRecord, preambleConfig, postambleConfig });

            if (!preamble?.magnitude) {
                preamble = null;
            }

            if (!postamble?.magnitude) {
                postamble = null;
            }

            wrapperChildren.push(
                {
                    className : {
                        'b-sch-event-buffer'        : 1,
                        'b-sch-event-buffer-before' : 1,
                        'b-buffer-thin'             : !bufferBeforeWidth,
                        [preambleConfig.cls]        : preambleConfig.cls
                    },
                    style : {
                        [`${sizeProp}`] : `${bufferBeforeWidth}px`
                    },
                    children : (showDuration && preamble) ? [
                        preambleConfig.icon ? {
                            tag       : 'i',
                            className : preambleConfig.icon
                        } : undefined,
                        {
                            tag       : 'span',
                            className : 'b-buffer-label',
                            text      : preambleConfig.text ?? preamble.toString(true)
                        }
                    ] : undefined
                },
                {
                    className : {
                        'b-sch-event-buffer'       : 1,
                        'b-sch-event-buffer-after' : 1,
                        'b-buffer-thin'            : !bufferAfterWidth,
                        [postambleConfig.cls]      : postambleConfig.cls
                    },
                    style : {
                        [`${sizeProp}`] : `${bufferAfterWidth}px`
                    },
                    children : (showDuration && postamble) ? [
                        {
                            tag       : 'span',
                            className : 'b-buffer-label',
                            text      : postambleConfig.text ?? postamble.toString(true)
                        },
                        postambleConfig.icon ? {
                            tag       : 'i',
                            className : postambleConfig.icon
                        } : undefined
                    ] : undefined
                }
            );
        }
    }

    //endregion

    updateShowDuration() {
        if (!this.isConfiguring) {
            this.client.refreshWithTransition();
        }
    }

    doDisable(disable) {
        super.doDisable(disable);

        const { client } = this;

        if (!client.isConfiguring && client.isPainted) {
            // Add a special CSS class to disable certain transitions
            client.element.classList.add('b-eventbuffer-transition');

            client.refreshWithTransition();

            client.waitForAnimations().then(() => {
                client.element.classList.remove('b-eventbuffer-transition');
            });
        }
    }
}

GridFeatureManager.registerFeature(EventBuffer, false, 'SchedulerPro');
