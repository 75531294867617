import ArrayHelper from '../../../Core/helper/ArrayHelper.js';
import DateHelper from '../../../Core/helper/DateHelper.js';
import Button from '../../../Core/widget/Button.js';
import ColorField from '../../../Core/widget/ColorField.js';
import Container from '../../../Core/widget/Container.js';
import DateField from '../../../Core/widget/DateField.js';
import SlideToggle from '../../../Core/widget/SlideToggle.js';
import Widget from '../../../Core/widget/Widget.js';
import CalendarEditorAvailabilityRangeContainer from './CalendarEditorAvailabilityRangeContainer.js';
import CalendarEditorStore from '../../data/calendareditor/CalendarEditorStore.js';

/**
 * @module SchedulerPro/widget/calendareditor/CalendarEditorWeekPanel
 */

/**
 * A panel implementing week interval editor.
 * The panel is used by the {@link SchedulerPro/widget/CalendarEditor calendar editor} "Weeks" tab.
 *
 * @extends Core/widget/Container
 * @classtype calendareditorweekpanel
 * @internal
 * @widget
 */
export default class CalendarEditorWeekPanel extends Container {

    static $name = 'CalendarEditorWeekPanel';

    static type = 'calendareditorweekpanel';

    static configurable = {
        store                         : null,
        defaultWorkingDayAvailability : [
            {
                startDate : '08:00',
                endDate   : '12:00'
            },
            {
                startDate : '13:00',
                endDate   : '17:00'
            }
        ],
        copyingContext : null,
        items          : {
            weekContainer : {
                type     : 'container',
                defaults : {
                    localeClass : this
                },
                items : {
                    weekForm : {
                        weight              : 10,
                        type                : 'container',
                        cls                 : 'b-calendareditor-form',
                        autoUpdateRecord    : true,
                        strictRecordMapping : true,
                        defaults            : {
                            localeClass : this
                        },
                        items : {
                            nameField : {
                                type     : 'textfield',
                                name     : 'name',
                                label    : 'L{name}',
                                cls      : 'b-span',
                                required : true
                            },
                            colorField : {
                                type           : ColorField.type,
                                name           : 'color',
                                addNoColorItem : false,
                                colors         : CalendarEditorStore.intervalColors
                            },
                            startDateField : {
                                type     : DateField.type,
                                name     : 'startDate',
                                label    : 'L{from}',
                                required : true,
                                onChange({ value }) {
                                    this.nextSibling.min = value;
                                }
                            },
                            endDateField : {
                                type     : DateField.type,
                                name     : 'endDate',
                                label    : 'L{to}',
                                required : true,
                                onChange({ value }) {
                                    this.previousSibling.max = value;
                                }
                            }
                        }
                    },
                    daysDivider : {
                        weight                : 20,
                        html                  : '',
                        localizableProperties : ['dataset.text'],
                        dataset               : {
                            text : 'L{days}'
                        },
                        cls  : 'b-divider',
                        flex : '1 0 100%'
                    },
                    // day containers skeleton
                    ...ArrayHelper.fill(7).reduce((acc, _i, index) => {
                        const config = this.getDayContainerConfig(index);
                        // provide weights to sort days (starting from 30)
                        config.weight = 30 + index * 10;
                        // keyed by ref property
                        acc[config.ref] = config;
                        return acc;
                    }, {})
                }
            },
            noRecordAddButton : {
                type              : Button.type,
                icon              : 'b-icon-add',
                text              : 'L{CalendarEditorWeekTab.addWeek}',
                hidden            : true,
                internalListeners : {
                    click : 'up.onAddDefaultWeekClick'
                }
            }
        }
    };

    construct() {
        this._weekStartDay = 0;
        this._removedAvailability = new Map();

        return super.construct(...arguments);
    }

    //#region Configs

    static getDayContainerConfig(dayId) {
        return {
            type          : 'container',
            flex          : '1 0 100%',
            cls           : 'b-availability-day',
            ref           : `day${dayId}Container`,
            // reduce noise - this container and nested ones don't read-from/write-to upper level
            isolateFields : true,
            items         : {
                container : {
                    type      : 'container',
                    width     : 150,
                    minHeight : 45,
                    items     : {
                        [`day${dayId}Name`] : {
                            html                : ``,
                            defaultBindProperty : ''
                        },

                        [`day${dayId}Slide`] : {
                            type              : SlideToggle.type,
                            flex              : '0 0 40px',
                            dayId,
                            internalListeners : {
                                change : 'up.onDayToggle'
                            }
                        }
                    }
                },

                [`day${dayId}Ranges`] : {
                    type    : CalendarEditorAvailabilityRangeContainer.type,
                    dataset : {
                        dayId
                    },
                    cls               : 'b-availability-day-ranges',
                    maxWidth          : 300,
                    flex              : null,
                    internalListeners : {
                        renderAvailabilityFields : 'up.onRenderAvailabilityFields'
                    }
                },

                [`day${dayId}CopyButton`] : {
                    type : Button.type,
                    cls  : {
                        'b-availability-day-actions' : 1,
                        'b-day-copy'                 : 1,
                        'b-transparent'              : 1
                    },
                    dayId,
                    icon               : 'b-icon-copy',
                    onClick            : 'up.onCopyClick',
                    tooltip            : `L{CalendarEditorWeekPanel.copyDay}`,
                    toggleable         : true,
                    // We need the button to be able to unpress (even though it's part of a toggleGroup)
                    // so user could stop copying
                    supportsUnpressing : true,
                    toggleGroup        : 'day-copy'
                },

                [`day${dayId}PasteButton`] : {
                    type : Button.type,
                    cls  : {
                        'b-availability-day-actions' : 1,
                        'b-day-paste'                : 1,
                        'b-transparent'              : 1
                    },
                    dayId,
                    icon    : 'b-icon-paste b-fade',
                    onClick : 'up.onPasteClick',
                    tooltip : `L{CalendarEditorWeekPanel.pasteDay}`
                }
            }
        };
    }

    updateStore(store) {
        this.detachListeners('store');

        store?.ion({
            name             : 'store',
            pullFromCalendar : this.onPullFromCalendar,
            thisObj          : this
        });
    }

    updateRecord(record) {
        super.updateRecord(...arguments);

        const { widgetMap } = this;

        this._removedAvailability.clear();

        widgetMap.weekForm.record = record;

        // hide start/end fields for the default week record
        widgetMap.startDateField.disabled  = widgetMap.startDateField.hidden = record?.isDefaultWeek;
        this.element.classList.toggle('b-default-week', Boolean(record?.isDefaultWeek));
        widgetMap.endDateField.disabled    = widgetMap.endDateField.hidden = record?.isDefaultWeek;
        widgetMap.weekContainer.hidden     = !record;
        widgetMap.noRecordAddButton.hidden = Boolean(record);

        this.renderIntervalAvailability(record?.availability);

        this.copyingContext = null;
    }

    updateCopyingContext(value, oldValue) {
        const
            { widgetMap } = this,
            dayContainerRef = `day${value?.id}Container`;

        if (value) {
            widgetMap[`day${value.id}CopyButton`].tooltip = 'L{CalendarEditorWeekPanel.stopCopying}';
        }

        if (oldValue) {
            const copyButton = widgetMap[`day${oldValue.id}CopyButton`];
            copyButton.tooltip = 'L{CalendarEditorWeekPanel.copyDay}';
            copyButton.pressed = false;
        }

        widgetMap.weekContainer.element.classList.toggle('b-copying', Boolean(value));

        this.element.querySelectorAll('.b-availability-day')
            .forEach(el => el.classList.toggle(
                'b-copied', Boolean(value && el.dataset.ref === dayContainerRef)
            ));
    }

    updateDefaultWorkingDayAvailability(value) {
        this.getConfig('items');

        const { widgetMap } = this;

        for (let i = 0; i < 7; i++) {
            widgetMap[`day${i}Ranges`].defaultAvailability = value;
        }
    }

    //#endregion Configs

    //#region Listeners

    onAddDefaultWeekClick() {
        this.trigger('addAddDefaultWeekClick');
    }

    onLocalized() {
        const
            { weekStartDay } = DateHelper,
            { widgetMap } = this;

        // If week start date has changed we need to reorder day containers
        if (this._weekStartDay !== weekStartDay) {

            this._weekStartDay = weekStartDay;

            // config.weight = 30 + index * 10;

            // days less than week start day shift to the end
            let indexShift = 7 - weekStartDay;

            for (let i = 0; i < weekStartDay; i++) {
                widgetMap[`day${i}Container`].weight = 30 + (i + indexShift) * 10;
            }

            // week start day and days after shift to the beginning
            indexShift = -weekStartDay;

            for (let i = weekStartDay; i < 7; i++) {
                widgetMap[`day${i}Container`].weight = 30 + (i + indexShift) * 10;
            }

            // <remove-on-release>
            // TODO Ideally containers should handle reordering when changing child weights
            // made a ticket for this: https://github.com/bryntum/bryntum-suite/issues/8419
            // </remove-on-release>
            widgetMap.weekContainer._items.sort(Widget.weightSortFn);
            widgetMap.weekContainer.layout.renderChildren();
        }

        for (let i = 0; i < 7; i++) {
            widgetMap[`day${i}Name`].html = DateHelper.getDayName(i);
        }
    }

    onCopyClick({ source }) {
        const
            { copyingContext } = this,
            { dayId }          = source;

        if (copyingContext && dayId === copyingContext.id) {
            this.copyingContext = null;
        }
        else {
            this.copyingContext = this.record.availability.getById(dayId);
        }

    }

    onPasteClick({ source }) {
        const
            { data }        = this.copyingContext,
            targetDay       = this.record.availability.getById(source.dayId),
            copiedIntervals = data.availability.map(r => r.copy());

        targetDay.availability.data = copiedIntervals;
    }

    onDayToggle({ source, value, userAction }) {
        const me = this;

        if (me.record) {
            const
                { dayId } = source,
                { availability } = me.record.availability.getById(dayId);

            if (userAction) {
                // remove the day availability
                if (!value) {
                    // remember availability in case user wants to toggle it back
                    me._removedAvailability.set(dayId, availability.toJSON());
                    availability.removeAll();
                }
                // add availability to the day
                else {
                    me.widgetMap[`day${dayId}Ranges`].addRanges(
                        // use previous value if any
                        me._removedAvailability.get(dayId)
                    );
                }
            }
        }
    }

    onPullFromCalendar({ source }) {
        const { record } = this;

        // if store no longer has the edited record - reset controls
        if (record && !source.includes(record)) {
            this.record = null;
        }
    }

    //#endregion Listeners

    renderIntervalAvailability(availability) {
        if (!availability?.count) {
            availability = ArrayHelper.populate(7, index => ({ id : index }));
        }

        const { widgetMap } = this;

        for (const dayAvailability of availability) {
            widgetMap[`day${dayAvailability.id}Ranges`].store = dayAvailability.availability;
        }
    }

    onRenderAvailabilityFields({ source }) {
        const
            { widgetMap }   = this,
            dayId           = source.dataset.dayId,
            hasAvailability = source.store?.count;

        // update toggle state
        widgetMap[`day${dayId}Slide`].checked = hasAvailability;
        // switch the row disabled state
        widgetMap[`day${dayId}Container`].element.classList.toggle('b-disabled', !hasAvailability);
    }
}

CalendarEditorWeekPanel.initClass();
