import PickerField from './PickerField.js';
import Objects from '../helper/util/Objects.js';
import EventHelper from '../helper/EventHelper.js';

/**
 * @module Core/widget/TextAreaPickerField
 */

/**
 * TextAreaPickerField is a picker field with a drop down showing a `textarea` element for multiline text input. See
 * also {@link Core.widget.TextAreaField}.
 *
 * ```javascript
 * const textAreaField = new TextAreaPickerField({
 *   placeholder: 'Enter some text'
 * });
 *```
 *
 * This field can be used as an {@link Grid.column.Column#config-editor} for {@link Grid.column.Column Columns}.
 *
 * {@inlineexample Core/widget/TextAreaPickerField.js}
 *
 * @extends Core/widget/PickerField
 * @classtype textareapickerfield
 * @inputfield
 */
export default class TextAreaPickerField extends PickerField {

    static $name = 'TextAreaPickerField';

    static type = 'textareapickerfield';

    static configurable = {
        picker : {
            type         : 'widget',
            tag          : 'textarea',
            cls          : 'b-textareapickerfield-picker',
            scrollAction : 'realign',
            align        : {
                align    : 't-b',
                axisLock : true
            },
            autoShow : false
        },

        triggers : {
            expand : {
                cls     : 'b-icon-picker',
                handler : 'onTriggerClick'
            }
        },

        /**
         * The resize style to apply to the `<textarea>` element.
         * @config {'none'|'both'|'horizontal'|'vertical'}
         * @default
         */
        resize : 'none',

        inputType : null
    };

    get inputElement() {
        const result = super.inputElement;

        result.readOnly = 'readonly';
        result.reference = 'displayElement';
        this.ariaElement = 'displayElement';

        return result;
    }

    get focusElement() {
        return this._picker?.isVisible ? this.input : this.displayElement;
    }

    get needsInputSync() {
        return this.displayElement[this.inputValueAttr] !== String(this.inputValue ?? '');
    }

    showPicker() {
        this.picker.width = this.pickerWidth || this.pickerAlignElement.offsetWidth;

        // Always focus the picker.
        super.showPicker(true);
    }

    focusPicker() {
        this.input.focus();
    }

    onPickerKeyDown(keyEvent) {
        const
            me        = this,
            realInput = me.input;

        switch (keyEvent.key.trim() || keyEvent.code) {
            case 'Escape':
                // <remove-on-release>
                // TODO: revert value?
                // </remove-on-release>
                me.picker.hide();
                return;
            case 'Enter':
                if (keyEvent.ctrlKey) {
                    me.syncInputFieldValue();
                    me.picker.hide();
                }
                break;
        }

        // Super's onPickerKeyDown fires through this.input, so avoid infinite recursion
        // by redirecting it through the displayElement.
        me.input     = me.displayElement;
        const result = super.onPickerKeyDown(keyEvent);
        me.input     = realInput;

        return result;
    }

    syncInputFieldValue(skipHighlight) {
        if (this.displayElement) {
            this.displayElement.value = this.inputValue;
        }

        super.syncInputFieldValue(skipHighlight);
    }

    changeValue(value) {
        return value == null ? '' : value;
    }

    changePicker(picker, oldPicker) {
        const
            me          = this,
            pickerWidth = me.pickerWidth || picker?.width;

        picker = TextAreaPickerField.reconfigure(oldPicker, picker ? Objects.merge({
            owner      : me,
            forElement : me.pickerAlignElement,
            align      : {
                matchSize : pickerWidth == null,
                anchor    : me.overlayAnchor,
                target    : me.pickerAlignElement
            },
            id    : me.id + '-input',
            style : {
                resize : me.resize
            },
            html : me.value ?? ''
        }, picker) : null, me);

        // May have been set to null (destroyed)
        if (picker) {
            const input = me.input = picker.element;

            me.inputListenerRemover = EventHelper.on({
                element  : input,
                thisObj  : me,
                focus    : 'internalOnInputFocus',
                change   : 'internalOnChange',
                input    : 'internalOnInput',
                keydown  : 'internalOnKeyEvent',
                keypress : 'internalOnKeyEvent',
                keyup    : 'internalOnKeyEvent'
            });
        }

        return picker;
    }
}

// Register this widget type with its Factory
TextAreaPickerField.initClass();
