import Store from '../../../Core/data/Store.js';
import DateHelper from '../../../Core/helper/DateHelper.js';
import Localizable from '../../../Core/localization/Localizable.js';
import DayAvailabilityModel from '../../model/calendareditor/DayAvailabilityModel.js';

/**
 * @module SchedulerPro/data/calendareditor/DayAvailabilityStore
 */

/**
 * A store of week availability ranges provided on daily basis.
 * Each store record represents a single day availability.
 *
 * The class is used by {@link SchedulerPro/model/calendareditor/CalendarEditorWeekModel} class
 * which is a part of the {@link SchedulerPro/widget/CalendarEditor calendar editor} data level.
 *
 * The store uses {@link SchedulerPro/model/calendareditor/DayAvailabilityModel} model.
 *
 * @mixes Core/localization/Localizable
 * @extends Core/data/Store
 * @internal
 */
export default class DayAvailabilityStore extends Localizable(Store) {

    static $name = 'CalendarEditorStore';

    static configurable = {
        modelClass : DayAvailabilityModel,

        sorters : [{
            id : 'weekStartDaySorter', // no-sanity
            fn : (recordA, recordB) => {
                const
                    valueA = recordA.id + (recordA.id < DateHelper.weekStartDay ? 7 : 0),
                    valueB = recordB.id + (recordB.id < DateHelper.weekStartDay ? 7 : 0);

                return valueA - valueB;
            }
        }]
    };

    // The store reacts on locale change and reorders its records
    // since week start day might change
    onLocalized() {
        this.sort();
    }

}
