import DomSync from '../../../../Core/helper/DomSync.js';
import TemplateHelper from '../../../../Core/helper/TemplateHelper.js';
import BrowserHelper from '../../../../Core/helper/BrowserHelper.js';
import Base from '../../../../Core/Base.js';
import Localizable from '../../../../Core/localization/Localizable.js';
import IdHelper from '../../../../Core/helper/IdHelper.js';
import DomHelper from '../../../../Core/helper/DomHelper.js';
import { getPrintPaperSizeAdjustments, RowsRange } from '../Utils.js';
import Events from '../../../../Core/mixin/Events.js';
import Delayable from '../../../../Core/mixin/Delayable.js';

/**
 * @module Grid/feature/export/exporter/Exporter
 */

/**
 * Base class for all exporters
 * @mixes Core/localization/Localizable
 * @mixes Core/mixin/Events
 */
export default class Exporter extends Delayable(Events(Localizable(Base))) {
    static get defaultConfig() {
        return {
            /**
             * `True` to replace all linked CSS files URLs to absolute before passing HTML to the server.
             * When passing a string the current origin of the CSS files URLS will be replaced by the passed origin.
             *
             * For example: css files pointing to /app.css will be translated from current origin to {translateURLsToAbsolute}/app.css
             * @config {Boolean|String}
             * @default
             */
            translateURLsToAbsolute : true,

            /**
             * When true links are converted to absolute by combining current window location (with replaced origin) with
             * resource link.
             * When false links are converted by combining new origin with resource link (for angular)
             * @config {Boolean}
             * @default
             */
            keepPathName : true,

            /**
             * This method accepts all stylesheets (link and style tags) which are supposed to be put on the page. Use this
             * hook method to filter or modify them.
             *
             * ```javascript
             * new Grid({
             *     features: {
             *         pdfExport: {
             *             // filter out inline styles and bootstrap.css
             *             filterStyles: styles => styles.filter(item => !/(link|bootstrap.css)/.test(item))
             *         }
             *     }
             * });
             * ```
             * @param {String[]} styles
             * @returns {String[]} List of stylesheets to put on the exported page
             */
            filterStyles : styles => styles
        };
    }

    static inchToPx(value) {
        // 1in = 96px for screens
        // https://developer.mozilla.org/en-US/docs/Web/CSS/length#Absolute_length_units
        return value * 96;
    }

    /**
     * Template of an extracted page.
     * @param {Object} data Data for the page template
     * @returns {String}
     */
    pageTpl(data) {
        const
            {
                title,
                header,
                footer,
                styles,
                htmlClasses,
                bodyClasses = [],
                paperHeight,
                paperWidth,
                html,
                currentPage,
                isPrint
            } = data;

        let { htmlStyle = '', bodyStyle = '' } = data;

        bodyClasses.push(`b-${this.constructor.type}`);

        // Other classes needed on outer element.
        // <remove-on-release>
        // TODO: use the new static Widget.outerCls property when merged with master
        // </remove-on-release>
        if (DomHelper.scrollBarWidth) {
            bodyClasses.push('b-visible-scrollbar');
        }
        else {
            bodyClasses.push('b-overlay-scrollbar');
        }
        if (BrowserHelper.isChrome) {
            bodyClasses.push('b-chrome');
        }
        else if (BrowserHelper.isSafari) {
            bodyClasses.push('b-safari');
        }
        else if (BrowserHelper.isFirefox) {
            bodyClasses.push('b-firefox');
        }

        htmlStyle = isPrint ? htmlStyle : `${htmlStyle}; width: ${paperWidth}in; height: ${paperHeight}in;`;
        bodyStyle = isPrint ? `${bodyStyle}; width: ${paperWidth}in;` : `${bodyStyle}; width: ${paperWidth}in; height: ${paperHeight}in;`;

        return TemplateHelper.tpl`
            <!DOCTYPE html>
            <html class="${htmlClasses} b-print-root" style="${htmlStyle}">
                <head>
                    <meta content="text/html; charset=UTF-8" http-equiv="Content-Type" />
                    <title>${title}</title>
                    ${styles.join('')}
                </head>
                <body class="b-export ${bodyClasses.join(' ')}" style="${bodyStyle}">
                    <div class="b-export-content b-page-${currentPage}">
                        ${header && `<div class="b-export-header" style="width: 100%">${header}</div>`}
                        <div class="b-export-body"><div class="b-export-viewport">${html}</div></div>
                        ${footer && `<div class="b-export-footer" style="width: 100%">${footer}</div>`}
                    </div>
                </body>
            </html>`;
    }

    /**
     * Returns all style-related tags: `<style>` and `<link rel="stylesheet">`
     * @property {String[]}
     * @readonly
     */
    get stylesheets() {
        const me = this;

        if (me._stylesheets) {
            return me._stylesheets;
        }

        const
            translate       = me.translateURLsToAbsolute,
            origin          = globalThis.origin,
            styleSheetNodes = Array.from(document.querySelectorAll('link[rel="stylesheet"], style')),
            styles          = [];

        styleSheetNodes.forEach(node => {
            node = node.cloneNode(true);

            // put absolute URL to node `href` attribute
            if (translate && node.href) {
                let result;

                if (translate === true) {
                    result = node.href;
                }
                // translate is truthy if we are here, which means it is string now
                else if (this.keepPathName) {
                    result = node.href.replace(origin, translate);
                }
                else {
                    result = new URL(node.getAttribute('href'), translate);
                }

                node.setAttribute('href', result);
            }

            let styleText = node.outerHTML;

            if (translate && /style/i.test(node.tagName)) {
                const converter = me.getStyleTagURLConverter(translate);
                styleText = styleText.replace(/url\(['"]?(.+?)['"]?\)/g, converter);
            }

            styles.push(styleText);
        });

        return me._stylesheets = me.filterStyles(styles);
    }

    set stylesheets(value) {
        this._stylesheets = value ? this.filterStyles(value) : value;
    }

    getStyleTagURLConverter(translate) {
        return function(match, url) {
            let result;

            try {
                let base;

                // Filter out local references, e.g. to svg marker
                if (/^#/.test(url)) {
                    result = match;
                }
                else {
                    if (translate === true) {
                        base = globalThis.location.href;
                    }
                    // translate is truthy if we are here, which means it is string now
                    else if (this.keepPathName) {
                        base = globalThis.location.href.replace(globalThis.location.origin, translate);
                    }
                    else {
                        base = translate;
                    }

                    result = `url('${new URL(url, base).href}')`;
                }
            }
            catch (e) {
                result = match;
            }

            return result;
        }.bind(this);
    }

    saveState({ client }) {
        const { disableGridColumnIdWarning } = client;
        client.disableGridColumnIdWarning    = true;

        this.state = client.state;

        client.disableGridColumnIdWarning = disableGridColumnIdWarning;
    }

    async restoreState({ client }) {
        const
            promises  = [],
            detachers = new Set();

        detachers.add(
            client.scrollable.ion({
                scroll() {
                    promises.push(client.scrollable.await('scrollEnd'));
                }
            })
        );

        client.eachSubGrid(({ header, scrollable }) => {
            detachers.add(
                scrollable.ion({
                    scroll() {
                        promises.push(scrollable.await('scrollEnd'));
                    }
                })
            );

            detachers.add(
                header.scrollable.ion({
                    scroll() {
                        promises.push(header.scrollable.await('scrollEnd'));
                    }
                })
            );
        });

        client.state = this.state;

        await Promise.all(promises);

        detachers.forEach(fn => fn());
    }

    beforeExport() {
        // Into this element we will put HTML for export
        this.element = document.createElement('div');
    }

    //region DOM helpers

    cloneElement(element, target = this.element, clear = true) {
        if (clear) {
            target.innerHTML = '';
        }

        target.appendChild(element.cloneNode(true));

        DomHelper.removeEachSelector(target, '.b-grid-row,.b-grid-merged-cells-container');

        const maskEl = target.querySelector('.b-gridbase > .b-mask');

        if (maskEl) {
            maskEl.remove();
        }
    }

    createPlaceholder(el, clear = true, config = {}) {
        if (clear) {
            el.innerHTML = '';
        }

        return DomHelper.createElement(Object.assign({
            parent : el,
            id     : IdHelper.generateId('export')
        }, config));
    }

    prepareElement({ client }) {
        const { tbar, bbar } = client;

        if (tbar) {
            this.element.querySelector(`#${tbar.id}`).remove();
        }

        if (bbar) {
            this.element.querySelector(`#${bbar.id}`).remove();
        }
    }

    /**
     * Appends generated header/footer element to the document body to measure their height
     * @param {String} html
     * @param {String} wrapperCls
     * @returns {Number}
     * @internal
     */
    measureElement(html = '', wrapperCls = '') {
        if (html instanceof HTMLElement) {
            html = html.outerHTML;
        }

        const target = DomHelper.createElement({
            parent : document.body,
            style  : {
                visibility : 'hidden',
                position   : 'absolute'
            },
            className : wrapperCls,
            // Add html to measure to a div between two other divs to take margin into account
            html      : `<div style="height: 1px"></div>${html}<div style="height: 1px"></div>`
        });

        const result = target.offsetHeight - 2;

        target.remove();

        return result;
    }

    // Converts local urls to absolute
    prepareHTML(html) {
        if (html instanceof HTMLElement) {
            html = html.outerHTML;
        }

        const target = DomHelper.createElement({
            parent : document.body,
            style  : {
                visibility : 'hidden',
                position   : 'absolute'
            },
            html
        });

        const elements = target.querySelectorAll('img');

        for (let i = 0, l = elements.length; i < l; i++) {
            elements[i].setAttribute('src', elements[i].src);
        }

        const result = target.innerHTML;

        target.remove();

        return result;
    }

    getVirtualScrollerHeight(client) {
        let result = 0;

        // If overlay scroll is enabled, this will return 0
        // when disabled, it will report proper virtualScrollers element height
        client.eachSubGrid(subGrid => {
            if (subGrid.overflowingHorizontally) {
                result = DomHelper.scrollBarWidth;
            }
        });

        // If there's a visible scrollbar, need to also take border height into account
        return result === 0 ? result : (result + 1);
    }

    //endregion

    // Use carefully picked values for portrait/landscape mode and fallback to legacy width/height in case use
    // has them overridden
    getPaperWidth(paperFormat, isPortrait) {
        if ('portraitWidth' in paperFormat) {
            const prop = isPortrait ? 'portraitWidth' : 'landscapeWidth';

            return paperFormat[prop] - (this.exportMeta.isPrinting ? getPrintPaperSizeAdjustments()[prop] : 0);
        }
        else {
            return isPortrait ? paperFormat.width : paperFormat.height;
        }

    }

    getPaperHeight(paperFormat, isPortrait) {
        if ('portraitHeight' in paperFormat) {
            const prop = isPortrait ? 'portraitHeight' : 'landscapeHeight';

            return paperFormat[prop] - (this.exportMeta.isPrinting ? getPrintPaperSizeAdjustments()[prop] : 0);
        }
        else {
            return isPortrait ? paperFormat.height : paperFormat.width;
        }
    }

    inchToPx(value) {
        // 1in = 96px for screens
        // https://developer.mozilla.org/en-US/docs/Web/CSS/length#Absolute_length_units
        return value * 96;
    }

    getScaleValue(base, value) {
        return Math.floor((base * 10000 / value)) / 10000;
    }

    getVisibleRowsCount(client) {
        const
            rowManager        = client.rowManager,
            // We cannot rely on `visibleRowCount` property, it is a mere estimate
            firstVisibleIndex = rowManager.rows.indexOf(rowManager.firstVisibleRow),
            lastVisibleIndex  = rowManager.rows.indexOf(rowManager.lastVisibleRow);

        let result;

        // first index could be negative only if there are no rendered rows, in which case we return store count
        if (firstVisibleIndex !== -1) {
            // last visible index could be negative if last actually visible row doesn't reach view bottom, i.e.
            // view is not completely filled with rows
            if (lastVisibleIndex === -1) {
                result = client.store.count - firstVisibleIndex;
            }
            else {
                // for a zero-based index we need to add 1 to the result
                result = lastVisibleIndex - firstVisibleIndex + 1;
            }
        }
        else {
            result = client.store.count;
        }

        return result;
    }

    async export(config) {
        const me = this;

        let pages;

        me.beforeExport();

        me.saveState(config);

        await me.prepareComponent(config);

        try {
            pages = await me.getPages(config);
        }
        finally {
            await me.restoreComponent(config);

            me.stylesheets = null;

            // Restoring scroll might trigger rows repaint on next animation frame. We are
            // waiting for next animation frame in such case. Covered in SinglePage.t.js
            await new Promise(resolve => me.requestAnimationFrame(resolve));

            await me.restoreState(config);
        }

        return pages;
    }

    async getPages(config) {
        const
            generator = this.pagesExtractor(config),
            pages     = [];

        let step;

        while ((step = await generator.next()) && !step.done) {
            pages.push(step.value);
        }

        return pages;
    }

    async prepareComponent(config) {
        const
            me             = this,
            {
                client,
                columns,
                rowsRange,
                keepRegionSizes,
                useRenderedColumnWidth
            }              = config,
            { rowManager } = client,
            exportMeta     = me.exportMeta = {
                totalWidth  : 0,
                totalHeight : 0,
                subGrids    : {},
                isPrinting  : config.useBrowserPrint,
                title       : config.title || client.description
            };

        client.columns.forEach(column => {
            if (columns.includes(column.id)) {
                column.show();
            }
            else {
                column.hide();
            }
        });

        // Wait for animation frame to catch optional resize event which may occur after hiding columns. Scroll
        // stretcher should be synced before we proceed.
        // Covered by export/Columns.t
        await new Promise(resolve => client.requestAnimationFrame(resolve));

        if (rowManager.rowCount > 0) {
            if (rowsRange === RowsRange.all) {
                exportMeta.firstVisibleDataIndex = rowManager.rows[0].dataIndex;
            }
            else {
                exportMeta.firstVisibleDataIndex = rowManager.firstVisibleRow.dataIndex;

                config.alignRows = true;
            }
        }

        const { element } = me;

        // clone whole grid element to the detached container
        me.cloneElement(client.element);

        me.prepareElement(config);

        let fakeRow;

        // Create fake row, which will be hidden by being translated offscreen.
        // We will use it to render stuff
        exportMeta.fakeRow = fakeRow = rowManager.rowClass.new({
            cls : {
                [client.rowCls] : 1,
                'b-export-row'  : 1
            },
            rowManager,
            grid      : client,
            // use fake indices, they aren't really required
            index     : -10,
            dataIndex : -10
        });

        client.eachSubGrid(subGrid => {
            // Call private listener to create actual DOM nodes
            if (fakeRow) {
                subGrid.onAddRow({ rows : [fakeRow] });

                // Trick inline widgets to think they're rendered into component (in fact they are)
                // This is required to remove `Widget.outerCls` classes from the widget
                fakeRow.element.dataset.ownerCmp = client.id;
            }

            const placeHolder = me.createPlaceholder(element.querySelector(`[id="${subGrid.id}"]`), false);

            let width;

            if (keepRegionSizes?.[subGrid.region]) {
                width = subGrid.element.offsetWidth;
            }
            else {
                const { visibleColumns } = subGrid.columns;

                if (
                    // If there is only one visible column...
                    visibleColumns.length === 1 &&
                    // ...and it is not a timeaxis column, which should always take as much space as possible
                    !visibleColumns[0].isTimeAxisColumn &&
                    // ...and originally in grid there was only one visible column and it is the same one
                    me.state.columns.filter(c => c.region === subGrid.region && !c.hidden && c.id !== visibleColumns[0].id).length === 0
                ) {
                    // ...then use subgrid width instead of column width
                    // https://github.com/bryntum/support/issues/7695
                    width = subGrid.element.offsetWidth;
                }
                else {
                    width = subGrid.columns.visibleColumns.reduce((result, column) => {
                        if (!useRenderedColumnWidth && typeof column.width === 'number') {
                            result += column.width;
                        }
                        else {
                            result += (client.hideHeaders ? rowManager.rows[0].getCell(column.id) : column.element).offsetWidth;
                        }
                        return result;
                    }, 0);
                }
            }

            exportMeta.totalWidth += width;

            const splitterWidth = subGrid.splitterElement?.offsetWidth || 0;

            exportMeta.totalWidth += splitterWidth;

            exportMeta.subGrids[subGrid.region] = {
                id       : subGrid.id,
                headerId : subGrid.header?.id || null,
                footerId : subGrid.footer?.id || null,
                rows     : [],
                splitterWidth,
                placeHolder,
                width
            };
        });
    }

    prepareExportElement() {
        const
            me                      = this,
            { element, exportMeta } = me;

        // Exporters may change subGrid width, e.g. when specific date range is exported
        Object.values(exportMeta.subGrids).forEach(({ width, id, headerId, footerId }) => {
            [id, headerId, footerId].forEach(id => {
                if (id) {
                    const childElement = element.querySelector(`[id="${id}"]`);
                    // SubGrid now always have header & footer, but not always in DOM
                    if (childElement) {
                        childElement.style.width = `${width}px`;
                        childElement.style.flex = '';
                    }
                }
            });
        });

        return element.innerHTML;
    }

    async restoreComponent(config) {
        if (this.exportMeta.fakeRow) {
            this.exportMeta.fakeRow.destroy();
            delete this.exportMeta.fakeRow;
        }
    }

    collectRow(row) {
        const
            subGrids = this.exportMeta.subGrids,
            re       = /data-owner-cmp=".+?"/;

        Object.entries(row.elements).forEach(([key, value]) => {
            // Only needed by the fakeRow, not when copied into the export document.
            value.classList.remove('b-export-row');
            subGrids[key].rows.push([
                value.outerHTML.replace(re, ''),
                new Map() // This one is used to collect events (or in general additional row content)
            ]);
            value.classList.add('b-export-row');
        });
    }

    renderMergedCells(config, fromIndex, toIndex, rows) {
        const
            me           = this,
            { client }   = config,
            { subGrids } = me.exportMeta,
            // Generate config for merged cells
            domConfigMap = client.features.mergeCells.buildMergedCellsConfig(fromIndex, toIndex, rows);

        for (const subGridName in subGrids) {
            const
                subGrid = subGrids[subGridName],
                target  = document.createElement('div');

            // Render config into temporary element
            DomSync.sync({
                targetElement : target,
                domConfig     : {
                    children : domConfigMap[subGridName].children
                }
            });

            if (target.childNodes.length) {
                // mergeCells feature uses dependency injection, so in order to get rendered cell content we
                // need to get the cell element matching the range
                target.childNodes.forEach(child => {
                    const
                        { syncId } = child.dataset,
                        range      = client.features.mergeCells.mergedRanges
                            .find(range => range.cellElement?.parentNode.dataset.syncId === syncId);

                    if (range) {
                        // Replace cell content if range is found (it might not be found if there's only one cell)
                        child.innerHTML = range.cellElement.outerHTML;
                    }
                });

                subGrid.mergedCellsHtml = [target.innerHTML];
            }
        }
    }
}

// HACK: terser/obfuscator doesn't yet support async generators, when processing code it converts async generator to regular async
// function.
/**
 * Pages generator. Value should be string with exported HTML
 * @param {Object} config
 * @returns {AsyncIterableIterator<{value: String, done: Boolean}>}
 * @private
 */
Exporter.prototype.pagesExtractor = async function * pagesExtractor() {
    throw new Error('Implement this method in a subclass');
};
