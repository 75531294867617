/**
 * @module Core/data/stm/action/ActionBase
 */
import Base from '../../../Base.js';
import { throwAbstractMethodCall } from '../state/StateBase.js';

/**
 * Base class for STM actions.
 *
 * @abstract
 */
export default class ActionBase extends Base {

    static get defaultConfig() {
        return {
            // If the action is inversed, undo calls redo and vice versa
            inversed : false
        };
    }

    /**
     * Gets the type of the action (stringified class name).
     * @readonly
     * @property {String}
     */
    get type() {
        return this.constructor.name;
    }

    /**
     * Undoes an action
     */
    undo() {
        throwAbstractMethodCall();
    }

    /**
     * Redoes an action
     */
    redo() {
        throwAbstractMethodCall();
    }

    invert() {
        const
            action         = new this.constructor({ ...this.initialConfig, isUserInput : this.isUserInput, inversed : true }),
            { undo, redo } = action;

        action.undo = redo;
        action.redo = undo;

        return action;
    }
}

export const ACTION_TYPES = {
    ADD          : 'AddAction',
    INSERT       : 'InsertAction',
    INSERT_CHILD : 'InsertChildAction',
    REMOVE       : 'RemoveAction',
    REMOVE_ALL   : 'RemoveAllAction',
    REMOVE_CHILD : 'RemoveChildAction',
    UPDATE       : 'UpdateAction',
    // Here we declare an action type which does not exist in the Core. Ideally we should not do it, however
    // alternative only includes single action and is not worth it.
    EVENT_UPDATE : 'EventUpdateAction'
};
