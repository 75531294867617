import { Mixin } from '../../../../ChronoGraph/class/BetterMixin.js';
import { Direction } from "../../../scheduling/Types.js";
import { ConstrainedByDependenciesEventScheduleMixin } from "../scheduler_pro/ConstrainedByDependenciesEventScheduleMixin.js";
/**
 * The "late" schedule of the event, which is used when scheduling "backward" (as late as possible).
 */
export class ConstrainedLateScheduleMixin extends Mixin([ConstrainedByDependenciesEventScheduleMixin], (base) => {
    class ConstrainedLateScheduleMixin extends base {
        constructor() {
            super(...arguments);
            this.phase = 'phase1';
        }
        pick(event) {
            if (this.phase === 'phase1') {
                return (this.direction === Direction.Forward ? event.earlyPreSchedule : event.latePreSchedule);
            }
            else {
                return (this.direction === Direction.Forward ? event.earlySchedule : event.lateSchedule);
            }
        }
        pickOpposite(event) {
            if (this.phase === 'phase1') {
                return (this.direction === Direction.Forward ? event.latePreSchedule : event.earlyPreSchedule);
            }
            else {
                return (this.direction === Direction.Forward ? event.lateSchedule : event.earlySchedule);
            }
        }
        *pickPushingSchedule(pushingEvent, pushingDirection) {
            if (this.phase === 'phase1') {
                return this.pick(pushingEvent);
            }
            else {
                // possibly should be checking the parent event direction here, instead of the project's?
                // anyway, the idea is, that we should pick the "phase1" schedule only on one side
                // of the dependency with contradicting directions, for that, we compare the direction
                // with the direction of some "outer" context
                const projectDirection = yield this.getProject().$.effectiveDirection;
                if (this.direction === Direction.Forward) {
                    if (pushingDirection.direction === Direction.Backward && projectDirection.direction === Direction.Forward) {
                        return pushingEvent.latePreSchedule;
                    }
                    else {
                        return pushingEvent.earlySchedule;
                    }
                }
                else {
                    if (pushingDirection.direction === Direction.Forward && projectDirection.direction === Direction.Backward) {
                        return pushingEvent.earlyPreSchedule;
                    }
                    else {
                        return pushingEvent.lateSchedule;
                    }
                }
            }
        }
        *pickPushingStartDateIdentifier(pushingEvent) {
            return (yield* this.pickPushingSchedule(pushingEvent, yield pushingEvent.$.startDateDirection)).$.startDate;
        }
        *pickPushingEndDateIdentifier(pushingEvent) {
            return (yield* this.pickPushingSchedule(pushingEvent, yield pushingEvent.$.endDateDirection)).$.endDate;
        }
        *onEmptyEffectiveInterval(type) {
            const projectDirection = yield this.getProject().$.effectiveDirection;
            // this condition indicates 2nd pass in the calculation ("late" for forward project, "early" for backward)
            // what happens here is the following - we assume, that if calculations have reached the 2nd pass
            // the conflicts on 1st pass have been resolved somehow
            // so when we have a conflict on the 2nd pass, we just take the value from the 1st pass
            // in other words, if we were able to position the event on 1st pass somehow, we should always be able
            // to position it on the 2nd pass as well (using data from the 1st pass)
            // this will make task critical (early date = late date) with slack 0, however in MSP,
            // slack is negative (if that make sense)
            // probably, also need to set some marker on the task, to indicate that there's a "hidden" conflict
            if (projectDirection.direction !== this.direction) {
                return {
                    kind: 'return',
                    value: yield this.pickOpposite(this.event).$[type === 'start' ? 'startDate' : 'endDate']
                };
            }
            else {
                return yield* super.onEmptyEffectiveInterval(type);
            }
        }
    }
    return ConstrainedLateScheduleMixin;
}) {
}
