import later from '../../../Engine/vendor/later/later.js';
import CalendarEditorIntervalBaseModel from './CalendarEditorIntervalBaseModel.js';
import RecurrenceModel from '../../../Scheduler/model/RecurrenceModel.js';
import RecurringTimeSpan from '../../../Scheduler/model/mixin/RecurringTimeSpan.js';
import FunctionHelper from '../../../Core/helper/FunctionHelper.js';
import AvailabilityRangeStore from '../../data/calendareditor/AvailabilityRangeStore.js';
import DateHelper from '../../../Core/helper/DateHelper.js';
import StringHelper from '../../../Core/helper/StringHelper.js';

/**
 * @module SchedulerPro/model/calendareditor/CalendarEditorExceptionModel
 */

/**
 * This class represents a calendar exception - a special interval providing working time changes.
 * The class is used by the calendar editor.
 *
 * @extends SchedulerPro/model/calendareditor/CalendarEditorIntervalBaseModel
 * @mixes Scheduler/model/mixin/RecurringTimeSpan
 * @internal
 */
export default class CalendarEditorExceptionModel extends RecurringTimeSpan(CalendarEditorIntervalBaseModel) {

    static $name = 'CalendarEditorExceptionModel';

    static fields = [
        /**
         * The field specifies availability provided by this exception.
         * When some availability is provided the system uses the provided
         * time ranges inside of the exception {@link #field-startDate} - {@link #field-endDate} period of time.
         *
         * Implemented as a field of {@link Core/data/field/StoreDataField} type
         * @field {SchedulerPro.data.calendareditor.AvailabilityRangeStore} availability
         */
        {
            name       : 'availability',
            type       : 'store',
            // keep id values to better use syncDataOnLoad
            usesId     : true,
            storeClass : AvailabilityRangeStore
        },
        /**
         * Specifies whether the exception is a working period of time or not.
         * If the value is `true`:
         *
         * - if {@link #field-availability} is not provided the whole
         *   {@link #field-startDate} - {@link #field-endDate} period of time is treated as working.
         * - if {@link #field-availability} is provided then the Engine uses the availability
         *   time ranges inside of the {@link #field-startDate} - {@link #field-endDate} period of time.
         * @field {Boolean} isWorking
         */
        { name : 'isWorking', type : 'boolean', defaultValue : false },
        'rawIntervalId',
        /**
         * Recurring start date of the exception.
         * @field {String|Object} recurrentStartDate
         */
        'recurrentStartDate',
        /**
         * Recurring end date of the exception.
         * @field {String|Object} recurrentEndDate
         */
        'recurrentEndDate'
    ];

    static errors = {
        errorMissingDate                     : 'errorMissingDate',
        errorStartAfterEnd                   : 'errorStartAfterEnd',
        errorInvalidAvailability             : 'errorInvalidAvailability',
        errorStartAndEndRepeatNumberMismatch : 'errorStartAndEndRepeatNumberMismatch'
    };

    get isException() {
        return true;
    }

    doDestroy() {
        this.startRecurrence?.destroy();
        this.endRecurrence?.destroy();
        super.doDestroy();
    }

    get basedOnInterval() {
        return [...this.intervals][0];
    }

    setValue(fieldName, value) {
        if (fieldName === 'isWorking') {
            value = Boolean(value);
        }

        return super.setValue(fieldName, value);
    }

    get recurrentStartDate() {
        return this.get('recurrentStartDate');
    }

    parseDateSchedule(value) {
        let schedule = value;

        if (value && value !== Object(value)) {
            schedule = later.parse.text(value.trim());

            if (schedule !== Object(schedule) || schedule.error >= 0) {
                // can be provided as JSON text
                schedule = StringHelper.safeJsonParse(value);
            }
        }

        return schedule;
    }

    set recurrentStartDate(value) {
        const interval = this.basedOnInterval;

        // If a laterjs rule is provided fill startRecurrence with corresponding RecurrenceModel
        if (value) {
            // use the corresponding raw interval (if provided by the time)
            // or fallback to our parseDateSchedule method
            const schedule = (interval || this).parseDateSchedule(value);

            // Build this.startRecurrence model based on the provided rule
            // ..unless we are here because of this.startRecurrence model change
            if (schedule && !this.readingStarRecurrence) {
                // We take the very first schedule (composite schedules are not supported by the calendar manager UI)
                this.startRecurrence = schedule.schedules[0];
            }
        }

        this.set('recurrentStartDate', value);
    }

    get recurrentEndDate() {
        return this.get('recurrentEndDate');
    }

    set recurrentEndDate(value) {
        const interval = this.basedOnInterval;

        // If a laterjs rule is provided fill startRecurrence with corresponding RecurrenceModel
        if (value) {
            // use the corresponding raw interval (if provided by the time)
            // or fallback to our parseDateSchedule method
            const schedule = (interval || this).parseDateSchedule(value);

            // Build this.endRecurrence model based on the provided rule
            // ..unless we are here because of this.endRecurrence model change
            if (schedule && !this.readingEndRecurrence) {
                // We take the very first schedule (composite schedules are not supported by the calendar manager UI)
                this.endRecurrence = schedule.schedules[0];
            }
        }

        this.set('recurrentEndDate', value);
    }

    set startRecurrence(value) {
        this._startRecurrenceAfterChangeDetacher?.();

        if (value && !value.isRecurrenceModel) {
            value = this.buildRecurrenceModel({
                date            : this.startDate,
                laterJsSchedule : value
            });
        }

        if (value?.isRecurrenceModel) {
            this._startRecurrenceAfterChangeDetacher = FunctionHelper.after(value, 'afterChange',
                'afterStartRecurrenceChange',
                this
            );
        }

        this._startRecurrence = value;

        // update recurrentStartDate value
        this.afterStartRecurrenceChange();
    }

    get startRecurrence() {
        return this._startRecurrence;
    }

    get endRecurrence() {
        return this._endRecurrence;
    }

    set endRecurrence(value) {
        const me = this;

        me._endRecurrenceAfterChangeDetacher?.();

        if (value && !value.isRecurrenceModel) {
            value = me.buildRecurrenceModel({
                date            : me.endDate,
                laterJsSchedule : value
            });
        }

        if (value?.isRecurrenceModel) {
            me._endRecurrenceAfterChangeDetacher = FunctionHelper.after(value, 'afterChange',
                'afterEndRecurrenceChange',
                me
            );
        }

        me._endRecurrence = value;

        // update recurrentEndDate value
        me.afterEndRecurrenceChange();
    }

    // called after changing startRecurrence stored RecurrenceModel instance field
    afterStartRecurrenceChange() {
        const me = this;

        me.readingStarRecurrence = true;
        // rebuild recurrentStartDate rule
        me.recurrentStartDate = me.startRecurrence?.laterJsSchedule || null;

        if (me.startRecurrence && me.endRecurrence) {
            me.endRecurrence.set({
                frequency : me.startRecurrence.frequency,
                endDate   : me.startRecurrence.endDate,
                count     : me.startRecurrence.count
            });
        }

        me.readingStarRecurrence = false;
    }

    // called after changing endRecurrence stored RecurrenceModel instance field
    afterEndRecurrenceChange() {
        this.readingEndRecurrence = true;
        // rebuild recurrentEndDate rule
        this.recurrentEndDate = this.endRecurrence?.laterJsSchedule || null;
        this.readingEndRecurrence = false;
    }

    processRawInterval(interval) {
        const me = this;

        me.rawIntervalId = interval.id;
        me.recurrentStartDate = interval.recurrentStartDate;
        me.recurrentEndDate = interval.recurrentEndDate;
        me.availability = interval.availability;

        // If the interval is working and no availability specified (a solid startDate-endDate working interval)
        // let's add a single 00:00-00:00 range availability
        if (me.isWorking && !me.availability.count) {
            me.availability = [{ startDate : '00:00', endDate : '00:00' }];
        }
    }

    buildRawInterval() {
        const
            me = this,
            // use initial data of the original raw interval
            sourceInterval = me.basedOnInterval?.toJSON() || {},
            { availability, rawIntervalId } = me,
            interval = {
                ...sourceInterval,
                type               : 'Exception',
                isWorking          : me.isWorking,
                name               : me.name,
                startDate          : me.startDate,
                endDate            : me.endDate,
                recurrentStartDate : me.recurrentStartDate,
                recurrentEndDate   : me.recurrentEndDate,
                compositeCode      : me.compositeCode
            };

        if (rawIntervalId) {
            interval.id = rawIntervalId;
        }

        if (availability?.count) {
            // then it's working
            interval.isWorking = true;

            // Passing the only 00:00-00:00 time range makes no sense.
            // It just means a single solid working  startDate-endDate interval.
            if (availability.count > 1 || !DateHelper.isMidnight(availability.first.startDate) ||
                !DateHelper.isMidnight(availability.first.endDate)
            ) {
                interval.availability = availability.toJSON();
            }
        }
        else if (me.isWorking === false) {
            interval.isWorking    = false;
            interval.availability = null;
        }

        return interval;
    }

    buildRawIntervals() {
        return [this.buildRawInterval()];
    }

    buildRecurrenceModel(config = {}) {
        return RecurrenceModel.new({ ...config });
    }

    get isValid() {
        return !this.getErrors();
    }

    getErrors(values = {}) {
        const
            me = this,
            { errors } = me.constructor,
            {
                startDate       = me.startDate,
                endDate         = me.endDate,
                availability    = me.availability,
                startRecurrence = me.startRecurrence,
                endRecurrence   = me.endRecurrence
            } = values,
            result = [];

        // if startDate is provided as recurrence
        if (startRecurrence) {
            // validate it against recurrentEndDate?
            if (endRecurrence) {
                if (
                    (startRecurrence.monthDays?.length !== endRecurrence.monthDays?.length) ||
                    (startRecurrence.months?.length !== endRecurrence.months?.length)
                ) {
                    result.push(errors.errorStartAndEndRepeatNumberMismatch);
                }
            }
        }
        // startDate is required
        else if (!startDate || !endDate) {
            result.push(errors.errorMissingDate);
        }
        // startDate is not allowed to be later than endDate
        else if (endDate && startDate > endDate) {
            result.push(errors.errorStartAfterEnd);
        }

        // If we got availability intervals
        if (availability && !availability.isValid) {
            result.push(errors.errorInvalidAvailability);
        }

        return result.length ? result : null;
    }

}
