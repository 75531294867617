import GridFeatureManager from '../../Grid/feature/GridFeatureManager.js';
import SchedulerDependencyEdit from '../../Scheduler/feature/DependencyEdit.js';
import '../../Core/widget/Checkbox.js';
import TransactionalFeature from '../../Scheduler/feature/mixin/TransactionalFeature.js';

/**
 * @module SchedulerPro/feature/DependencyEdit
 */

/**
 * Feature that displays a popup containing fields for editing dependency data.
 *
 * This feature is **disabled** by default.
 *
 * For info on enabling it, see {@link Grid/view/mixin/GridFeatures}.
 *
 * {@inlineexample SchedulerPro/feature/DependencyEdit.js}
 *
 * @demo SchedulerPro/dependencies/
 *
 * @extends Scheduler/feature/DependencyEdit
 * @classtype dependencyEdit
 * @feature
 *
 * @typings Scheduler.feature.DependencyEdit -> Scheduler.feature.SchedulerDependencyEdit
 */
export default class DependencyEdit extends TransactionalFeature(SchedulerDependencyEdit) {
    //region Config

    static get $name() {
        return 'DependencyEdit';
    }

    static get configurable() {
        return {
            /**
             * True to show the lag field for the dependency
             * @config {Boolean}
             * @default
             * @category Editor widgets
             */
            showLagField : true,

            editorConfig : {
                items : {
                    activeField : {
                        type  : 'checkbox',
                        name  : 'active',
                        label : 'L{Active}'
                    }
                }
            }
        };
    }
    //endregion

    async editDependency(record) {
        await this.startFeatureTransaction();

        const result = await super.editDependency(record);

        if (!result) {
            await this.rejectFeatureTransaction();
        }

        return result;
    }

    async save() {
        const saved = await super.save();

        if (saved) {
            await this.finishFeatureTransaction();
        }

        return saved;
    }

    async deleteDependency() {
        const removed = await super.deleteDependency();

        if (removed) {
            await this.finishFeatureTransaction();
        }

        return removed;
    }

    afterCancel() {
        this.rejectFeatureTransaction();
        super.afterCancel();
    }
}

GridFeatureManager.registerFeature(DependencyEdit, false);
