import GridSinglePageExporter from '../../../../Grid/feature/export/exporter/SinglePageExporter.js';
import SchedulerExporterMixin from './SchedulerExporterMixin.js';

/**
 * @module Scheduler/feature/export/exporter/SinglePageExporter
 */

/**
 * A single page exporter. Used by the {@link Scheduler.feature.export.PdfExport} feature to export to single page.
 * Content will be scaled in both directions to fit the page.
 *
 * You do not need to use this class directly.
 *
 * ### Extending exporter
 *
 * ```javascript
 * class MySinglePageExporter extends SinglePageExporter {
 *     // type is required for exporter
 *     static get type() {
 *         return 'mysinglepageexporter';
 *     }
 *
 *     get stylesheets() {
 *         const stylesheets = super.stylesheets;
 *
 *         stylesheets.forEach(styleNodeOrLinkTag => doSmth(styleNodeOrLinkTag))
 *
 *         return stylesheets;
 *     }
 * }
 *
 * const scheduler = new Scheduler({
 *     features : {
 *         pdfExport : {
 *             // this export feature is configured with only one exporter
 *             exporters : [MySinglePageExporter]
 *         }
 *     }
 * });
 *
 * // run export with the new exporter
 * scheduler.features.pdfExport.export({ exporter : 'mysinglepageexporter' });
 * ```
 *
 * @classtype singlepage
 * @extends Grid/feature/export/exporter/SinglePageExporter
 *
 * @typings Grid.feature.export.exporter.SinglePageExporter -> Grid.feature.export.exporter.GridSinglePageExporter
 */
export default class SinglePageExporter extends SchedulerExporterMixin(GridSinglePageExporter) {

    static $name = 'SinglePageExporter';

    static type = 'singlepage';
}
